import segmentAnglesImage from '../assets/tooltipPictures/segment-angles-image.png';

const tooltipData = {
  jointAngles: {
    text: 'Angles formed between body segments (e.g. Shin & Thigh). This Angle will decrease as the joint flexes.',
  },
  segmentAngles: {
    text: 'Measure angles of single body segments against vertical (e.g., thigh). Zero degrees (0°) in anatomical standing position.',
    img: segmentAnglesImage,
  },
  tilts: {
    text: 'Measures shoulder and hip rise/drop using points on shoulders and pelvis. Identifies muscle weaknesses in the gym and hip drop in gait analysis.',
  },
  velocities: {
    text: 'Velocities are generated from specific joints. Use the hips to gauge squat velocity or the elbows to gauge rowing or pressing velocity.',
  },
  webApp: {
    text: 'Click on the graph to jump to that point in the video. Hover over the line to get the exact angle. Orange represents the left side while Blue represents the right side.',
  },
  combinedGraphs: {
    text: 'Combine angle or velocity data by selecting multiple joints to be displayed on the same graph. For best data accuracy, select the side (left or right) facing the camera. Use the scroll wheel or pinch to zoom in and out of the graph, and click and drag to pan the graph.',
    right: '-16px',
  },
  noCSVavailable: {
    text: 'CSV export is our newest feature and is only available for the most recent submissions',
  },
};

export default tooltipData;
