import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from './GroundAngleGraph.module.css';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart } from 'chart.js';

import GraphProgressLine from '../graphProgressLine/GraphProgressLine';
import GraphDeltaValue from '../graphDeltaValue/GraphDeltaValue';

const GroundAngleGraph = forwardRef(
  //forwardRef is for link scrolling
  ({ linedata, dataPointName, dataPointLabel, videoRef, scrollToTime, jointDisplay, onLabelClick }, ref) => {
    const jointName = dataPointName.split('_').slice(1).join('_').toLowerCase();
    const graphRef = useRef(null);

    const [dataVisibility, setDataVisibility] = useState({
      [dataPointLabel]: jointDisplay['visible'],
    });

    const options = {
      elements: {
        point: {
          radius: 0,
        },
      },
      normalized: false,
      animation: false,
      scales: {
        x: {
          display: false,
        },
        y: {
          min: 0,
          max: 180,
          ticks: {
            callback: function (value) {
              return value + '°';
            },
          },
        },
      },
      legend: {
        display: false,
      },
      plugins: {
        legend: {
          onClick: function (e, legendItem, legend) {
            onLabelClick('graph', 'visible');

            // Toggle visibility for the clicked dataset
            setDataVisibility((prevVisibility) => ({
              ...prevVisibility,
              [dataPointLabel]: !prevVisibility[dataPointLabel],
            }));
          },
        },
        tooltip: {
          callbacks: {
            title: function () {
              return '';
            },
            label: function (context) {
              const formattedValue = context.formattedValue;
              const roundedValue = Math.round(formattedValue * 100) / 100; // Round to 2 decimal places
              return `${context.dataset.label}: ${roundedValue}°`;
            },
          },
        },
      },
      maintainAspectRatio: false,
    };

    const lineChart = {
      labels: linedata.map((data, index) => index),
      datasets: [
        {
          label: `${dataPointLabel}`,
          data: linedata.map((data) => data[dataPointName]),
          backgroundColor: ['#2e2e2e'],
          borderColor: ['#2e2e2e'],
          borderWidth: 3,
          hidden: !dataVisibility[dataPointLabel],
        },
      ],
    };

    const handleGraphClick = (event) => {
      const rect = graphRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left - 38;
      const y = event.clientY - rect.top;
      const totalWidth = rect.width - 38;
      const totalHeight = rect.height - 6;

      if (x < 0 || x > totalWidth) {
        return;
      }

      if (y < 32 || y > totalHeight) {
        return;
      }

      const percentage = (x / totalWidth) * 100;
      scrollToTime(percentage);
    };

    const chartRef = useRef(null);

    return (
      <div
        ref={ref}
        className={styles.lineGraph}
        id={jointName + 'Graph'}
        style={{ position: 'relative', scrollSnapPaddingTop: '100px' }}
        onClick={handleGraphClick}
      >
        <div ref={graphRef} className={styles.graphContainer}>
          <Line ref={chartRef} className={styles.line} data={lineChart} options={options} />
        </div>
        <GraphProgressLine videoRef={videoRef} graphRef={graphRef} />
        {dataVisibility[dataPointLabel] ? (
          <GraphDeltaValue
            videoRef={videoRef}
            linedata={linedata}
            dataPoint1Name={dataPointName}
            dataPoint1Label={dataPointLabel}
            dataVisibility={dataVisibility}
            graphRef={graphRef}
          />
        ) : null}
      </div>
    );
  }
);

export default React.memo(GroundAngleGraph);
