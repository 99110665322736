import { useEffect, useRef, useState } from 'react';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import styles from './Result.module.css';
import LineGraph from '../lineGraph/LineGraph';
import AngleDisparityLine from '../angleDisparityLine/AngleDisparityLine';
import DatavizControl from '../datavizControl/DatavizControl';
import React from 'react';
import TiltCircle from '../tiltCircle/TiltCircle';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ROM from '../ROM/ROM';

import { IconBoxAlignLeft, IconLoader2, IconNote } from '@tabler/icons-react';
import { saveAs } from 'file-saver';
// import Rotation from '../rotation/Rotation';
import RangeSliderTitle from '../rangeSliderTitle/RangeSliderTitle';
import CommentModal from '../modals/CommentModal';
import WithTooltip from '../HOCs/WithTooltip';
import VelocityGraph from '../velocityGraph/VelocityGraph';
import GroundAngleGraph from '../groundAngleGraph/GroundAngleGraph';
import CenterOfBody from '../centerOfBody/CenterOfBody';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas'; // Import HTML2Canvas library
// import * as htmlToImage from 'html-to-image';
import ReportModal from '../modals/ReportModal';
import tooltipData from '../../data/tooltipPropsData';

import jointsInitialDisplay from '../../data/jointsInitialDisplay';

function Result({
  result,
  currentPreset,
  updateInfoJson,
  videoName,
  comment: initialComment,
  fetchData,
  setIsShareModalActive,
}) {
  const [comment, setComment] = useState(initialComment);
  const [resultFiles, setResultFiles] = useState({});
  const [linedata, setLinedata] = useState(null);
  const [jointsDisplay, setJointsDisplay] = useState(currentPreset || jointsInitialDisplay);
  const [tiltLimits, setTiltLimits] = useState({});
  const [isModalActive, setIsModalActive] = useState(false);
  const [isReportModalActive, setIsReportModalActive] = useState(false);
  const [isPDFbeingExported, setIsPDFbeingExported] = useState(false);
  const [currentSectionInView, setCurrentSectionInView] = useState('');

  const stringifiedResult = JSON.stringify({ result, videoName, comment, jointsDisplay });

  const videoRef = useRef();
  const datavizRef = useRef(null);
  const videoListRef = ref(storage, `/${result?._location.path_}`);

  const elementRefs = useRef([]);
  const titleRefs = useRef([]);

  // Temporary
  const exportPDF = function (params) {
    return;
  };

  // const exportPDF = async (checkboxState) => {
  //   setIsPDFbeingExported(true);
  //   setIsReportModalActive(false);

  //   const pdfWidth = 8.5;
  //   const pdfMargin = 0.5;

  //   let currentY = 0.5; // Initialize the current Y position

  //   const doc = new jsPDF({
  //     unit: 'in',
  //     format: 'letter',
  //   });

  //   const addNewPage = () => {
  //     doc.addPage();
  //   };

  //   // Calculate the available height based on the current position
  //   let availableHeight = () => doc.internal.pageSize.height - currentY - 6 * pdfMargin;

  //   const addSectionTitle = async (title) => {
  //     const titleElement = titleRefs.current[title];

  //     if (titleElement) {
  //       const dataUrl = await htmlToImage.toPng(titleElement);

  //       // Check if the available height is enough for the title image
  //       if (availableHeight() <= 0) {
  //         addNewPage();
  //         currentY = pdfMargin; // Reset the current Y position after adding a new page
  //       }

  //       const scaledWidth = pdfWidth - 2 * pdfMargin;
  //       const aspectRatio = scaledWidth / titleElement.offsetWidth;
  //       const scaledHeight = titleElement.offsetHeight * aspectRatio;

  //       // Check if the scaled dimensions are valid
  //       if (scaledWidth <= 0 || scaledHeight <= 0) {
  //         addNewPage();
  //         currentY = pdfMargin; // Reset the current Y position after adding a new page
  //         return; // Skip adding this title image if dimensions are invalid
  //       }

  //       // Check if the title image fits on the current page, and add a new page if needed
  //       if (currentY + scaledHeight > doc.internal.pageSize.height - 6 * pdfMargin) {
  //         addNewPage();
  //         currentY = pdfMargin; // Reset the current Y position after adding a new page
  //       }

  //       console.log(titleElement, currentY);
  //       doc.addImage(dataUrl, 'PNG', pdfMargin, currentY, scaledWidth, scaledHeight);

  //       currentY += scaledHeight; // Update the current Y position after adding the title image
  //     }
  //   };

  //   const addElementToPDF = async (elementRef) => {
  //     const dataUrl = await htmlToImage.toPng(elementRef);

  //     // Check if the available height is enough for the image
  //     if (availableHeight() <= 0) {
  //       addNewPage();
  //       currentY = pdfMargin; // Reset the current Y position after adding a new page
  //     }

  //     const scaledWidth = pdfWidth - 2 * pdfMargin;
  //     const aspectRatio = scaledWidth / elementRef.offsetWidth;
  //     const scaledHeight = elementRef.offsetHeight * aspectRatio;

  //     // Check if the scaled dimensions are valid
  //     if (scaledWidth <= 0 || scaledHeight <= 0) {
  //       addNewPage();
  //       currentY = pdfMargin; // Reset the current Y position after adding a new page
  //       return; // Skip adding this image if dimensions are invalid
  //     }

  //     // Check if the image fits on the current page, and add a new page if needed
  //     if (currentY + scaledHeight > doc.internal.pageSize.height - 6 * pdfMargin) {
  //       addNewPage();
  //       currentY = pdfMargin; // Reset the current Y position after adding a new page
  //     }

  //     console.log(elementRef, currentY);

  //     doc.addImage(dataUrl, 'PNG', pdfMargin, currentY, scaledWidth, scaledHeight);

  //     currentY += scaledHeight; // Update the current Y position after adding the image
  //   };

  //   const { stillFrame, clientsData, includeNotes, angleGraphs, velocities, tilts, vsHipsGraph, centerOfBody } =
  //     checkboxState;

  //   const imagePromises = [];

  //   // if (stillFrame) {
  //   //   addSectionTitle('Video Screenshot');
  //   //   // Add video screenshot content here
  //   // }

  //   // if (clientsData) {
  //   //   addSectionTitle("Client's Data");
  //   //   // Add client's data content here
  //   // }

  //   // if (includeNotes) {
  //   //   addSectionTitle('Notes');
  //   //   // Add notes content here
  //   // }

  //   if (angleGraphs) {
  //     const sectionName = 'Joint Angles';
  //     imagePromises.push(addSectionTitle(sectionName)); // Wait for the title to be added
  //     // Iterate through jointsDisplay to determine which joint angles to include
  //     Object.entries(jointsDisplay).forEach(async ([jointName, jointData]) => {
  //       if (jointData.graph?.display) {
  //         const elementRef = elementRefs.current[`${jointName}GraphGroup`];
  //         if (elementRef) {
  //           await imagePromises[imagePromises.length - 1]; // Wait for the last added promise to resolve
  //           imagePromises.push(addElementToPDF(elementRef));
  //         }
  //       }
  //     });
  //   }

  //   if (velocities) {
  //     const sectionName = 'Velocity';
  //     imagePromises.push(addSectionTitle(sectionName));
  //     // Iterate through jointsDisplay to determine which velocities to include
  //     Object.entries(jointsDisplay).forEach(async ([jointName, jointData]) => {
  //       if (jointData.velocity?.display) {
  //         const elementRef = elementRefs.current[`${jointName}VelocityGroup`];
  //         if (elementRef) {
  //           await imagePromises[imagePromises.length - 1]; // Wait for the last added promise to resolve
  //           imagePromises.push(addElementToPDF(elementRef));
  //         }
  //       }
  //     });
  //   }

  //   if (tilts) {
  //     imagePromises.push(addSectionTitle('Tilt'));
  //     // Iterate through jointsDisplay to determine which velocities to include
  //     Object.entries(jointsDisplay).forEach(([jointName, jointData]) => {
  //       if (jointData.tilt?.display) {
  //         const elementRef = elementRefs.current[`${jointName}TiltGroup`];
  //         if (elementRef) {
  //           imagePromises.push(addElementToPDF(elementRef));
  //         }
  //       }
  //     });
  //   }

  //   if (vsHipsGraph) {
  //     // Iterate through jointsDisplay to determine which velocities to include
  //     Object.entries(jointsDisplay).forEach(([jointName, jointData]) => {
  //       if (jointData.vsHipsGraph?.display) {
  //         const elementRef = elementRefs.current[`vsHipGraphGroup`];
  //         if (elementRef) {
  //           imagePromises.push(addElementToPDF(elementRef));
  //         }
  //       }
  //     });
  //   }

  //   if (centerOfBody) {
  //     imagePromises.push(addSectionTitle('CenterOfBody'));
  //     // Iterate through jointsDisplay to determine which velocities to include
  //     Object.entries(jointsDisplay).forEach(([jointName, jointData]) => {
  //       if (jointData.centerOfBody?.display) {
  //         const elementRef = elementRefs.current[`centerOfBodyGroup`];
  //         if (elementRef) {
  //           imagePromises.push(addElementToPDF(elementRef));
  //         }
  //       }
  //     });
  //   }

  //   const processImagePromises = async () => {
  //     for (const imagePromise of imagePromises) {
  //       await imagePromise;
  //     }
  //   };

  //   try {
  //     // Wait for all promises to be processed in order
  //     await processImagePromises();

  //     doc.save(`${videoName.split(' ').join('_')}_Report.pdf`);
  //     setIsPDFbeingExported(false); // Set the state after saving is done
  //   } catch (error) {
  //     console.error('Error while saving the PDF:', error);
  //     setIsPDFbeingExported(false); // Handle the error and set the state
  //   }
  // };

  const handleDownload = () => {
    if (linedata) {
      const jsonBlob = new Blob([JSON.stringify(linedata)], { type: 'application/json' });
      saveAs(jsonBlob, videoName.split(' ').join('_') + '.json');
    }
  };

  const handleScrollLink = (e, id) => {
    const targetElement = elementRefs.current[id];

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const handleCheckboxClick = (jointKey, keyToUpdate, side = null) => {
    const updatedJointsDisplay = { ...jointsDisplay };

    // Update the display value
    if (side) {
      updatedJointsDisplay[jointKey][keyToUpdate][side] = !updatedJointsDisplay[jointKey][keyToUpdate][side];
    } else {
      updatedJointsDisplay[jointKey][keyToUpdate].display = !updatedJointsDisplay[jointKey][keyToUpdate].display;
    }

    setJointsDisplay(updatedJointsDisplay);

    const newInfo = { preset: updatedJointsDisplay };
    const videoRefPath = result?._location.path_;

    // Update info.json in Firebase
    updateInfoJson(videoRefPath, newInfo);
  };

  const handleKeyDown = (e) => {
    if (videoRef.current) {
      if (e.key === 's' || e.key === 'S' || e.key === ' ') {
        e.preventDefault();
        videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
      }
      if (e.key === 'd' || e.key === 'D' || e.key === 'ArrowRight') {
        e.preventDefault();
        videoRef.current.currentTime += 1 / 25;
      }
      if (e.key === 'a' || e.key === 'A' || e.key === 'ArrowLeft') {
        e.preventDefault();
        videoRef.current.currentTime -= 1 / 25;
      }
    }
  };

  // Function to scroll the video to a particular time
  const scrollToTime = (percentage) => {
    if (videoRef.current) {
      const video = videoRef.current;
      const time = (video.duration * percentage) / 100;
      video.currentTime = time;

      video.pause();
    }
  };

  function isEveryItemEmpty(linedata, propertyName) {
    return linedata.every((item) => item[propertyName] === null || item[propertyName] === undefined);
  }

  const checkDataAvailability = (dataType) => {
    if (!linedata) {
      return false;
    }

    // Assume jointsDisplay is an object with keys as jointNames
    for (const jointName in jointsDisplay) {
      switch (dataType) {
        case 'graph':
          if (jointsDisplay[jointName]?.graph?.display) {
            return true;
          }
          break;
        case 'velocity':
          if (jointsDisplay[jointName]?.velocity?.display) {
            return true;
          }
          break;
        case 'tilt':
          if (jointsDisplay[jointName]?.tilt?.display || jointsDisplay[jointName]?.vsHipsGraph?.display) {
            return true;
          }
          break;
        case 'cob':
          if (jointsDisplay[jointName]?.centerOfBody?.display) {
            return true;
          }
          break;

        // Add other data types as needed
        default:
          console.warn(`Unknown data type: ${dataType}`);
          return false;
      }
    }

    return false;
  };

  const toggleReportModal = () => {
    setIsReportModalActive(true);
    videoRef.current.pause();
  };

  useEffect(() => {
    // setResultFiles([]);
    setComment(initialComment);
    setLinedata(null);
    listAll(videoListRef).then((response) => {
      response.items.forEach((item) => {
        if (item._location.path.endsWith('/angles.json')) {
          // Check if item ends with '/angles.json'
          getDownloadURL(item)
            .then((url) => fetch(url))
            .then((response) => response.json())
            .then((data) => {
              setLinedata(data);
            })
            .catch((error) => console.error(error));
        } else if (item._location.path.endsWith('.mp4')) {
          getDownloadURL(item).then((url) => {
            setResultFiles((prev) => ({
              ...prev,
              Result: url,
            }));
          });
        }
      });
    });
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [result]);

  useEffect(() => {
    setJointsDisplay(currentPreset || jointsInitialDisplay);
  }, [currentPreset]);

  const calculateOffsetsForJoints = () => {
    if (!datavizRef.current) return;

    const scrollY = datavizRef.current.scrollTop;
    const datavizHeight = datavizRef.current.clientHeight;
    let sectionInView = '';

    // Function to check if section is at the bottom of the screen
    const isSectionAtBottom = (sectionRef) => {
      if (sectionRef) {
        const sectionTop = sectionRef.offsetTop;
        if (scrollY + datavizHeight >= sectionTop && sectionTop >= scrollY) {
          return true;
        }
      }
      return false;
    };

    // Loop through each joint
    for (const jointName in jointsInitialDisplay) {
      const joint = jointsInitialDisplay[jointName];

      switch (true) {
        case jointName === 'Shoulders' &&
          joint.centerOfBody &&
          joint.centerOfBody.display &&
          isSectionAtBottom(elementRefs.current[`centerOfBody`]):
          sectionInView = 'cob-dropdown';
          break;

        case joint.tilt &&
          joint.tilt.display &&
          !isSectionAtBottom(elementRefs.current[`centerOfBody`]) &&
          (isSectionAtBottom(elementRefs.current[`${jointName}Tilt`]) ||
            isSectionAtBottom(elementRefs.current[`shoulderHipGraph`])):
          sectionInView = 'tilt-dropdown';
          break;

        case joint.velocity && joint.velocity.display && isSectionAtBottom(elementRefs.current[`${jointName}Velocity`]):
          sectionInView = 'velocity-dropdown';
          break;

        case joint.graph && joint.graph.display && isSectionAtBottom(elementRefs.current[`${jointName}Graph`]):
          sectionInView = 'graph-dropdown';
          break;

        default:
          sectionInView = '';
          break;
      }

      if (sectionInView) break;
    }

    if (sectionInView !== currentSectionInView) {
      setCurrentSectionInView(sectionInView);
    }
  };

  useEffect(() => {}, [videoName]);

  useEffect(() => {
    calculateOffsetsForJoints();
  }, [linedata]);

  return resultFiles.Result && linedata ? (
    <>
      {/* <div className={styles.footerAdjuster}></div> */}
      <div className={styles.result}>
        <div className={styles.left}>
          <video
            className={styles.video}
            src={resultFiles.Result}
            ref={videoRef}
            type='video/mp4'
            width='320'
            autoPlay
            loop
            muted
            controls
            playsInline
          ></video>
          <div className={styles.title}>
            <h3>{videoName}</h3>
          </div>

          <div
            onClick={() => {
              setIsModalActive('comment1');
            }}
            className={styles.notesBtn}
          >
            Notes <IconNote color='#272727' />
          </div>
        </div>
        <div className={styles.right}>
          <DatavizControl
            handleScrollLink={handleScrollLink}
            jointsDisplay={jointsDisplay}
            handleDownload={handleDownload}
            handleCheckboxClick={handleCheckboxClick}
            toggleReportModal={toggleReportModal}
            setIsShareModalActive={setIsShareModalActive}
            currentSectionInView={currentSectionInView}
          />
          <Link to='/compare' state={stringifiedResult} className={styles.compareLink}>
            <button className={styles.compareBtn}>
              <span className={styles.compareText}>Compare</span>
              <IconBoxAlignLeft
                className={styles.compareIcon}
                color='#ffffff'
                // style={{ marginBottom: '4px', marginLeft: '0px' }}
              />
            </button>
          </Link>
          {isPDFbeingExported ? (
            <div className={styles.PDFoverlay}>
              <div className={styles.container}>
                <h2>
                  <IconLoader2 className={styles.icon} />
                  Preparing the report...
                </h2>
                <p>Your report will automatically begin downloading once it's generated.</p>
              </div>
            </div>
          ) : null}

          <div
            ref={datavizRef}
            onScroll={calculateOffsetsForJoints}
            className={`${styles.dataviz} ${isPDFbeingExported && styles.forReport}`}
          >
            <div>
              {checkDataAvailability('graph') &&
                ['Shoulders', 'Hips', 'Knees', 'Ankles', 'Elbows', 'Wrists'].reduce((acc, jointName) => {
                  const displayValue = jointsDisplay[jointName]?.graph?.display;
                  if (displayValue) {
                    return acc + displayValue;
                  }
                  return acc;
                }, '') && (
                  <h3 ref={(el) => (titleRefs.current['Joint Angles'] = el)}>
                    <WithTooltip {...tooltipData.jointAngles}>Joint Angles</WithTooltip>
                  </h3>
                )}

              {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                if (['Upper_Leg', 'Torso', 'Lower_Leg'].includes(jointName)) {
                  return null;
                }
                // Check if linedata.tilt is false and return null
                if (!jointsDisplay[jointName]?.graph?.display) {
                  return null;
                }

                if (
                  !isEveryItemEmpty(linedata, 'LEFT_' + jointName.slice(0, -1).toUpperCase()) &&
                  !isEveryItemEmpty(linedata, 'RIGHT_' + jointName.slice(0, -1).toUpperCase())
                ) {
                  let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase();
                  let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase();

                  const dataPoint1Label = 'Left';
                  const dataPoint2Label = 'Right';
                  return (
                    <div
                      key={`${jointName}Graph`}
                      ref={(groupEl) => (elementRefs.current[`${jointName}GraphGroup`] = groupEl)}
                    >
                      <p className={styles.label}>{jointName.split('_').join(' ')}:</p>
                      <LineGraph
                        ref={(lineGraphEl) => (elementRefs.current[`${jointName}Graph`] = lineGraphEl)} // Ref for the LineGraph
                        videoRef={videoRef}
                        linedata={linedata}
                        dataPoint1Name={dataPoint1Name}
                        dataPoint2Name={dataPoint2Name}
                        dataPoint1Label={dataPoint1Label}
                        dataPoint2Label={dataPoint2Label}
                        scrollToTime={scrollToTime}
                        jointDisplay={jointsDisplay[jointName].graph}
                        onLabelClick={(keyToUpdate, side) => handleCheckboxClick(jointName, keyToUpdate, side)}
                      />
                      <br />
                      {/* <React.Fragment>
                    <RangeSliderTitle
                      title={`${jointName.slice(0, -1)} Asymmetry:`}
                      minValue='0'
                      maxValue='180'
                      lineLimit={lineLimits[jointName] || 15}
                      setLineLimit={(value) => {
                        setLineLimits((prevLimits) => ({ ...prevLimits, [jointName]: value }));
                      }}
                    />
                    <AngleDisparityLine
                      ref={(el) => (elementRefs.current[`${jointName}Difference`] = el)}
                      linedata={linedata}
                      videoRef={videoRef}
                      jointName={jointName}
                      lineLimit={lineLimits[jointName] || 15}
                    />
                  </React.Fragment> */}
                    </div>
                  );
                } else {
                  return (
                    <div key={`${jointName}Unavailable`}>
                      <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data not available</h4>
                      <br />
                    </div>
                  );
                }
              })}
            </div>

            {/* <h3>Relative to Ground Graphs</h3> */}

            {checkDataAvailability('graph') &&
              ['Upper_Leg', 'Torso', 'Lower_Leg'].reduce((acc, jointName) => {
                const displayValue = jointsDisplay[jointName]?.graph?.display;
                if (displayValue) {
                  return acc + displayValue;
                }
                return acc;
              }, '') && (
                <>
                  {checkDataAvailability('graph') && <hr />}
                  <h3 ref={(el) => (titleRefs.current['Segment Angles'] = el)}>
                    <WithTooltip {...tooltipData.segmentAngles}>Segment Angles</WithTooltip>
                  </h3>
                </>
              )}

            {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
              if (['Shoulders', 'Hips', 'Knees', 'Ankles', 'Elbows', 'Wrists'].includes(jointName)) {
                return null;
              }

              if (!jointsDisplay[jointName]?.graph?.display) {
                return null;
              }

              if (!isEveryItemEmpty(linedata, jointName.toUpperCase() + '_ANGLE')) {
                let dataPointName = jointName.toUpperCase() + '_ANGLE';

                const dataPointLabel = jointName.split('_').join(' ');
                return (
                  <div
                    key={`${jointName}Graph`}
                    ref={(groupEl) => (elementRefs.current[`${jointName}GraphGroup`] = groupEl)}
                  >
                    <p className={styles.label}>{jointName.split('_').join(' ')}:</p>

                    <GroundAngleGraph
                      ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)}
                      videoRef={videoRef}
                      linedata={linedata}
                      dataPointName={dataPointName}
                      dataPointLabel={dataPointLabel}
                      scrollToTime={scrollToTime}
                      jointDisplay={jointsDisplay[jointName].graph}
                      onLabelClick={(keyToUpdate, side) => handleCheckboxClick(jointName, keyToUpdate, side)}
                    />
                    <br />
                  </div>
                );
              } else {
                return (
                  <div key={`${jointName}Unavailable`}>
                    <h4 style={{ color: '#727272' }}>{jointName.split('_').join(' ')} data not available</h4>
                    <br />
                  </div>
                );
              }
            })}

            {checkDataAvailability('velocity') && (
              <>
                {checkDataAvailability('graph') && <hr />}
                <h3 ref={(el) => (titleRefs.current['Velocity'] = el)}>
                  <WithTooltip {...tooltipData.velocities}>Velocity</WithTooltip>
                </h3>
              </>
            )}

            {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
              if (!jointsDisplay[jointName]?.velocity?.display) {
                return null;
              }

              if (
                !isEveryItemEmpty(linedata, 'X:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                !isEveryItemEmpty(linedata, 'Y:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                !isEveryItemEmpty(linedata, 'X:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                !isEveryItemEmpty(linedata, 'Y:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY')
              ) {
                let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase();
                let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase();

                const dataPoint1Label = 'Left';
                const dataPoint2Label = 'Right';
                return (
                  <div
                    key={`${jointName}Velocity`}
                    ref={(groupEl) => (elementRefs.current[`${jointName}VelocityGroup`] = groupEl)}
                  >
                    <p className={styles.label}>{jointName.slice(0, -1)} Velocity:</p>

                    <VelocityGraph
                      ref={(el) => (elementRefs.current[`${jointName}Velocity`] = el)}
                      videoRef={videoRef}
                      linedata={linedata}
                      dataPoint1Name={dataPoint1Name + '_VELOCITY'}
                      dataPoint2Name={dataPoint2Name + '_VELOCITY'}
                      dataPoint1Label={dataPoint1Label}
                      dataPoint2Label={dataPoint2Label}
                      scrollToTime={scrollToTime}
                      jointDisplay={jointsDisplay[jointName].velocity}
                      onLabelClick={(keyToUpdate, side) => handleCheckboxClick(jointName, keyToUpdate, side)}
                    />
                    <br />
                  </div>
                );
              } else {
                return (
                  <div key={`${jointName}Unavailable`}>
                    <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} velocity data not available</h4>
                    <br />
                  </div>
                );
              }
            })}

            {checkDataAvailability('tilt') && (
              <>
                {(checkDataAvailability('velocity') || checkDataAvailability('graph')) && <hr />}
                <h3 ref={(el) => (titleRefs.current['Tilt'] = el)}>
                  <WithTooltip {...tooltipData.tilts}>Tilt</WithTooltip>
                </h3>
              </>
            )}

            {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
              if (!jointsDisplay[jointName]?.tilt?.display) {
                return null;
              }

              if (!isEveryItemEmpty(linedata, 'TILT_SHOULDER')) {
                return (
                  <div
                    key={`${jointName}Tilt`}
                    ref={(groupEl) => (elementRefs.current[`${jointName}TiltGroup`] = groupEl)}
                  >
                    <RangeSliderTitle
                      title={`${jointName}`}
                      minValue='0'
                      maxValue='45'
                      lineLimit={tiltLimits[jointName] || 5}
                      setLineLimit={(value) => {
                        setTiltLimits((prevLimits) => ({ ...prevLimits, [jointName]: value }));
                      }}
                    />
                    <TiltCircle
                      ref={(el) => (elementRefs.current[`${jointName}Tilt`] = el)}
                      linedata={linedata}
                      videoRef={videoRef}
                      jointName={jointName}
                      lineLimit={tiltLimits[jointName] || 5}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={`${jointName}Unavailable`}>
                    <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data not available</h4>
                    <br />
                  </div>
                );
              }
            })}
            {jointsDisplay.Shoulders?.vsHipsGraph?.display ? (
              !(isEveryItemEmpty(linedata, 'TILT_SHOULDER') || isEveryItemEmpty(linedata, 'TILT_HIP')) ? (
                <div ref={(groupEl) => (elementRefs.current[`vsHipGraphGroup`] = groupEl)}>
                  <p className={styles.label}>Shoulder vs Hip Tilt:</p>
                  {/* need change colors */}
                  <LineGraph
                    ref={(el) => (elementRefs.current['shoulderHipGraph'] = el)}
                    videoRef={videoRef}
                    linedata={linedata}
                    dataPoint1Name={'TILT_SHOULDER'}
                    dataPoint2Name={'TILT_HIP'}
                    dataPoint1Label={'Shoulders'}
                    dataPoint2Label={'Hips'}
                    scrollToTime={scrollToTime}
                    jointDisplay={jointsDisplay.Shoulders.vsHipsGraph}
                    onLabelClick={(keyToUpdate, side) => handleCheckboxClick('Shoulders', keyToUpdate, side)}
                  />
                </div>
              ) : (
                <div key={`vsHipsGraphUnavailable`}>
                  <h4 style={{ color: '#727272' }}>Shoulders vs Hips data not available</h4>
                  <br />
                </div>
              )
            ) : null}
            <br />

            {checkDataAvailability('cob') && (
              <>
                {(checkDataAvailability('tilt') ||
                  checkDataAvailability('velocity') ||
                  checkDataAvailability('graph')) && <hr />}
                <h3 ref={(el) => (titleRefs.current['CenterOfBody'] = el)}>
                  Center of Body
                  {/* <WithTooltip {...tooltipData.tilts}>Center of Body</WithTooltip> */}
                </h3>
              </>
            )}

            {jointsDisplay.Shoulders?.centerOfBody?.display ? (
              !(isEveryItemEmpty(linedata, 'COB_X') || isEveryItemEmpty(linedata, 'COB_Y')) ? (
                <div ref={(groupEl) => (elementRefs.current['centerOfBodyGroup'] = groupEl)}>
                  <CenterOfBody
                    ref={(el) => (elementRefs.current[`centerOfBody`] = el)}
                    linedata={linedata}
                    videoRef={videoRef}
                  />
                </div>
              ) : (
                <div key={`centerOfBodyUnavailable`}>
                  <h4 style={{ color: '#727272' }}>Center of Body data not available</h4>
                  <br />
                </div>
              )
            ) : null}

            <br />
          </div>
        </div>
      </div>
      <CommentModal
        isActive={isModalActive}
        setIsModalActive={setIsModalActive}
        videoRef={result}
        comment={comment}
        setComment={setComment}
        videoName={videoName}
        fetchData={fetchData}
      />
      <ReportModal
        isActive={isReportModalActive}
        jointsDisplay={jointsDisplay}
        notes={comment}
        exportPDF={exportPDF}
        onCancel={() => {
          setIsReportModalActive(false);
        }}
        videoName={videoName}
      />
    </>
  ) : (
    <>
      <img
        className={styles.loadingGif}
        src='https://vote.elections.virginia.gov/Content/images/loading_animated.gif'
        alt=''
        width='100px'
        height='100px'
      />
    </>
  );
}

export default Result;
