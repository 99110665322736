import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ReactGA from 'react-ga';
import store from './store';
import * as Sentry from '@sentry/react';

const TRACKING_ID = 'G-D9BMJ3ET90';
ReactGA.initialize(TRACKING_ID);

Sentry.init({
  dsn: 'https://450011942d50813804053574dae6ba55@o4507487326896128.ingest.de.sentry.io/4507487329386576',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/sparkmotion\.parados\.app/],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
