import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../actions/userActions';
import { FETCH_ATHLETES_REQUEST, FETCH_ATHLETES_SUCCESS, FETCH_ATHLETES_FAILURE } from '../actions/athletesActions';
import { FETCH_COUPONS_REQUEST, FETCH_COUPONS_SUCCESS, FETCH_COUPONS_FAILURE } from '../actions/couponActions';

const initialState = {
  userData: null,
  userLoading: false,
  userError: null,
  athleteList: [],
  athletesLoading: false,
  athletesError: null,
  coupons: [],
  couponsLoading: false,
  couponsError: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        userLoading: true,
        userError: null, // Reset the error on a new request
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        userLoading: false,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        userData: null,
        userLoading: false,
        userError: action.payload,
      };
    case FETCH_ATHLETES_REQUEST:
      return {
        ...state,
        athletesLoading: true,
        athletesError: null, // Reset the error on a new request
      };
    case FETCH_ATHLETES_SUCCESS:
      return {
        ...state,
        athleteList: action.payload,
        athletesLoading: false,
      };
    case FETCH_ATHLETES_FAILURE:
      return {
        ...state,
        athleteList: [],
        athletesLoading: false,
        athletesError: action.payload,
      };
    case FETCH_COUPONS_REQUEST:
      return {
        ...state,
        couponsLoading: true,
        couponsError: null, // Reset the error on a new request
      };
    case FETCH_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: action.payload,
        couponsLoading: false,
      };
    case FETCH_COUPONS_FAILURE:
      return {
        ...state,
        coupons: [],
        couponsLoading: false,
        couponsError: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
