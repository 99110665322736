import React, { useEffect, useRef, useState } from 'react';
import styles from './WithTooltip.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

function WithTooltip({
  text = null,
  link = null,
  img = null,
  top = '-8px',
  left = null,
  right = '-20px',
  parentWidth = null,
  children,
}) {
  const [tooltipActive, setTooltipActive] = useState(false);
  const tooltipRef = useRef(null);
  const iconRef = useRef(null);

  const calculateWidth = (text) => {
    const maxLength = 200;
    const minWidth = 240;
    const maxWidth = 500;

    // Ensure the width doesn't exceed maxWidth
    const width = Math.min(minWidth + (text.length / maxLength) * (maxWidth - minWidth), maxWidth);

    return width;
  };

  useEffect(() => {
    if (!tooltipRef.current || !iconRef.current) return;

    const tooltipWidth = calculateWidth(text);

    const parent = iconRef.current.parentElement;

    // Get the grandparent element (parent of iconRef's parent)
    const grandparent = iconRef.current.parentElement.parentElement;

    // Calculate available space around the grandparent element
    const grandparentRect = grandparent.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    const spaceAbove = parentRect.top;
    const spaceBelow = window.innerHeight - parentRect.bottom;
    const spaceLeft = parentRect.left - grandparentRect.left;
    const spaceRight = grandparentRect.right - parentRect.right;

    const position = {};
    let maxPopupWidth;
    maxPopupWidth = img ? 500 : tooltipWidth;

    if (spaceBelow >= spaceAbove) {
      position.top = '0%'; // Display below
    } else {
      position.bottom = '50%'; // Display above
    }

    if (spaceRight <= spaceLeft || parentRect.width > tooltipWidth) {
      position.right = '-20px'; // Display on the left
      if (parentRect.right - grandparentRect.left - 16 < tooltipWidth) {
        maxPopupWidth = parentRect.right - grandparentRect.left - 16;
      }
    } else {
      position.left = '-20px'; // Display on the right

      if (grandparentRect.right - parentRect.left - 16 < tooltipWidth) {
        maxPopupWidth = grandparentRect.right - parentRect.left - 16;
      }
    }

    tooltipRef.current.style.top = position.top || '';
    tooltipRef.current.style.bottom = position.bottom || '';
    tooltipRef.current.style.left = position.left || '';
    tooltipRef.current.style.right = position.right || '';
    tooltipRef.current.style.width = maxPopupWidth + 'px';
  }, [tooltipActive]);

  const handleMouseEnter = () => {
    setTooltipActive(true);
  };

  const handleMouseLeave = () => {
    setTooltipActive(false);
  };

  const handleMouseClick = () => {
    setTooltipActive(!tooltipActive);
  };

  const tooltipContent = (
    <div className={styles.tooltipContent}>
      {img && <img src={img} alt='Tooltip' className={styles.tooltipImage} />}
      <div className={styles.tooltipText}>
        {text && <p>{text}</p>}
        {link && (
          <a href={link} target='_blank' rel='noopener noreferrer'>
            {link}
          </a>
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.tooltipContainer} style={{ width: parentWidth }}>
      {children}

      <FontAwesomeIcon
        className={styles.tooltipIcon}
        ref={iconRef}
        style={{
          position: 'absolute',
          top: top,
          left: left,
          right: right,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleMouseClick}
        icon={faCircleQuestion}
      />

      {tooltipActive && (
        <div
          className={styles.tooltipWrapper}
          ref={tooltipRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleMouseClick}
        >
          <div className={styles.tooltip}>{tooltipContent}</div>
        </div>
      )}
    </div>
  );
}

export default WithTooltip;
