import React, { useRef, useState } from 'react';
import styles from './VelocityGraph.module.css';
import { Line } from 'react-chartjs-2';
import GraphProgressLine from '../graphProgressLine/GraphProgressLine';
import GraphDeltaValueNew from '../graphDeltaValue/GraphDeltaValueNew';

const VelocityGraphNew = ({
  dataPoint1Data,
  dataPoint2Data,
  dataPoint1Name,
  dataPoint2Name,
  dataPoint1Label,
  dataPoint2Label,
  videoRef,
  scrollToTime,
  jointDisplay,
  jointName,
  handleCheckboxClick, // New prop for visibility control
}) => {
  const graphRef = useRef(null);

  const [dataVisibility, setDataVisibility] = useState({
    [dataPoint1Label]: jointDisplay[dataPoint1Label.toLowerCase()],
    [dataPoint2Label]: jointDisplay[dataPoint2Label.toLowerCase()],
  });

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    normalized: false,
    animation: false,
    scales: {
      x: {
        display: false,
        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },
        },
      },
      y: {
        min: undefined,
        max: undefined,
        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },
          callback: function (value) {
            return value.toFixed(1) + 'm/s';
          },
        },
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        onClick: function (e, legendItem, legend) {
          const side = (legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label).toLowerCase();
          handleCheckboxClick(jointName, 'velocity', side);

          setDataVisibility((prevVisibility) => ({
            ...prevVisibility,
            [legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label]:
              !prevVisibility[legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label],
          }));
        },
      },
      tooltip: {
        callbacks: {
          title: function () {
            return '';
          },
          label: function (context) {
            const formattedValue = context.formattedValue;
            const roundedValue = Math.round(formattedValue * 100) / 100;
            return `${context.dataset.label}: ${roundedValue}m/s`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const lineChart = {
    labels: Array.from({ length: Math.max(dataPoint1Data.length, dataPoint2Data.length) }, (_, index) => index),
    datasets: [
      {
        label: dataPoint1Label,
        data: dataPoint1Data,

        backgroundColor: ['#F5AB51'],
        borderColor: ['#F5AB51'],
        borderWidth: 3,
        hidden: !dataVisibility[dataPoint1Label],
      },
      {
        label: dataPoint2Label,
        data: dataPoint2Data,
        backgroundColor: ['#0C89C3'],
        borderColor: ['#0C89C3'],
        borderWidth: 3,
        hidden: !dataVisibility[dataPoint2Label],
      },
    ],
  };

  const handleGraphClick = (event) => {
    const rect = graphRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left - 52;
    const y = event.clientY - rect.top;
    const totalWidth = rect.width - 52;
    const totalHeight = rect.height - 6;

    if (x < 0 || x > totalWidth) {
      return;
    }

    if (y < 32 || y > totalHeight) {
      return;
    }

    const percentage = (x / totalWidth) * 100;
    scrollToTime(percentage);
  };

  const chartRef = useRef(null);

  return (
    <div
      className={styles.lineGraph}
      id={`${jointName}VelocityGraph`}
      style={{ position: 'relative', scrollSnapPaddingTop: '100px' }}
      onClick={handleGraphClick}
    >
      <div ref={graphRef} className={styles.graphContainer}>
        <Line ref={chartRef} className={styles.line} data={lineChart} options={options} />
      </div>
      <GraphProgressLine videoRef={videoRef} graphRef={graphRef} leftOffset={52} />
      {(dataVisibility[dataPoint1Label] || dataVisibility[dataPoint2Label]) && (
        <GraphDeltaValueNew
          videoRef={videoRef}
          dataPoint1Data={dataPoint1Data}
          dataPoint2Data={dataPoint2Data}
          dataPoint1Name={dataPoint1Name}
          dataPoint2Name={dataPoint2Name}
          dataPoint1Label={dataPoint1Label}
          dataPoint2Label={dataPoint2Label}
          dataVisibility={dataVisibility}
          label='m/s'
          graphRef={graphRef}
        />
      )}
    </div>
  );
};

export default React.memo(VelocityGraphNew);
