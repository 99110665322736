import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Nav, NavDropdown, Dropdown, NavLink, Form } from 'react-bootstrap';
import { Angle, ArrowsDiff, FocusCentered, Graph, TiltShiftOff } from 'tabler-icons-react';
import { IconShare3, IconDotsVertical, IconGauge, IconDownload, IconFileDownload } from '@tabler/icons-react';

import styles from './DatavizControl.module.css';
import WithTooltip from '../HOCs/WithTooltip';
import tooltipData from '../../data/tooltipPropsData';
import WithPayWall from '../HOCs/WithPayWall';

function DatavizControl({
  handleScrollLink,
  jointsDisplay,
  handleDownload,
  isCSVAvailable,
  isPDFAvailable,
  downloadableJSONURL,
  downloadableCSVURL,
  downloadablePDFURL,
  handleCheckboxClick,
  isCompare = false,
  toggleReportModal,
  setIsShareModalActive,
  currentSectionInView,
}) {
  const [dropdowns, setDropdowns] = useState([
    { id: 'graph-dropdown', isOpen: false },
    { id: 'velocity-dropdown', isOpen: false },
    { id: 'tilt-dropdown', isOpen: false },
    { id: 'cob-dropdown', isOpen: false },
    // { id: 'difference-dropdown', isOpen: false },
    { id: 'other-dropdown', isOpen: false },
  ]);

  const handleDropdownOpen = (id) => {
    setDropdowns((prevDropdowns) =>
      prevDropdowns.map((dropdown) => (dropdown.id === id ? { ...dropdown, isOpen: true } : dropdown))
    );
  };

  const handleDropdownClose = (id) => {
    setDropdowns((prevDropdowns) =>
      prevDropdowns.map((dropdown) => (dropdown.id === id ? { ...dropdown, isOpen: false } : dropdown))
    );
  };

  return (
    <Navbar style={{ padding: '4px 8px' }} className={styles.navControl} bg='light' expand='lg'>
      <Nav className={`${styles.navItems} mr-auto ${isCompare ? styles.narrow : null}`} style={{ flexWrap: 'nowrap' }}>
        {dropdowns.map((dropdown) => {
          const { id, isOpen } = dropdown;

          const handleMouseEnter = () => {
            handleDropdownOpen(id);
          };

          const handleMouseLeave = () => {
            handleDropdownClose(id);
          };

          const iconProps = {
            style: { marginRight: '4px', height: '20px' },
          };

          // Check if the current dropdown matches the currentSectionInView prop
          const isDropdownInView = id === currentSectionInView;

          switch (id) {
            case 'graph-dropdown':
              return (
                <NavDropdown
                  show={isOpen}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={`${styles.dropdown} ${isDropdownInView ? styles.inView : ''}`}
                  title={
                    <>
                      <Graph {...iconProps} />
                      <span className={styles.navTitle}>Angles</span>
                    </>
                  }
                  key={id}
                  id={styles[id]}
                >
                  {Object.keys(jointsDisplay).map((jointKey) => (
                    <React.Fragment key={jointKey}>
                      {jointKey === 'Torso' ? <hr style={{ margin: '4px 0px 4px 0px' }} /> : null}
                      <div className={styles.navDropdownLink} onClick={(e) => handleScrollLink(e, `${jointKey}Graph`)}>
                        <div className={styles.navDropdownLinkWrapper}>
                          <Form.Check
                            type='checkbox'
                            className={styles.navDropdownCheckbox}
                            id={`${jointKey}-graph-checkbox`}
                            checked={jointsDisplay[jointKey]?.graph?.display ?? false}
                            onChange={() => handleCheckboxClick(jointKey, 'graph')}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                          <NavLink className={styles.navLinkLabels}>{jointKey.split('_').join(' ')}</NavLink>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </NavDropdown>
              );

            case 'velocity-dropdown':
              return (
                <NavDropdown
                  show={isOpen}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={`${styles.dropdown} ${isDropdownInView ? styles.inView : ''}`}
                  title={
                    <>
                      <IconGauge {...iconProps} />
                      <span className={styles.navTitle}>Velocity</span>
                    </>
                  }
                  key={id}
                  id={styles[id]}
                >
                  {Object.keys(jointsDisplay).map((jointKey) => {
                    if (jointsDisplay[jointKey].velocity !== undefined && jointKey !== 'Dorsiflexion') {
                      return (
                        <div
                          key={jointKey}
                          className={styles.navDropdownLink}
                          onClick={(e) => handleScrollLink(e, `${jointKey}Velocity`)}
                        >
                          <div className={styles.navDropdownLinkWrapper}>
                            <Form.Check
                              type='checkbox'
                              className={styles.navDropdownCheckbox}
                              id={`${jointKey}-velocity-checkbox`}
                              checked={jointsDisplay[jointKey]?.velocity?.display ?? false}
                              onChange={() => handleCheckboxClick(jointKey, 'velocity')}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <NavLink className={styles.navLinkLabels}>{jointKey}</NavLink>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </NavDropdown>
              );

            case 'tilt-dropdown':
              return (
                <NavDropdown
                  show={isOpen}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={`${styles.dropdown} ${isDropdownInView ? styles.inView : ''}`}
                  title={
                    <>
                      <TiltShiftOff {...iconProps} />
                      <span className={styles.navTitle}>Tilts & CoB</span>
                    </>
                  }
                  key={id}
                  id={styles[id]}
                >
                  {Object.keys(jointsDisplay).map((jointKey) => {
                    if (jointsDisplay[jointKey].tilt !== undefined) {
                      return (
                        <div
                          key={jointKey}
                          className={styles.navDropdownLink}
                          onClick={(e) => handleScrollLink(e, `${jointKey}Tilt`)}
                        >
                          <div className={styles.navDropdownLinkWrapper}>
                            <Form.Check
                              type='checkbox'
                              className={styles.navDropdownCheckbox}
                              id={`${jointKey}-tilt-checkbox`}
                              checked={jointsDisplay[jointKey]?.tilt?.display ?? false}
                              onChange={() => handleCheckboxClick(jointKey, 'tilt')}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            <NavLink className={styles.navLinkLabels}>{`${jointKey.slice(0, -1)} Tilt`}</NavLink>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                  <hr style={{ margin: '4px 0px 4px 0px' }} />

                  <div
                    className={styles.navDropdownLink}
                    onClick={(e) => {
                      handleScrollLink(e, 'shoulderHipGraph');
                    }}
                  >
                    <div className={styles.navDropdownLinkWrapper}>
                      <Form.Check
                        type='checkbox'
                        className={styles.navDropdownCheckbox}
                        id='shoulder-hip-checkbox'
                        checked={jointsDisplay.Shoulders?.vsHipsGraph?.display ?? false}
                        onChange={() => handleCheckboxClick('Shoulders', 'vsHipsGraph')}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      <NavLink className={styles.navLinkLabels}>Shoulders vs Hips</NavLink>
                    </div>
                  </div>
                  <hr style={{ margin: '4px 0px 4px 0px' }} />

                  <div
                    className={styles.navDropdownLink}
                    onClick={(e) => {
                      handleScrollLink(e, 'centerOfBody');
                    }}
                  >
                    <div className={styles.navDropdownLinkWrapper}>
                      <Form.Check
                        type='checkbox'
                        className={styles.navDropdownCheckbox}
                        id='centerOfBody-checkbox'
                        checked={jointsDisplay.Shoulders?.centerOfBody?.display ?? false}
                        onChange={() => handleCheckboxClick('Shoulders', 'centerOfBody')}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      <NavLink className={styles.navLinkLabels}>Center of Body</NavLink>
                    </div>
                  </div>
                </NavDropdown>
              );

            case 'difference-dropdown':
              return (
                <NavDropdown
                  show={isOpen}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={`${styles.dropdown} ${isDropdownInView ? styles.inView : ''}`}
                  title={
                    <>
                      <ArrowsDiff {...iconProps} />
                      <span className={styles.navTitle}>Asymmetry</span>
                    </>
                  }
                  key={id}
                  id={styles[id]}
                >
                  {Object.keys(jointsDisplay).map((jointKey) => (
                    <NavLink
                      onClick={(e) => handleScrollLink(e, `${jointKey}Difference`)}
                      key={jointKey}
                      className={styles.navDropdownLink}
                    >
                      {jointKey}
                    </NavLink>
                  ))}
                </NavDropdown>
              );

            case 'other-dropdown':
              return (
                <NavDropdown
                  show={isOpen}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className={styles.dropdown}
                  title={
                    <>
                      <IconDotsVertical />
                    </>
                  }
                  key={id}
                  id={styles[id]}
                >
                  {!isCompare && (
                    <NavLink onClick={() => setIsShareModalActive(true)} className={styles.navDropdownLink}>
                      <IconShare3 color='#2e2e2e' /> Share
                    </NavLink>
                  )}

                  <WithPayWall acceptedPlanIDs={[5, 6, 7, 8, 9]}>
                    <NavLink download href={downloadableJSONURL} className={styles.navDropdownLink}>
                      <IconDownload color='#2e2e2e' /> Export Data (.json)
                    </NavLink>
                  </WithPayWall>

                  {!isCSVAvailable ? (
                    <WithTooltip {...tooltipData.noCSVavailable} right='-10px'>
                      <NavLink
                        disabled={!isCSVAvailable}
                        onClick={() => handleDownload('csv')}
                        className={`${styles.navDropdownLink} ${!isCSVAvailable && styles.disabledLink}`}
                      >
                        <IconDownload color={!isCSVAvailable ? '#bebebe' : '#2e2e2e'} />
                        Export Data (.csv)
                      </NavLink>
                    </WithTooltip>
                  ) : (
                    <WithPayWall acceptedPlanIDs={[5, 6, 7, 8, 9]}>
                      <NavLink
                        disabled={!isCSVAvailable}
                        download
                        href={downloadableCSVURL}
                        className={`${styles.navDropdownLink} ${!isCSVAvailable && styles.disabledLink}`}
                      >
                        <IconDownload color={!isCSVAvailable ? '#bebebe' : '#2e2e2e'} />
                        Export Data (.csv)
                      </NavLink>
                    </WithPayWall>
                  )}

                  {!isCompare &&
                    (!isPDFAvailable ? (
                      <NavLink
                        disabled={!isCSVAvailable}
                        // onClick={() => handleDownload('pdf')}

                        className={`${styles.navDropdownLink} ${styles.disabledLink}`}
                      >
                        <IconFileDownload color='#bebebe' /> Create Report{' '}
                        <span className={styles.betaHightlight}>Soon</span>
                      </NavLink>
                    ) : (
                      <NavLink
                        disabled={!isCSVAvailable}
                        download
                        href={
                          'https://spalbadevstorage1.s3.ap-south-1.amazonaws.com/venue_images/1716480644584-snapshot.pdf'
                        }
                        // onClick={() => handleDownload('pdf')}
                        className={`${styles.navDropdownLink}`}
                      >
                        <IconFileDownload color='#2e2e2e' /> Create Report
                      </NavLink>
                    ))}
                </NavDropdown>
              );
            default:
              return null;
          }
        })}
      </Nav>
    </Navbar>
  );
}

export default React.memo(DatavizControl);
