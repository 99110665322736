import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import styles from '../resultList/ResultList.module.css';

const ResultListVideoItem = ({
  result,
  exerciseDatabase,
  isSelected,
  handleSelectVideo,
  setIsSideListOpen,
  handleEditClick,
  athleteEmail,
}) => {
  // Find the exercise in the exerciseDatabase by ID
  const exercise = exerciseDatabase.find(
    (item) => item.id === result.exercise || item.fields['Exercise Name'] === result.exercise
  );

  // Define badge content and style based on the exercise
  let exerciseBadgeContent = null;
  let exerciseBadgeStyle = {};

  if (exercise) {
    exerciseBadgeContent = exercise.fields['Web App Adaptation'];
    exerciseBadgeStyle = { backgroundColor: exercise.fields['Web App Badge Color'] };
  }

  const viewBadgeStyle = () => {
    switch (result.view) {
      case 'Right':
        return { backgroundColor: '#0C89C3', color: '#ffffff' };
      case 'Left':
        return { backgroundColor: '#F5AB51', color: '#0E0E0E' };
      case 'Front':
        return { backgroundColor: '#0E0E0E', color: '#ffffff' };
      case 'Back':
        return { backgroundColor: '#EEEEEE', color: '#0E0E0E' };
      default:
        return { display: 'none' };
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleSelectVideo(result); // Pass the athleteEmail along with the result
        setIsSideListOpen(false);
      }}
      className={`${styles.resultItem} ${isSelected ? styles.selectedVid : ''}`}
    >
      <div>
        <h5>{result.videoName}</h5>
        {(result.view || exercise) && (
          <div className={styles.badges}>
            {result.view && (
              <p className={styles.viewBadge} style={viewBadgeStyle()}>
                {result.view}
              </p>
            )}
            {exercise && (
              <p className={styles.exerciseBadge} style={exerciseBadgeStyle}>
                {exerciseBadgeContent}
              </p>
            )}
          </div>
        )}
      </div>
      <FontAwesomeIcon
        icon={faPencil}
        style={{ marginLeft: '5px' }}
        className={styles.editIcon}
        onClick={(e) => {
          e.stopPropagation();
          handleEditClick(result);
        }}
      />
    </div>
  );
};

export default ResultListVideoItem;
