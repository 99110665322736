import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';

import styles from './Modal.module.css';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';

export default function VideoRenamingModal({
  isActive,
  setIsModalActive,
  videoRef,
  videoName,
  fetchData,
  setCurrentVideoName,
  onDeleteVideo,
}) {
  const inputRef = useRef();
  const [newName, setNewName] = useState('');
  const [error, setError] = useState('New name must be different from the current name.');

  const renameVideo = async (storageRef, newName) => {
    try {
      const infoRef = ref(storage, `${storageRef.fullPath}/info.json`);
      const infoSnapshot = await getDownloadURL(infoRef);
      const response = await fetch(infoSnapshot);
      const jsonData = await response.json();

      // Update the name property in the info.json object
      jsonData.name = newName.trim();

      // Upload the updated info.json back to Firebase Storage
      const updatedInfoData = JSON.stringify(jsonData);
      const updatedInfoBlob = new Blob([updatedInfoData], { type: 'application/json' });
      await uploadBytes(ref(storage, `${storageRef.fullPath}/info.json`), updatedInfoBlob);
      setIsModalActive(false);
      fetchData();
      setCurrentVideoName(newName.trim());
    } catch (error) {
      console.error('Failed to rename video:', error);
    }
  };

  const handleInputChange = (e) => {
    const newNameValue = e.target.value.trim();
    setNewName(newNameValue);

    // Check for errors
    if (newNameValue.length === 0) {
      setError('Name cannot be empty.');
    } else if (newNameValue.length > 48) {
      setError('Name cannot be longer than 48 characters.');
    } else if (newNameValue === videoName) {
      setError('New name must be different from the current name.');
    } else {
      setError('');
    }
  };

  if (!isActive) return null;

  return (
    <div className={styles.modal}>
      <div
        onClick={() => {
          setIsModalActive(false);
        }}
        className={styles.overlay}
      ></div>
      <div className={styles.card}>
        <FontAwesomeIcon
          onClick={() => {
            setIsModalActive(false);
          }}
          className={styles.cancelIcon}
          icon={faTimes}
        />
        <div>
          <h3>Edit video</h3>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (!error && newName !== videoName) {
                renameVideo(videoRef, newName);
              }
            }}
          >
            <Form.Group className='mb-1'>
              <Form.Control
                type='text'
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                ref={inputRef}
                defaultValue={videoName}
                onChange={handleInputChange}
              />
            </Form.Group>
            {error && <p className={styles.errorText}>{error}</p>}
            <p className={styles.suggestionText}>
              We suggest using Exercise Name as the Description (e.g., Squat Side 1)
            </p>
          </Form>
        </div>
        <div className={styles.btnGroup}>
          <Button
            type='submit'
            disabled={error || newName === videoName}
            onClick={(e) => {
              e.preventDefault();
              if (!error && newName !== videoName) {
                renameVideo(videoRef, newName);
              }
            }}
          >
            Update Name
          </Button>

          <Button
            className={styles.btnRed}
            onClick={async () => {
              await onDeleteVideo(videoRef);
              setIsModalActive(false);
            }}
          >
            Delete Video
          </Button>
        </div>
      </div>
    </div>
  );
}
