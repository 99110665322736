import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import styles from '../resultList/ResultList.module.css';

const ResultListFolder = ({ user, isFolderOpen }) => (
  <h4 className={styles.folderName}>
    <span>
      {isFolderOpen ? (
        <FontAwesomeIcon icon={faFolderOpen} style={{ width: '16px', marginRight: '8px' }} />
      ) : (
        <FontAwesomeIcon icon={faFolder} style={{ width: '14px', marginRight: '10px' }} />
      )}
    </span>
    {user.athleteName}
  </h4>
);

export default ResultListFolder;
