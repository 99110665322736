import React, { useEffect, useRef, useState } from 'react';
import styles from './WithPayWall.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getUserData } from '../../store/selectors/userSelectors';
import { useNavigate } from 'react-router-dom';
import { IconRocket } from '@tabler/icons-react';
import { getSubdomain } from '../../utils/getSubdomain';

function WithPayWall({
  text = 'This content is only available for subscribers of an upgraded tier.',
  badgeText = '',
  top = '-8px',
  left = null,
  right = '-8px',
  parentWidth = null,
  children,
  acceptedPlanIDs = [],
}) {
  const [tooltipActive, setTooltipActive] = useState(false);
  const tooltipRef = useRef(null);
  const badgeRef = useRef(null);
  const userData = useSelector(getUserData); // Adjust path according to your reducer setup
  const navigate = useNavigate(); // Create the navigate function instance

  useEffect(() => {
    if (
      !tooltipRef.current ||
      !badgeRef.current ||
      acceptedPlanIDs.includes(Number(userData?.activeSubscription?.[0]?.tierId))
    )
      return;

    const parent = badgeRef.current.parentElement;
    const grandparent = parent.parentElement;
    const grandparentRect = grandparent.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    const spaceAbove = parentRect.top;
    const spaceBelow = window.innerHeight - parentRect.bottom;
    let spaceLeft = parentRect.left - grandparentRect.left;
    let spaceRight = grandparentRect.right - parentRect.right;
    if (spaceRight === 0) {
      spaceRight = window.innerWidth - parentRect.right;
    }
    if (spaceLeft === 0) {
      spaceLeft = parentRect.left;
    }
    const computedParentStyle = window.getComputedStyle(parent);
    const computedGrandparentStyle = window.getComputedStyle(grandparent);

    let maxPopupWidth = 300; // Set a fixed width for the tooltip
    if (
      parentRect.width < maxPopupWidth &&
      grandparentRect.width < maxPopupWidth &&
      !(
        computedParentStyle.position === 'fixed' ||
        computedParentStyle.position === 'absolute' ||
        computedGrandparentStyle.position === 'fixed' ||
        computedGrandparentStyle.position === 'absolute'
      )
    ) {
      maxPopupWidth = grandparentRect.width;
    }

    const position = {};

    if (spaceBelow >= spaceAbove) {
      position.top = '0%';
    } else {
      position.bottom = '50%';
    }

    if (spaceRight <= spaceLeft) {
      position.right = '0'; // Tooltip on the left
    } else {
      position.left = '0'; // Tooltip on the right
    }

    tooltipRef.current.style.top = position.top || '';
    tooltipRef.current.style.bottom = position.bottom || '';
    tooltipRef.current.style.left = position.left || '';
    tooltipRef.current.style.right = position.right || '';
    tooltipRef.current.style.width = maxPopupWidth + 'px';
  }, [tooltipActive, acceptedPlanIDs, userData?.activeSubscription?.[0]?.tierId]);

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    setTooltipActive(true);
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    setTooltipActive(false);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setTooltipActive(false);
    navigate('/billing'); // Use navigate to change the route
  };

  if (
    !(
      getSubdomain() ||
      userData?.['Full Access'] ||
      acceptedPlanIDs.includes(Number(userData?.activeSubscription?.[0]?.tierId))
    )
  ) {
    return (
      <div
        className={styles.tooltipContainer}
        style={{ width: parentWidth }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            style: { ...child.props.style, opacity: 0.5, pointerEvents: 'none' },
          })
        )}
        <div
          className={styles.paywallBadge}
          ref={badgeRef}
          style={{
            position: 'absolute',
            padding: !badgeText && '6px 8px',
            top,
            left,
            right,
            transform: `translate(${left === '50%' ? '-50%' : '0%'}, ${top === '50%' ? '50%' : '0%'})`,
          }}
        >
          <FontAwesomeIcon icon={faLock} className={styles.paywallIcon} />
          {badgeText && <span className={styles.paywallText}>{badgeText}</span>}
        </div>

        {tooltipActive && (
          <div
            className={styles.tooltipWrapper}
            ref={tooltipRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
          >
            <div className={styles.tooltip}>
              <div className={styles.tooltipContent}>
                <div className={styles.tooltipText}>
                  <p>{text}</p>
                  <button className={styles.upgradeButton}>
                    <IconRocket stroke={1.5} color='white' />
                    <span> Upgrade Plan</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return children; // Render children directly if the plan is accepted
}

export default WithPayWall;
