import { subdomainConfig } from './subdomainConfig';

export const getSubdomain = () => {
  let subdomain = null;

  if (window.location.hostname === 'localhost') {
    subdomain = 'sparkmotion';
  } else {
    var parts = window.location.hostname.split('.');
    if (parts.length >= 3) {
      subdomain = parts.slice(0, -2).join('.');
    }
  }

  // Check if the subdomain exists in the configuration
  if (subdomainConfig.hasOwnProperty(subdomain)) {
    return { subdomain, providerName: subdomainConfig[subdomain].providerName };
  }

  return null;
};
