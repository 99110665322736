import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore'; // Add the Firestore SDK
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const getAuthDomain = () => {
  const hostname = window.location.hostname;

  if (hostname === 'localhost') {
    return 'parados-pre-prod.firebaseapp.com';
  }

  // Extract subdomain if present
  const subdomain = hostname.split('.')[0];
  return `${subdomain}.parados.app`;
};

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: getAuthDomain(),
  // authDomain: 'parados-pre-prod.firebaseapp.com',

  databaseURL: 'https://parados-pre-prod-default-rtdb.firebaseio.com',
  projectId: 'parados-pre-prod',
  storageBucket: 'parados-pre-prod.appspot.com',
  messagingSenderId: '460616980809',
  appId: '1:460616980809:web:56a1882149105a023a6298',
  measurementId: 'G-5Z1P9C5QZM',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);

if (window.location.hostname.includes('localhost')) {
  // connectAuthEmulator(auth, 'http://localhost:9099');
  // connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}

//firebase deploy --only hosting:dev
