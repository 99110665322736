import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserLoading, getAthletesLoading, getCouponsLoading } from '../../store/selectors/userSelectors';
import styles from './LoadingIndicator.module.css'; // Import the CSS module
import { IconLoader } from '@tabler/icons-react';
import clsx from 'clsx';

const LoadingIndicator = () => {
  const userLoading = useSelector(getUserLoading);
  const athletesLoading = useSelector(getAthletesLoading);
  const couponsLoading = useSelector(getCouponsLoading);

  const [isVisible, setIsVisible] = useState(false);

  // Show the loader if any loading state is true
  const showLoader = userLoading || athletesLoading || couponsLoading;

  useEffect(() => {
    if (showLoader) {
      setIsVisible(true);
    } else if (!showLoader && isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 500); // Delay hiding the loader by 500ms
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [showLoader, isVisible]);

  return (
    <div className={clsx(styles.loaderContainer, { [styles.visible]: isVisible })}>
      <div className={styles.card}>
        <IconLoader size={32} stroke={1.5} className={styles.spinner} />
        <h4 className={styles.loadingText}>Loading Profile Info</h4>
      </div>
    </div>
  );
};

export default LoadingIndicator;
