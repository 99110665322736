import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button, Form } from 'react-bootstrap';

import styles from './CommentModal.module.css';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';
import { useSelector } from 'react-redux';
import { getUserData } from '../../store/selectors/userSelectors';
import WithTooltip from '../HOCs/WithTooltip';

function CommentModal({ isActive, setIsModalActive, videoRef, comment, setComment, videoName, fetchData }) {
  const inputRef = useRef();

  const userData = useSelector(getUserData);

  const handleAction = (action) => {
    console.log('Action requested:', action);
    alert(`Action requested: ${action}`);
    // Implement specific actions based on the button pressed
    // This might involve sending data to a server or updating local state
  };

  const uploadComment = async (storageRef, newComment) => {
    if (newComment.trim() === '') {
      return;
    }

    try {
      const infoRef = ref(storage, `${storageRef.fullPath}/info.json`);
      const infoSnapshot = await getDownloadURL(infoRef);
      const response = await fetch(infoSnapshot);
      const jsonData = await response.json();

      // Ensure jsonData.comment is an array (initialize as an empty array if not)
      if (!Array.isArray(jsonData.comment)) {
        jsonData.comment = [];
      }

      // Create a new comment object
      const commentObj = {
        user: userData.User, // Assuming userData.User contains the email
        timestamp: new Date().toISOString(), // Current timestamp in ISO format
        comment: newComment.trim(),
      };

      // Push the new comment into the array
      jsonData.comment.push(commentObj);

      // Upload the updated info.json back to Firebase Storage
      const updatedInfoData = JSON.stringify(jsonData);
      const updatedInfoBlob = new Blob([updatedInfoData], { type: 'application/json' });
      await uploadBytes(ref(storage, `${storageRef.fullPath}/info.json`), updatedInfoBlob);

      fetchData();
      setComment(jsonData.comment);

      // setIsModalActive(false);
      inputRef.current.value = '';
    } catch (error) {
      console.error('Failed to update comments:', error);
    }
  };

  useEffect(() => {}, [videoRef]);

  if (!isActive) return null;

  return (
    <div className={styles.modal}>
      <div
        onClick={() => {
          setIsModalActive(false);
        }}
        className={styles.overlay}
      ></div>
      <div className={styles.card}>
        <FontAwesomeIcon
          onClick={() => {
            setIsModalActive(false);
          }}
          className={styles.cancelIcon}
          icon={faTimes}
        />

        <div className={styles.container}>
          <div className={styles.notesGroup}>
            <h4>{videoName}</h4>

            {Array.isArray(comment) && comment.length > 0 ? (
              <div className={styles.notesList}>
                {comment.map((commentItem, index) => (
                  <div className={`${styles.note} ${commentItem.user === userData.User && styles.ownNote}`} key={index}>
                    <div className={styles.userAndTime}>
                      <p className={styles.user}>{commentItem.user}</p>
                      <p className={styles.time}>
                        {new Date(commentItem.timestamp).toLocaleString('en-US', {
                          month: 'short',
                          day: '2-digit',
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </p>
                    </div>
                    <p className={styles.comment}>{commentItem.comment}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className={styles.noNotes}>No notes yet...</p>
            )}
            <div className={styles.noteForm}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Form.Group className='mb-2'>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                    }}
                    ref={inputRef}
                    placeholder='Write a note...'
                  />
                </Form.Group>
              </Form>
              <button
                className={styles.btn}
                onClick={() => {
                  // Check if there's no error and the name is different
                  const newComment = inputRef.current.value;

                  uploadComment(videoRef, newComment);
                }}
              >
                Send
              </button>
            </div>
          </div>

          {userData.Role === 'Trainer' && (
            <div className={styles.quickActions}>
              <h4 className={styles.label}>
                Quick actions&nbsp;{' '}
                <WithTooltip
                  text={
                    'Quick actions enable you to efficiently communicate with your client by sending standardized messages. These include requests for re-recording due to specific issues or sharing exercise plans.'
                  }
                  top='-6px'
                  right='-12px'
                  parentWidth={'auto'}
                >
                  <span className={styles.betaHightlight}>Soon</span>{' '}
                </WithTooltip>
              </h4>

              <div>
                <p className={styles.label}>Send re-record request:</p>
                <div className={styles.btnGroup}>
                  <Button
                    disabled
                    variant='warning'
                    // className={styles.red}
                    onClick={() => handleAction('Poor Lighting Conditions')}
                  >
                    Lighting
                  </Button>
                  <Button
                    disabled
                    variant='warning'
                    // className={styles.red}
                    onClick={() => handleAction('Incorrect Camera Angle')}
                  >
                    Angle
                  </Button>
                  <Button
                    disabled
                    variant='warning'
                    // className={styles.red}
                    onClick={() => handleAction('Colors are not visible')}
                  >
                    Colors
                  </Button>
                </div>
                <input disabled className={styles.rerecordReason} type='text' placeholder='Add Instructions:' />
              </div>
              <div>
                <p className={styles.label}>Recommend Exercise Plan:</p>
                <div className={styles.btnGroup}>
                  <Button
                    disabled
                    variant='success'
                    // className={styles.green}
                    onClick={() => handleAction('Shoulder Mobility')}
                  >
                    Shoulder Mobility
                  </Button>
                  <Button
                    disabled
                    variant='success'
                    // className={styles.green}
                    onClick={() => handleAction('Hip Mobility')}
                  >
                    Hip Mobility
                  </Button>
                  <Button
                    disabled
                    variant='success'
                    // className={styles.green}
                    onClick={() => handleAction('Ankle Mobility')}
                  >
                    Ankle Mobility
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(CommentModal);
