import React, { useRef, useState } from 'react';
import styles from './GroundAngleGraph.module.css';
import { Line } from 'react-chartjs-2';
import GraphProgressLine from '../graphProgressLine/GraphProgressLine';
import GraphDeltaValueNew from '../graphDeltaValue/GraphDeltaValueNew';

const GroundAngleGraphNew = ({
  dataPointData,
  videoRef,
  dataPointName,
  dataPointLabel,
  scrollToTime,
  jointDisplay,
  jointName,
  handleCheckboxClick, // New prop for visibility control
}) => {
  const graphRef = useRef(null);

  const [dataVisibility, setDataVisibility] = useState({
    [dataPointLabel]: jointDisplay['visible'],
  });

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    normalized: false,
    animation: false,
    scales: {
      x: {
        display: false,
        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },
        },
      },
      y: {
        min: 0,
        max: 180,
        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },

          callback: function (value) {
            return value + '°';
          },
        },
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        onClick: function (e, legendItem, legend) {
          e.native.preventDefault();

          const side = legendItem.text.toLowerCase(); // Use the legend item's text as the side
          handleCheckboxClick(jointName, 'graph', side);

          setDataVisibility((prevVisibility) => ({ [dataPointLabel]: !prevVisibility[dataPointLabel] }));
        },
      },
      tooltip: {
        callbacks: {
          title: function () {
            return '';
          },
          label: function (context) {
            const formattedValue = context.formattedValue;
            const roundedValue = Math.round(formattedValue * 100) / 100;
            return `${context.dataset.label}: ${roundedValue}°`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const lineChart = {
    labels: Array.from({ length: dataPointData.length }, (_, index) => index),
    datasets: [
      {
        label: `${dataPointLabel}`,
        data: dataPointData,
        backgroundColor: ['#2e2e2e'],
        borderColor: ['#2e2e2e'],
        borderWidth: 3,
        hidden: !dataVisibility[dataPointLabel],
      },
    ],
  };

  const handleGraphClick = (event) => {
    const rect = graphRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left - 38;
    const y = event.clientY - rect.top;
    const totalWidth = rect.width - 38;
    const totalHeight = rect.height - 6;

    if (x < 0 || x > totalWidth) {
      return;
    }

    if (y < 32 || y > totalHeight) {
      return;
    }

    const percentage = (x / totalWidth) * 100;
    scrollToTime(percentage);
  };

  const chartRef = useRef(null);

  return (
    <div
      className={styles.lineGraph}
      id={`${jointName}Graph`}
      style={{ position: 'relative', scrollSnapPaddingTop: '100px' }}
      onClick={handleGraphClick}
    >
      <div ref={graphRef} className={styles.graphContainer}>
        <Line ref={chartRef} className={styles.line} data={lineChart} options={options} />
      </div>
      <GraphProgressLine videoRef={videoRef} graphRef={graphRef} />
      {dataVisibility ? (
        <GraphDeltaValueNew
          videoRef={videoRef}
          dataPoint1Data={dataPointData}
          dataPoint1Name={dataPointName}
          dataPoint1Label={dataPointLabel}
          dataVisibility={dataVisibility}
          graphRef={graphRef}
        />
      ) : null}
    </div>
  );
};

export default React.memo(GroundAngleGraphNew);
