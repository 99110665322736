import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { customLogo, customName, customPadding } from '../../utils/custom-labeling';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedbackModal from '../modals/FeedbackModal';

import {
  faArrowUpFromBracket,
  faChartLine,
  faGear,
  faRightFromBracket,
  faUser,
  faComments,
  faTriangleExclamation,
  faChartSimple,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import styles from './Navbar.module.css';
import { useSelector } from 'react-redux';
import { getUserData } from '../../store/selectors/userSelectors';
import { getSubdomain } from '../../utils/getSubdomain';
import WithPayWall from '../HOCs/WithPayWall';

export default function CustomNavbar() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [shouldHideNavbar, setShouldHideNavbar] = useState(false);
  const [navbarClassName, setNavbarClassName] = useState(styles.navbar);

  useEffect(() => {
    // throw new Error('Test Error');
  }, []);

  const userData = useSelector(getUserData);

  const showDropdown1 = (e) => {
    setShow2(false);

    setShow1(!show1);
  };
  const toggleDropdown1 = (e) => {
    setShow1(!show1);
  };
  const hideDropdown1 = (e) => {
    setShow1(false);
  };

  const showDropdown2 = (e) => {
    setShow1(false);

    setShow2(!show2);
  };
  const toggleDropdown2 = (e) => {
    setShow2(!show2);
  };
  const hideDropdown2 = (e) => {
    setShow2(false);
  };
  const closeExpandedDropdown = (e) => {
    setExpanded(false);
  };

  async function handleLogout() {
    try {
      const user = await logout();
      setExpanded(false);

      navigate('/login');
    } catch {}
  }

  const handleOpenModal = () => {
    setIsFeedbackModalOpen(true);
    closeExpandedDropdown();
  };

  // New useEffect to listen for scroll events
  useEffect(() => {
    let lastScrollTop = 0;
    function handleScroll() {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (window.innerWidth <= 991) {
        if (currentScrollTop > lastScrollTop && currentScrollTop > 50) {
          setShouldHideNavbar(true);
        } else {
          setShouldHideNavbar(false);
        }
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setNavbarClassName(`${styles.navbar} ${shouldHideNavbar ? styles.hidden : null}`);
  }, [shouldHideNavbar]);

  return (
    <Navbar expanded={expanded} className={navbarClassName} collapseOnSelect expand='md' bg='light' variant='light'>
      <Container>
        <Navbar.Brand className={styles.logoLink} style={{ padding: '0px' }}>
          <Link to='/'>
            <img id={styles.logo} style={{ padding: customPadding }} src={customLogo} alt={`${customName} Logo`} />
          </Link>
        </Navbar.Brand>
        {currentUser ? (
          <>
            <Navbar.Toggle
              onClick={() => setExpanded(expanded ? false : 'expanded')}
              aria-controls='responsive-navbar-nav'
            />
            <Navbar.Collapse
              id='responsive-navbar-nav'
              className={`justify-content-end ${styles.navbarCollapse}`}
              style={{
                backgroundColor: '#f8f9fa',
              }}
            >
              <Nav className={styles.nav}>
                <Nav.Link
                  hidden={getSubdomain() !== null}
                  as={Link}
                  className={styles.navLink}
                  onClick={closeExpandedDropdown}
                  to='/form'
                >
                  <FontAwesomeIcon className={styles.faIcon} icon={faArrowUpFromBracket} />
                  <span className={styles.labelText}>Upload</span>
                </Nav.Link>

                <Nav.Link reloadDocument className={styles.navLink} as={Link} onClick={closeExpandedDropdown} to='/'>
                  <FontAwesomeIcon className={styles.faIcon} icon={faChartLine} />
                  <span className={styles.labelText}>Analyze</span>
                </Nav.Link>

                {['demo@parados.app', 'pascal@parados.app', 'yehor@parados.app'].includes(currentUser.email) ? (
                  <WithPayWall acceptedPlanIDs={[9]}>
                    <Nav.Link
                      hidden={getSubdomain() !== null}
                      as={Link}
                      className={styles.navLink}
                      onClick={closeExpandedDropdown}
                      to='/demo-dashboard/squat'
                    >
                      <FontAwesomeIcon className={styles.faIcon} icon={faChartSimple} />
                      <span className={styles.labelText}>Dashboard</span>
                    </Nav.Link>
                  </WithPayWall>
                ) : null}

                {/* parados HQ will be back when there's more content */}
                {/* <Nav.Link
                  className={styles.navLink}
                  href='https://parados.notion.site/8764206e067e4b468eb549f50b4b8073?v=ebd24ed8a4fb4272bb0a5216bbcc54af'
                  rel='noopener noreferrer'
                  target='_blank'
                  title='Parados Learning Platform'
                >
                  <FontAwesomeIcon className={styles.faIcon} icon={faGraduationCap} />

                  <span className={`${styles.labelText} ${styles.mobileOnly}`}>ParadosHQ</span>
                </Nav.Link> */}

                <NavDropdown
                  show={show1}
                  onMouseEnter={showDropdown1}
                  onMouseLeave={hideDropdown1}
                  onClick={toggleDropdown1}
                  title={
                    <>
                      <FontAwesomeIcon className={styles.faIcon} icon={faCircleQuestion} />
                      <span className={`${styles.labelText} ${styles.mobileOnly}`} style={{ marginLeft: '6px' }}>
                        Help
                      </span>
                    </>
                  }
                  className={`${styles.dropdown} ${styles.reducedGap}`}
                  align='end'
                >
                  <NavDropdown.Item
                    className={styles.navDropdownLink}
                    as={Link}
                    onClick={closeExpandedDropdown}
                    to='/welcome'
                  >
                    <FontAwesomeIcon className={styles.faIcon} icon={faComments} />
                    <span className={styles.labelText}>FAQ</span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider className='my-1' />

                  {/* <NavDropdown.Item
                    className={styles.navDropdownLink}
                    as={Link}
                    onClick={closeExpandedDropdown}
                    to='/problem-report'
                  >
                    <FontAwesomeIcon className={styles.faIcon} icon={faBug} />
                    <span className={styles.labelText}>Problem Report</span>
                  </NavDropdown.Item> */}

                  <NavDropdown.Item
                    className={styles.navDropdownLink}
                    // as={Link}
                    onClick={handleOpenModal}
                    // to='/feedback'
                  >
                    <FontAwesomeIcon className={styles.faIcon} icon={faTriangleExclamation} />
                    <span className={styles.labelText}>Feedback</span>
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  hidden={getSubdomain() !== null}
                  show={show2}
                  onMouseEnter={showDropdown2}
                  onMouseLeave={hideDropdown2}
                  onClick={toggleDropdown2}
                  title={
                    <>
                      <FontAwesomeIcon className={styles.faIcon} icon={faGear} />
                      <span className={`${styles.labelText} ${styles.mobileOnly}`} style={{ marginLeft: '6px' }}>
                        Settings
                      </span>
                    </>
                  }
                  className={styles.dropdown}
                  align='end'
                >
                  <NavDropdown.Item
                    className={styles.navDropdownLink}
                    as={Link}
                    onClick={closeExpandedDropdown}
                    to='/settings'
                  >
                    <FontAwesomeIcon className={styles.faIcon} icon={faUser} />
                    <span className={styles.labelText}>Account</span>
                  </NavDropdown.Item>

                  {userData.Role === 'Trainer' ? (
                    <NavDropdown.Item
                      className={styles.navDropdownLink}
                      as={Link}
                      onClick={closeExpandedDropdown}
                      to='/billing'
                    >
                      <FontAwesomeIcon className={styles.faIcon} icon={faCreditCard} />
                      <span className={styles.labelText}>Billing</span>
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item
                      className={styles.navDropdownLink}
                      onClick={closeExpandedDropdown}
                      href='https://buy.stripe.com/bIY9DfbH3fYXbBu149'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <FontAwesomeIcon className={styles.faIcon} icon={faCreditCard} />
                      <span className={styles.labelText}>Pay for Parados</span>
                    </NavDropdown.Item>
                  )}

                  <NavDropdown.Divider className='my-1' />
                  <NavDropdown.Item className={styles.navDropdownLogout} onClick={handleLogout}>
                    <FontAwesomeIcon className={styles.faIcon} icon={faRightFromBracket} />
                    <span className={styles.labelText}>Log Out</span>
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link
                  hidden={getSubdomain() === null}
                  className={styles.navLink}
                  style={{ minWidth: 'auto' }}
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon={faRightFromBracket} className={`${styles.faIcon} ${styles.logoutIcon}`} />
                  <span className={`${styles.labelText} ${styles.mobileOnly}`}>Log Out</span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </>
        ) : null}
      </Container>
      <FeedbackModal isActive={isFeedbackModalOpen} closeModal={() => setIsFeedbackModalOpen(false)} />
    </Navbar>
  );
}
