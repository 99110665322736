import React, { useEffect, useRef, useState } from 'react';
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconBoxAlignLeft,
  IconChevronLeft,
  IconChevronRight,
  IconNote,
} from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase';

import styles from './ComparisonResult.module.css';
import LineGraph from '../lineGraph/LineGraph';
import AngleDisparityLine from '../angleDisparityLine/AngleDisparityLine';
import DatavizControl from '../datavizControl/DatavizControl';
import TiltCircle from '../tiltCircle/TiltCircle';
import ROM from '../ROM/ROM';
import Rotation from '../rotation/Rotation';
import RangeSliderTitle from '../rangeSliderTitle/RangeSliderTitle';
import CommentModal from '../modals/CommentModal';
import { ChevronDownLeft } from 'tabler-icons-react';
import GroundAngleGraph from '../groundAngleGraph/GroundAngleGraph';
import VelocityGraph from '../velocityGraph/VelocityGraph';
import CenterOfBody from '../centerOfBody/CenterOfBody';

import jointsInitialDisplay from '../../data/jointsInitialDisplay';

function ComparisonResult({
  result,
  result2,
  currentPreset,
  currentPreset2,
  updateInfoJson,
  comment: initialComment,
  comment2: initialComment2,
  videoName,
  videoName2,
  fetchData,
}) {
  const [comment, setComment] = useState(initialComment);
  const [comment2, setComment2] = useState(initialComment2);

  const [resultFiles, setResultFiles] = useState({});
  const [resultFiles2, setResultFiles2] = useState({});

  const [linedata, setLinedata] = useState(null);
  const [linedata2, setLinedata2] = useState(null);

  const [jointsDisplay, setJointsDisplay] = useState(currentPreset || jointsInitialDisplay);
  const [jointsDisplay2, setJointsDisplay2] = useState(currentPreset2 || jointsInitialDisplay);

  const [lineLimits, setLineLimits] = useState({});
  const [tiltLimits, setTiltLimits] = useState({});
  const [isModalActive, setIsModalActive] = useState(false);

  const [showNavbar1, setShowNavbar1] = useState(true);
  const [bgColorClass, setBgColorClass] = useState(null);

  const videoRef = useRef();
  const videoRef2 = useRef();

  const videoListRef = ref(storage, `/${result?._location.path_}`);
  const videoListRef2 = ref(storage, `/${result2?._location.path_}`);

  const elementRefs = useRef([]);

  const toggleNavbar = () => {
    setShowNavbar1(!showNavbar1);
  };

  const handleDownload = () => {
    if (linedata) {
      const jsonBlob = new Blob([JSON.stringify(linedata)], { type: 'application/json' });
      saveAs(jsonBlob, videoName.split(' ').join('_') + '.json');
    }
  };

  const handleDownload2 = () => {
    if (linedata2) {
      const jsonBlob = new Blob([JSON.stringify(linedata2)], { type: 'application/json' });
      saveAs(jsonBlob, videoName2.split(' ').join('_') + '.json');
    }
  };

  const handleScrollLink = (e, id) => {
    // e.preventDefault(); // prevent default behavior of the browser
    const targetElement = elementRefs.current[id];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  // Function to scroll the video to a particular time
  const scrollToTime = (percentage) => {
    if (videoRef.current) {
      const video = videoRef.current;
      const time = (video.duration * percentage) / 100;
      video.currentTime = time;

      video.pause();
    }
  };

  const scrollToTime2 = (percentage) => {
    if (videoRef2.current) {
      const video = videoRef2.current;
      const time = (video.duration * percentage) / 100;
      video.currentTime = time;

      video.pause();
    }
  };

  function isEveryItemEmpty(linedata, propertyName) {
    return linedata.every((item) => item[propertyName] === null || item[propertyName] === undefined);
  }

  const handleCheckboxClick = (jointKey, keyToUpdate, side = null) => {
    const updatedJointsDisplay = { ...jointsDisplay };

    // Update the display value
    if (side) {
      updatedJointsDisplay[jointKey][keyToUpdate][side] = !updatedJointsDisplay[jointKey][keyToUpdate][side];
    } else {
      updatedJointsDisplay[jointKey][keyToUpdate].display = !updatedJointsDisplay[jointKey][keyToUpdate].display;
    }

    setJointsDisplay(updatedJointsDisplay);

    const newInfo = { preset: updatedJointsDisplay };
    const videoRefPath = result?._location.path_;

    // Update info.json in Firebase
    updateInfoJson(videoRefPath, newInfo);
  };

  const handleCheckboxClick2 = (jointKey, keyToUpdate, side = null) => {
    const updatedJointsDisplay = { ...jointsDisplay2 };

    // Update the display value
    if (side) {
      updatedJointsDisplay[jointKey][keyToUpdate][side] = !updatedJointsDisplay[jointKey][keyToUpdate][side];
    } else {
      updatedJointsDisplay[jointKey][keyToUpdate].display = !updatedJointsDisplay[jointKey][keyToUpdate].display;
    }

    setJointsDisplay2(updatedJointsDisplay);

    const newInfo = { preset: updatedJointsDisplay };
    const videoRefPath = result2?._location.path_;

    // Update info.json in Firebase
    updateInfoJson(videoRefPath, newInfo);
  };

  const handleKeyDown = (e) => {
    if (videoRef.current) {
      if (e.key === 's' || e.key === 'S' || e.key === ' ') {
        e.preventDefault();
        videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
        videoRef2.current.paused ? videoRef2.current.play() : videoRef2.current.pause();
      }
      if (e.key === 'd' || e.key === 'D' || e.key === 'ArrowRight') {
        e.preventDefault();
        videoRef.current.currentTime += 1 / 25;
        videoRef2.current.currentTime += 1 / 25;
      }
      if (e.key === 'a' || e.key === 'A' || e.key === 'ArrowLeft') {
        e.preventDefault();
        videoRef.current.currentTime -= 1 / 25;
        videoRef2.current.currentTime -= 1 / 25;
      }
    }
  };

  const getFirebaseFiles = async (videoListRef, setResultFiles, setLineData) => {
    try {
      const response = await listAll(videoListRef);
      response.items.forEach(async (item) => {
        if (item._location.path.endsWith('/angles.json')) {
          // Check if item ends with '/angles.json'
          getDownloadURL(item)
            .then((url) => fetch(url))
            .then((response) => response.json())
            .then((data) => {
              setLineData(data);
            })
            .catch((error) => console.error(error));
        } else if (item._location.path.endsWith('.mp4')) {
          getDownloadURL(item).then((url) => {
            setResultFiles((prev) => ({
              ...prev,
              Result: url,
            }));
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const checkDataAvailability = (dataType) => {
    if (!linedata || !linedata2) {
      return false;
    }

    // Assume jointsDisplay is an object with keys as jointNames
    for (const jointName in jointsDisplay) {
      switch (dataType) {
        case 'graph':
          if (jointsDisplay[jointName]?.graph?.display) {
            return true;
          }
          break;
        case 'velocity':
          if (jointsDisplay[jointName]?.velocity?.display) {
            return true;
          }
          break;
        case 'tilt':
          if (jointsDisplay[jointName]?.tilt?.display || jointsDisplay[jointName]?.vsHipsGraph?.display) {
            return true;
          }
          break;
        case 'cob':
          if (jointsDisplay[jointName]?.centerOfBody?.display) {
            return true;
          }
          break;

        // Add other data types as needed
        default:
          console.warn(`Unknown data type: ${dataType}`);
          return false;
      }
    }

    for (const jointName in jointsDisplay2) {
      switch (dataType) {
        case 'graph':
          if (jointsDisplay2[jointName]?.graph?.display) {
            return true;
          }
          break;
        case 'velocity':
          if (jointsDisplay2[jointName]?.velocity?.display) {
            return true;
          }
          break;
        case 'tilt':
          if (jointsDisplay2[jointName]?.tilt?.display || jointsDisplay2[jointName]?.vsHipsGraph?.display) {
            return true;
          }
          break;
        case 'cob':
          if (jointsDisplay2[jointName]?.centerOfBody?.display) {
            return true;
          }
          break;

        // Add other data types as needed
        default:
          console.warn(`Unknown data type: ${dataType}`);
          return false;
      }
    }

    return false;
  };

  useEffect(() => {
    setComment(initialComment);
    setLinedata(null);
    getFirebaseFiles(videoListRef, setResultFiles, setLinedata);

    // Add the event listener with the 'keydown' handler
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [result]);

  useEffect(() => {
    setComment2(initialComment2);
    setLinedata2(null);
    getFirebaseFiles(videoListRef2, setResultFiles2, setLinedata2);
  }, [result2]);

  useEffect(() => {
    setJointsDisplay(currentPreset);
  }, [currentPreset]);

  useEffect(() => {
    setJointsDisplay2(currentPreset2);
  }, [currentPreset2]);

  useEffect(() => {
    if (showNavbar1) {
      setBgColorClass('left');
    } else {
      setBgColorClass('right');
    }

    // Reset the background color to gray after 3 seconds
    const timer = setTimeout(() => {
      setBgColorClass(null);
    }, 1500);

    return () => clearTimeout(timer);
  }, [showNavbar1]);

  return (
    <>
      <div className={styles.footerAdjuster}></div>

      <div className={styles.result}>
        <div className={styles.left}>
          {resultFiles.Result ? (
            <>
              <video
                className={`${styles.video} ${bgColorClass === 'left' ? styles.orangeBG : null}`}
                src={resultFiles.Result}
                ref={videoRef}
                type='video/mp4'
                width='320'
                autoPlay
                loop
                muted
                controls
                playsInline
              ></video>
              <div className={styles.title}>
                <h3>{videoName}</h3>
              </div>
              <div
                onClick={() => {
                  setIsModalActive('comment1');
                }}
                className={styles.notesBtn}
              >
                Notes <IconNote color='#272727' />
              </div>
            </>
          ) : null}
        </div>

        <div className={styles.center}>
          {linedata && linedata2 ? (
            <>
              <div className={styles.navbarControl}>
                <div className={`${styles.navbarIcon} ${showNavbar1 ? styles.selected : null}`} onClick={toggleNavbar}>
                  <IconChevronLeft />
                </div>
                <div className={styles.twoNavbars}>
                  <div className={`${styles.insideNavbar} ${showNavbar1 ? styles.visible : styles.hiddenLeft}`}>
                    <DatavizControl
                      handleScrollLink={handleScrollLink}
                      jointsDisplay={jointsDisplay}
                      handleCheckboxClick={handleCheckboxClick}
                      handleDownload={handleDownload}
                      setIsModalActive={setIsModalActive}
                      isCompare={true}
                    />
                  </div>
                  <div className={`${styles.insideNavbar} ${showNavbar1 ? styles.hiddenRight : styles.visible}`}>
                    <DatavizControl
                      handleScrollLink={handleScrollLink}
                      jointsDisplay={jointsDisplay2}
                      handleCheckboxClick={handleCheckboxClick2}
                      handleDownload={handleDownload2}
                      setIsModalActive={setIsModalActive}
                      isCompare={true}
                    />
                  </div>
                </div>

                <div className={`${styles.navbarIcon} ${!showNavbar1 ? styles.selected : null}`} onClick={toggleNavbar}>
                  <IconChevronRight />
                </div>
              </div>

              {/* <div id={styles.leftGreyBlock} className={bgColorClass === 'left' ? styles.blueBG : null} />
              <div id={styles.rightGreyBlock} className={bgColorClass === 'right' ? styles.blueBG : null} /> */}

              <div id={styles.leftGreyBlock} />
              <div id={styles.rightGreyBlock} />

              <div className={styles.dataviz}>
                <div className={styles.graphsSection}>
                  {checkDataAvailability('graph') && <h3>Joint Angles</h3>}
                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (['Upper_Leg', 'Torso', 'Lower_Leg'].includes(jointName)) {
                      return null;
                    }
                    // Check if linedata.tilt is false and return null
                    if (!jointsDisplay[jointName]?.graph?.display && !jointsDisplay2[jointName]?.graph?.display) {
                      return null;
                    }

                    if (
                      !isEveryItemEmpty(linedata, 'LEFT_' + jointName.slice(0, -1).toUpperCase()) &&
                      !isEveryItemEmpty(linedata, 'RIGHT_' + jointName.slice(0, -1).toUpperCase()) &&
                      !isEveryItemEmpty(linedata2, 'LEFT_' + jointName.slice(0, -1).toUpperCase()) &&
                      !isEveryItemEmpty(linedata2, 'RIGHT_' + jointName.slice(0, -1).toUpperCase())
                    ) {
                      let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase();
                      let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase();

                      const dataPoint1Label = 'Left';
                      const dataPoint2Label = 'Right';
                      return (
                        <React.Fragment key={`${jointName}Graph`}>
                          <p className={styles.label}>{jointName.split('_').join(' ')}:</p>
                          {jointsDisplay[jointName]?.graph?.display ? (
                            <div className={styles.leftGraph}>
                              <LineGraph
                                ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)}
                                videoRef={videoRef}
                                linedata={linedata}
                                dataPoint1Name={dataPoint1Name}
                                dataPoint2Name={dataPoint2Name}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime}
                                jointDisplay={jointsDisplay[jointName].graph}
                                onLabelClick={(keyToUpdate, side) => handleCheckboxClick(jointName, keyToUpdate, side)}
                              />
                            </div>
                          ) : null}
                          {jointsDisplay2[jointName]?.graph?.display ? (
                            <div style={{ backgroundColor: 'ff0000' }} className={styles.rightGraph}>
                              <LineGraph
                                ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)}
                                videoRef={videoRef2}
                                linedata={linedata2}
                                dataPoint1Name={dataPoint1Name}
                                dataPoint2Name={dataPoint2Name}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime2}
                                jointDisplay={jointsDisplay2[jointName].graph}
                                onLabelClick={(keyToUpdate, side) => handleCheckboxClick(jointName, keyToUpdate, side)}
                              />
                            </div>
                          ) : null}
                          <br />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data is not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}
                  {checkDataAvailability('graph') && <hr />}

                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (['Shoulders', 'Hips', 'Knees', 'Ankles', 'Elbows', 'Wrists'].includes(jointName)) {
                      return null;
                    }

                    if (!jointsDisplay[jointName]?.graph?.display && !jointsDisplay2[jointName]?.graph?.display) {
                      return null;
                    }

                    if (
                      !isEveryItemEmpty(linedata, jointName.toUpperCase() + '_ANGLE') &&
                      !isEveryItemEmpty(linedata2, jointName.toUpperCase() + '_ANGLE')
                    ) {
                      let dataPointName = jointName.toUpperCase() + '_ANGLE';

                      const dataPointLabel = jointName.split('_').join(' ');
                      return (
                        <React.Fragment key={`${jointName}Graph`}>
                          <p className={styles.label}>{jointName.split('_').join(' ')}:</p>
                          {jointsDisplay[jointName]?.graph?.display ? (
                            <div className={styles.leftGraph}>
                              <GroundAngleGraph
                                ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)}
                                videoRef={videoRef}
                                linedata={linedata}
                                dataPointName={dataPointName}
                                dataPointLabel={dataPointLabel}
                                scrollToTime={scrollToTime}
                                jointDisplay={jointsDisplay[jointName].graph}
                                onLabelClick={(keyToUpdate, side) => handleCheckboxClick(jointName, keyToUpdate, side)}
                              />
                            </div>
                          ) : null}
                          {jointsDisplay2[jointName]?.graph?.display ? (
                            <div className={styles.rightGraph}>
                              <GroundAngleGraph
                                ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)}
                                videoRef={videoRef2}
                                linedata={linedata2}
                                dataPointName={dataPointName}
                                dataPointLabel={dataPointLabel}
                                scrollToTime={scrollToTime2}
                                jointDisplay={jointsDisplay2[jointName].graph}
                                onLabelClick={(keyToUpdate, side) => handleCheckboxClick2(jointName, keyToUpdate, side)}
                              />
                            </div>
                          ) : null}
                          <br />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.split('_').join(' ')} data is not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}
                  {checkDataAvailability('velocity') && checkDataAvailability('graph') && <hr />}

                  {checkDataAvailability('velocity') && <h3>Velocity</h3>}

                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (!jointsDisplay[jointName]?.velocity?.display && !jointsDisplay2[jointName]?.velocity?.display) {
                      return null;
                    }

                    if (
                      !isEveryItemEmpty(linedata, 'X:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'Y:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'X:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'Y:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'X:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'Y:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'X:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'Y:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY')
                    ) {
                      let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase();
                      let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase();

                      const dataPoint1Label = 'Left';
                      const dataPoint2Label = 'Right';
                      return (
                        <React.Fragment key={`${jointName}Velocity`}>
                          <p className={styles.label}>{jointName.slice(0, -1)} Velocity:</p>
                          {jointsDisplay[jointName]?.velocity?.display ? (
                            <div className={styles.leftGraph}>
                              <VelocityGraph
                                ref={(el) => (elementRefs.current[`${jointName}Velocity`] = el)}
                                videoRef={videoRef}
                                linedata={linedata}
                                dataPoint1Name={dataPoint1Name + '_VELOCITY'}
                                dataPoint2Name={dataPoint2Name + '_VELOCITY'}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime}
                                jointDisplay={jointsDisplay[jointName].velocity}
                                onLabelClick={(keyToUpdate, side) => handleCheckboxClick(jointName, keyToUpdate, side)}
                              />
                            </div>
                          ) : null}

                          {jointsDisplay2[jointName]?.velocity?.display ? (
                            <div className={styles.rightGraph}>
                              <VelocityGraph
                                ref={(el) => (elementRefs.current[`${jointName}Velocity`] = el)}
                                videoRef={videoRef2}
                                linedata={linedata2}
                                dataPoint1Name={dataPoint1Name + '_VELOCITY'}
                                dataPoint2Name={dataPoint2Name + '_VELOCITY'}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime2}
                                jointDisplay={jointsDisplay2[jointName].velocity}
                                onLabelClick={(keyToUpdate, side) => handleCheckboxClick2(jointName, keyToUpdate, side)}
                              />
                            </div>
                          ) : null}
                          <br />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} velocity data is not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}
                  {checkDataAvailability('tilt') && checkDataAvailability('velocity') && <hr />}
                  {checkDataAvailability('tilt') && <h3>Tilt</h3>}

                  <div className={styles.tiltSection}>
                    <div className={styles.divider} />

                    {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                      if (!jointsDisplay[jointName]?.tilt?.display && !jointsDisplay2[jointName]?.tilt?.display) {
                        return null;
                      }

                      if (!isEveryItemEmpty(linedata, 'TILT_SHOULDER')) {
                        return (
                          <React.Fragment key={`${jointName}Tilt`}>
                            <RangeSliderTitle
                              title={`${jointName}`}
                              minValue='0'
                              maxValue='45'
                              lineLimit={tiltLimits[jointName] || 5}
                              setLineLimit={(value) => {
                                setTiltLimits((prevLimits) => ({ ...prevLimits, [jointName]: value }));
                              }}
                              isCompare={true}
                            />
                            <div className={styles.dividedDataSection}>
                              {jointsDisplay[jointName]?.tilt?.display ? (
                                <TiltCircle
                                  ref={(el) => (elementRefs.current[`${jointName}Tilt`] = el)}
                                  linedata={linedata}
                                  videoRef={videoRef}
                                  jointName={jointName}
                                  lineLimit={tiltLimits[jointName] || 5}
                                  isCompare={true}
                                />
                              ) : (
                                <div></div>
                              )}
                              {jointsDisplay2[jointName]?.tilt?.display ? (
                                <TiltCircle
                                  ref={(el) => (elementRefs.current[`${jointName}Tilt`] = el)}
                                  linedata={linedata2}
                                  videoRef={videoRef2}
                                  jointName={jointName}
                                  lineLimit={tiltLimits[jointName] || 5}
                                  isCompare={true}
                                />
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <div key={`${jointName}Unavailable`}>
                            <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data is not available</h4>
                            <br />
                          </div>
                        );
                      }
                    })}
                  </div>

                  {!jointsDisplay.Shoulders?.vsHipsGraph?.display &&
                  !jointsDisplay2.Shoulders?.vsHipsGraph?.display ? null : !(
                      isEveryItemEmpty(linedata, 'TILT_SHOULDER') || isEveryItemEmpty(linedata, 'TILT_HIP')
                    ) ? (
                    <>
                      <p className={styles.label}>Shoulder vs Hip Tilt:</p>

                      {jointsDisplay.Shoulders?.vsHipsGraph?.display ? (
                        <div className={styles.leftGraph}>
                          <LineGraph
                            ref={(el) => (elementRefs.current['shoulderHipGraph'] = el)}
                            videoRef={videoRef}
                            linedata={linedata}
                            dataPoint1Name={'TILT_SHOULDER'}
                            dataPoint2Name={'TILT_HIP'}
                            dataPoint1Label={'Shoulders'}
                            dataPoint2Label={'Hips'}
                            scrollToTime={scrollToTime}
                            jointDisplay={jointsDisplay.Shoulders.vsHipsGraph}
                            onLabelClick={(keyToUpdate, side) => handleCheckboxClick('Shoulders', keyToUpdate, side)}
                          />
                        </div>
                      ) : null}

                      {jointsDisplay2.Shoulders?.vsHipsGraph?.display ? (
                        <div className={styles.rightGraph}>
                          <LineGraph
                            ref={(el) => (elementRefs.current['shoulderHipGraph'] = el)}
                            videoRef={videoRef2}
                            linedata={linedata2}
                            dataPoint1Name={'TILT_SHOULDER'}
                            dataPoint2Name={'TILT_HIP'}
                            dataPoint1Label={'Shoulders'}
                            dataPoint2Label={'Hips'}
                            scrollToTime={scrollToTime2}
                            jointDisplay={jointsDisplay2.Shoulders.vsHipsGraph}
                            onLabelClick={(keyToUpdate, side) => handleCheckboxClick2('Shoulders', keyToUpdate, side)}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div key={`vsHipsGraphUnavailable`}>
                      <h4 style={{ color: '#727272' }}>Shoulders vs Hips data is not available</h4>
                      <br />
                    </div>
                  )}
                  <br />

                  {checkDataAvailability('cob') && checkDataAvailability('tilt') && <hr />}
                  {checkDataAvailability('cob') && <h3>Center of Body</h3>}

                  <div className={styles.cobSection}>
                    <div className={styles.divider} />

                    {!jointsDisplay.Shoulders?.centerOfBody?.display &&
                    !jointsDisplay2.Shoulders?.centerOfBody?.display ? null : (
                      <div className={styles.dividedDataSection}>
                        {!(isEveryItemEmpty(linedata, 'COB_X') || isEveryItemEmpty(linedata, 'COB_Y')) ? (
                          jointsDisplay.Shoulders?.centerOfBody?.display ? (
                            <CenterOfBody
                              ref={(el) => (elementRefs.current[`centerOfBody`] = el)}
                              linedata={linedata}
                              videoRef={videoRef}
                              isCompare={true}
                            />
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div key={`centerOfBodyUnavailable`}>
                            <h4 style={{ color: '#727272' }}>Center of Body data is not available</h4>
                            <br />
                          </div>
                        )}
                        {!(isEveryItemEmpty(linedata2, 'COB_X') || isEveryItemEmpty(linedata2, 'COB_Y')) ? (
                          jointsDisplay2.Shoulders?.centerOfBody?.display ? (
                            <CenterOfBody
                              ref={(el) => (elementRefs.current[`centerOfBody`] = el)}
                              linedata={linedata2}
                              videoRef={videoRef2}
                              isCompare={true}
                            />
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div key={`centerOfBodyUnavailable`}>
                            <h4 style={{ color: '#727272' }}>Center of Body data is not available</h4>
                            <br />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : linedata && !linedata2 ? (
            <div className={styles.dropdownPrompt}>
              <h4 style={{ textAlign: 'right' }}>
                Select a second video <IconArrowNarrowRight />
              </h4>
            </div>
          ) : !linedata && linedata2 ? (
            <div className={styles.dropdownPrompt}>
              <h4>
                <IconArrowNarrowLeft /> Select a second video
              </h4>
            </div>
          ) : null}
        </div>

        <div className={styles.right}>
          {resultFiles2.Result ? (
            <>
              <video
                className={`${styles.video} ${bgColorClass === 'right' ? styles.blueBG : null}`}
                src={resultFiles2.Result}
                ref={videoRef2}
                type='video/mp4'
                width='320'
                autoPlay
                loop
                muted
                controls
                playsInline
              ></video>
              <div className={styles.title}>
                <h3>{videoName2}</h3>
              </div>
              <div
                onClick={() => {
                  setIsModalActive('comment2');
                }}
                className={styles.notesBtn}
              >
                <IconNote color='#272727' /> Notes
              </div>
            </>
          ) : null}
        </div>
      </div>
      <CommentModal
        isActive={isModalActive === 'comment1'} // Only open the modal if isModalActive is 'comment1'
        setIsModalActive={setIsModalActive}
        videoRef={result}
        comment={comment}
        setComment={setComment}
        videoName={videoName}
        fetchData={fetchData}
      />

      <CommentModal
        isActive={isModalActive === 'comment2'} // Only open the modal if isModalActive is 'comment2'
        setIsModalActive={setIsModalActive}
        videoRef={result2}
        comment={comment2}
        setComment={setComment2}
        videoName={videoName2}
        fetchData={fetchData}
      />
    </>
  );

  // <>
  //   {!linedata && !linedata2 ? (
  //     <img
  //       className={styles.loadingGif}
  //       src='https://vote.elections.virginia.gov/Content/images/loading_animated.gif'
  //       alt=''
  //       width='100px'
  //       height='100px'
  //     />
  //   ) : null}

  //   {linedata && !linedata2 ? (
  //     <div style={{ maxWidth: '70%' }}>
  //       <h3>Select a second video from the right dropdown...</h3>
  //     </div>
  //   ) : null}

  //   {!linedata && linedata2 ? (
  //     <div style={{ maxWidth: '70%' }}>
  //       <h3>Select a second video from the left dropdown...</h3>
  //     </div>
  //   ) : null}
  // </>
}

export default ComparisonResult;
