import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './ResultList.module.css';
import { differenceInDays, format, formatDistance } from 'date-fns';

import { Link, useLocation } from 'react-router-dom';
import loadingGif from '../../assets/loading-image.gif';
import SearchBar from '../searchBar/SearchBar';
import ResultListFolder from '../resultListFolder/ResultListFolder';
import ResultListVideoItem from '../resultListVideoItem/ResultListVideoItem';
import { useSelector } from 'react-redux';
import { getUserLoading } from '../../store/selectors/userSelectors';
import { exerciseTable } from '../../airtable-scripts';
import { IconLoader } from '@tabler/icons-react';
import { getSubdomain } from '../../utils/getSubdomain';
import clsx from 'clsx';
import WithPayWall from '../HOCs/WithPayWall';

function getUrlParameter(name) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(name);
}

function ResultList({
  resultList,
  currentResult,
  handleSelectVideo,
  onEditVideo,
  isSecond = false,
  isDashboardLoading = false,
}) {
  const [openFolders, setOpenFolders] = useState({});
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [filteredResults, setFilteredResults] = useState([]); // State for filtered results
  const [exerciseDatabase, setExerciseDatabase] = useState([]);

  const searchInputRef = useRef(null);
  const isLoading = useSelector(getUserLoading) || isDashboardLoading;

  const selectedFolderParam = getUrlParameter('selectedFolder');
  const location = useLocation();

  const [isSideListOpen, setIsSideListOpen] = useState(
    (selectedFolderParam || location.state) && !isSecond ? false : true
  );

  function handleEditClick(result) {
    onEditVideo(result.path, result.videoName); // Call the onEditVideo prop
  }

  function handleFolderToggle(athleteName) {
    setOpenFolders((prevState) => {
      if (searchQuery === '') {
        return Object.fromEntries(
          Object.keys(prevState).map((key) => [key, key === athleteName ? !prevState[athleteName] : false])
        );
      } else {
        return {
          ...prevState,
          [athleteName]: !prevState[athleteName],
        };
      }
    });
  }

  useEffect(() => {
    const filteredResults = resultList.map((user) => {
      const filteredVideos = filterVideos(user, searchQuery);
      return { ...user, paths: filteredVideos };
    });

    setFilteredResults(filteredResults);

    if (resultList) {
      const folderState = {};
      resultList.forEach((item) => {
        folderState[item.athleteName] = openFolders[item.athleteName] || false;
      });
      setOpenFolders(folderState);
    }
  }, [resultList]);

  useEffect(() => {
    const filteredResults = resultList.map((user) => {
      const filteredVideos = filterVideos(user, searchQuery);
      return { ...user, paths: filteredVideos };
    });
    setFilteredResults(filteredResults);

    setOpenFolders((prevState) => {
      const newState = {};
      Object.keys(prevState).forEach((key) => {
        const hasVideos = filteredResults.find((user) => user.athleteName === key)?.paths?.length > 0;
        if (searchQuery !== '' && hasVideos) {
          newState[key] = true;
        } else {
          newState[key] = false;
        }
      });
      return {
        ...prevState,
        ...newState,
      };
    });
  }, [searchQuery]);

  function getexercises() {
    exerciseTable.select().eachPage(
      (records, fetchNextPage) => {
        setExerciseDatabase([...records]);
        fetchNextPage();
      },
      (err) => {
        if (err) {
          console.error(err);
          return;
        }
      }
    );
  }

  function filterVideos(user, searchQuery) {
    return user.paths.filter(
      (result) =>
        result.videoName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.date.split('-').join(' ').toLowerCase().includes(searchQuery.toLowerCase()) ||
        result.view?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  // const sortedFilteredResults = searchQuery
  //   ? [...filteredResults].sort((a, b) => {
  //       const aTimestamp = new Date(a.date);
  //       const bTimestamp = new Date(b.date);

  //       const isAOpen = filterVideos(a, searchQuery).length > 0 && openFolders[a.athleteName] ? 1 : 0;
  //       const isBOpen = filterVideos(b, searchQuery).length > 0 && openFolders[b.athleteName] ? 1 : 0;

  //       if (isAOpen !== isBOpen) {
  //         return isBOpen - isAOpen; // Sort by open status first
  //       }

  //       // If both are open or both are closed, then sort by timestamp
  //       return bTimestamp - aTimestamp;
  //     })
  //   : filteredResults;

  // TEMPORARY
  useEffect(() => {
    getexercises();
  }, []);

  const groupVideosByDate = (videos) => {
    return videos.reduce((groups, video) => {
      const date = video.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(video);
      return groups;
    }, {});
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    // Check if the date difference is more than 7 days
    if (differenceInDays(now, date) > 7) {
      // Format differently if it's the current year or not
      const currentYear = now.getFullYear();
      const formatString = date.getFullYear() === currentYear ? 'dd MMM' : 'dd MMM yyyy';
      return format(date, formatString);
    } else {
      // For dates within 7 days, use formatDistance
      return formatDistance(date, now, { addSuffix: true });
    }
  };

  return (
    <>
      <div
        className={`${styles.resultList} ${isSecond ? styles.resultListSecond : ''} ${
          isSideListOpen ? styles.open : ''
        }`}
      >
        <h2 className={styles.header}>Select Video:</h2>
        <SearchBar searchInputRef={searchInputRef} onSearch={setSearchQuery} />

        <div className={styles.list}>
          {resultList[0]?.athleteName !== undefined
            ? filteredResults.map((user, i) => {
                const filteredVideos = filterVideos(user, searchQuery);
                const groupedVideos = groupVideosByDate(filteredVideos);

                // Apply the new sorting logic here
                Object.keys(groupedVideos).forEach((date) => {
                  groupedVideos[date].sort((a, b) => {
                    if (a.timestamp && b.timestamp) {
                      return new Date(b.timestamp) - new Date(a.timestamp);
                    }
                    if (a.timestamp) return -1;
                    if (b.timestamp) return 1;
                    return new Date(b.date) - new Date(a.date);
                  });
                });

                // Sort the dates in descending order
                const sortedDates = Object.keys(groupedVideos).sort((a, b) => new Date(b) - new Date(a));

                const isFolderOpen = openFolders[user.athleteName];

                return (
                  <div
                    key={i}
                    onClick={() => handleFolderToggle(user.athleteName)}
                    className={`${styles.resultFolder} ${isFolderOpen ? '' : styles.collapsed}`}
                  >
                    {user.athleteName !== 'My Videos' ? (
                      <WithPayWall parentWidth={'max-content'} acceptedPlanIDs={[2, 3, 4, 5, 6, 7, 8, 9]}>
                        <ResultListFolder user={user} isFolderOpen={isFolderOpen} />
                      </WithPayWall>
                    ) : (
                      <ResultListFolder user={user} isFolderOpen={isFolderOpen} />
                    )}
                    <div className={styles.folderInside}>
                      {filteredVideos?.length === 0 ? (
                        <div className={styles.emptyItem} style={{ textAlign: 'center' }}>
                          {searchQuery === '' ? (
                            <h5>
                              <span>No videos yet...</span>
                              {!getSubdomain() && (
                                <span>
                                  <Link to='/form'>Upload Your First!</Link>
                                </span>
                              )}
                            </h5>
                          ) : (
                            <h5>No search matches</h5>
                          )}
                        </div>
                      ) : (
                        sortedDates.map((date) => (
                          <div key={date}>
                            <div className={styles.dateHeader}>
                              <span className={styles.dateText}>{formatDate(date)} </span>
                              <div className={styles.dateLine}></div>
                            </div>
                            {groupedVideos[date].map((result, i) => {
                              const isSelected = result.path._location.path_ === currentResult?._location.path_;
                              return (
                                <ResultListVideoItem
                                  key={i}
                                  result={result}
                                  exerciseDatabase={exerciseDatabase}
                                  isSelected={isSelected}
                                  setIsSideListOpen={setIsSideListOpen}
                                  handleSelectVideo={handleSelectVideo}
                                  handleEditClick={handleEditClick}
                                />
                              );
                            })}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                );
              })
            : null}

          <div className={`${styles.loadingPlaceholder} ${isLoading && styles.active}`}>
            <IconLoader color='#2e2e2e' className={styles.icon} />
            <h2>Loading...</h2>
          </div>
        </div>

        <div
          onClick={() => {
            setIsSideListOpen(!isSideListOpen);
          }}
          id={styles.arrow}
          className={clsx({
            [styles.loading]: isLoading,
            [styles.open]: isSideListOpen,
          })}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <div
        id={styles.outerOverlay}
        onClick={() => {
          setIsSideListOpen(false);
        }}
        className={`${isSideListOpen ? styles.open : ''}`}
      ></div>
    </>
  );
}

export default React.memo(ResultList);
