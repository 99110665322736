import React, { useRef, useEffect } from 'react';
import yAxis from './../../assets/y-axis-ruler.svg';
import styles from './CobRulerY.module.css';

const CobRulerYNew = ({ scaleFactorY, videoRef, dataPoint2Data, height }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = 16 * 2;
    canvas.height = height * 2;

    let lastRenderTime = 0;
    let requestId;

    const animate = (timestamp) => {
      if (timestamp - lastRenderTime < 16) {
        requestId = requestAnimationFrame(animate);
        return;
      }

      const videoDuration = videoRef.current?.getDuration();
      const videoCurrentTime = videoRef.current.getCurrentTime() % videoDuration;
      const newProgress = videoDuration ? videoCurrentTime / videoDuration : 0;
      const newIndex = ((dataPoint2Data.length - 1) * newProgress).toFixed(0);

      const currentCOBY = dataPoint2Data ? dataPoint2Data[newIndex] : 0;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Calculate the position of the circle based on COB_Y
      const circleY = canvas.height / 2 + currentCOBY * scaleFactorY; // Adjust scaleFactorY as needed

      // Draw the empty circle
      ctx.beginPath();
      ctx.arc(canvas.width / 2, circleY, 12, 0, 2 * Math.PI); // Outer circle
      ctx.lineWidth = 4; // Set line thickness
      ctx.strokeStyle = '#0C89C3'; // Set line color
      ctx.stroke();

      lastRenderTime = timestamp;

      // Request the next animation frame
      requestId = requestAnimationFrame(animate);
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [videoRef, dataPoint2Data, height, scaleFactorY]);

  return (
    <div className={styles.ruler} style={{ height: height, width: '16px' }}>
      <div
        className={styles.backgroundImage}
        style={{
          height: height,
          width: '16px',
          backgroundImage: `url(${yAxis})`,
          filter: 'contrast(0%)',
        }}
      ></div>
      <canvas className={styles.CoBCanvas} style={{ height: height, width: '16px' }} ref={canvasRef} />
      {/* <p className={styles.rulerLabel}>Vertical Displacement:</p> */}
    </div>
  );
};

export default React.memo(CobRulerYNew);
