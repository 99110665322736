const jointsInitialDisplay = {
    Shoulders: {
        graph: { display: true, right: true, left: true },
        velocity: { display: true, right: true, left: true },
        acceleration: { display: true, right: true, left: true },
        tilt: { display: true },
        vsHipsGraph: { display: true, shoulders: true, hips: true },
        centerOfBody: { display: true, visible: true },
    },
    Elbows: {
        graph: { display: true, right: true, left: true },
        velocity: { display: true, right: true, left: true },
        acceleration: { display: true, right: true, left: true },
    },
    Hips: {
        graph: { display: true, right: true, left: true },
        velocity: { display: true, right: true, left: true },
        acceleration: { display: true, right: true, left: true },
        tilt: { display: true },
    },
    Knees: {
        graph: { display: true, right: true, left: true },
        velocity: { display: true, right: true, left: true },
        acceleration: { display: true, right: true, left: true },
    },
    Ankles: {
        graph: { display: true, right: true, left: true },
        velocity: { display: true, right: true, left: true },
        acceleration: { display: true, right: true, left: true },
    },

    Torso: {
        graph: { display: true, visible: true },
    },
    Upper_Leg: {
        graph: { display: true, visible: true },
    },
    Lower_Leg: {
        graph: { display: true, visible: true },
    },
};

export default jointsInitialDisplay