import React, { useEffect, useRef, useState } from 'react';
import styles from './MultiGraph.module.css';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom'; // Import chartjs-plugin-zoom
import GraphProgressLine from '../graphProgressLine/GraphProgressLine';
import { Finger } from 'react-finger';

Chart.register(zoomPlugin);

const backgroundColors = [
  'rgba(229, 115, 115, 1)',
  'rgba(129, 199, 132, 1)',
  'rgba(100, 181, 246, 1)',
  'rgba(255, 183, 77, 1)',
  'rgba(244, 143, 177, 1)',
  'rgba(206, 147, 216, 1)',
  'rgba(144, 202, 249, 1)',
  'rgba(186, 104, 200, 1)',
  'rgba(126, 214, 223, 1)',
  'rgba(121, 134, 203, 1)',
  'rgba(215, 204, 200, 1)',
  'rgba(204, 204, 255, 1)',
];

const MultiVelocityGraph = ({ linedata, multiVelocityToDisplay, videoRef, scrollToTime }) => {
  const graphRef = useRef(null);
  const chartRef = useRef(null);
  const [isZoomed, setIsZoomed] = useState(false);

  const FingeredDiv = Finger('div');

  // Function to generate datasets with resultant velocity
  const generateDatasets = () => {
    return multiVelocityToDisplay.map((joint, index) => {
      const velocityData =
        linedata[joint] &&
        linedata[joint].map((_, idx) => {
          const vx = linedata[`X:${joint}_VELOCITY`][idx];
          const vy = linedata[`Y:${joint}_VELOCITY`][idx];

          if (vx === null || vy === null) return null;
          return Math.sqrt(vx * vx + vy * vy);
        });

      return {
        label: joint
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' '),
        data: velocityData,
        backgroundColor: backgroundColors[index % backgroundColors.length],
        borderColor: backgroundColors[index % backgroundColors.length],
        borderWidth: 3,
        hidden: false,
      };
    });
  };

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    normalized: false,
    animation: false,
    scales: {
      x: {
        display: false,
        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },
        },
      },
      y: {
        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },
          callback: function (value) {
            return value.toFixed(1) + 'm/s';
          },
        },
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
      tooltip: {
        callbacks: {
          title: function () {
            return '';
          },
          label: function (context) {
            const formattedValue = context.formattedValue;
            const roundedValue = Math.round(formattedValue * 100) / 100;
            return `${context.dataset.label}: ${roundedValue}m/s`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const lineChart = {
    labels: Array.from(
      { length: Math.max(...multiVelocityToDisplay.map((joint) => linedata[joint].length)) },
      (_, index) => index
    ),
    datasets: generateDatasets(),
  };

  const handleGraphClick = (event) => {
    const rect = graphRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left - 52;
    const y = event.clientY - rect.top;
    const totalWidth = rect.width - 52;
    const totalHeight = rect.height - 6;

    if (x < 0 || x > totalWidth) {
      return;
    }

    if (y < 32 || y > totalHeight) {
      return;
    }

    const percentage = (x / totalWidth) * 100;
    scrollToTime(percentage);
  };

  const handleZoom = () => {
    const chartInstance = chartRef.current;
    setIsZoomed(chartInstance.isZoomedOrPanned());
  };

  const onResetZoom = () => {
    chartRef.current.resetZoom();
    handleZoom();
  };

  // Render the component
  return (
    <>
      <div className={styles.zoomNote}>
        <p>Joint Velocities:</p>
        <button disabled={!isZoomed} onClick={onResetZoom} className={styles.resetZoomBtn}>
          Reset Zoom
        </button>
      </div>
      <div className={styles.lineGraph} style={{ position: 'relative', scrollSnapPaddingTop: '100px' }}>
        <FingeredDiv onPinch={handleZoom} onClick={!isZoomed ? handleGraphClick : null}>
          <div
            ref={graphRef}
            onWheel={handleZoom}
            style={{ cursor: isZoomed ? 'all-scroll' : 'zoom-in' }}
            className={styles.graphContainer}
          >
            <Line ref={chartRef} className={styles.line} data={lineChart} options={options} />
          </div>
        </FingeredDiv>

        {isZoomed ? null : (
          <div style={{ pointerEvents: 'none' }}>
            <GraphProgressLine videoRef={videoRef} graphRef={graphRef} leftOffset={52} />
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(MultiVelocityGraph);
