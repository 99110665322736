import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './MultiGraphModal.module.css';

// Mapping object for interpreting friendly names to keys in linedata
const jointNameMapping = {
  Shoulders: ['LEFT_SHOULDER', 'RIGHT_SHOULDER'],
  Hips: ['LEFT_HIP', 'RIGHT_HIP'],
  Elbows: ['LEFT_ELBOW', 'RIGHT_ELBOW'],
  Knees: ['LEFT_KNEE', 'RIGHT_KNEE'],
  Ankles: ['LEFT_ANKLE', 'RIGHT_ANKLE'],
};

export default function MultiVelocityModal({ isActive, jointsDisplay, onConfirm, onCancel }) {
  const [selectedJoints, setSelectedJoints] = useState({});

  if (!isActive) return null;

  const handleCheckboxChange = (joint, side) => {
    setSelectedJoints((prevState) => ({
      ...prevState,
      [joint]: {
        ...prevState[joint],
        [side]: !prevState[joint]?.[side],
      },
    }));
  };

  const handleSubmit = () => {
    const selected = [];
    for (const joint in selectedJoints) {
      let key = jointNameMapping[joint];

      // If joint is not Upper Leg, Lower Leg, or Torso, specify chosen left or right side
      if (!['Upper_Leg', 'Lower_Leg', 'Torso'].includes(joint)) {
        if (selectedJoints[joint]?.left) {
          selected.push(key[0]);
        }
        if (selectedJoints[joint]?.right) {
          selected.push(key[1]);
        }
      } else {
        if (selectedJoints[joint]?.display) {
          selected.push(key);
        }
      }
    }
    onConfirm(selected);
  };

  return (
    <div className={styles.modal}>
      <div onClick={onCancel} className={styles.overlay}></div>
      <div className={styles.card}>
        <h3>Velocity Comparison:</h3>
        <p>Select Joint Velocities to be ccompared</p>
        <FontAwesomeIcon onClick={onCancel} className={styles.cancelIcon} icon={faTimes} />
        <form>
          <table>
            <thead>
              <tr>
                <th>Joint</th>
                <th>Left</th>
                <th>Right</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(jointsDisplay).map(([joint, data]) => {
                if (data.graph) {
                  return (
                    <tr key={joint}>
                      {/* Check if joint is Lower, Upper leg, or Torso */}
                      {['Lower_Leg', 'Upper_Leg', 'Torso'].includes(joint) ? null : (
                        <>
                          <td>{joint.split('_').join(' ')}</td>
                          <td onClick={() => handleCheckboxChange(joint, 'left')}>
                            <input
                              type='checkbox'
                              checked={selectedJoints[joint]?.left || false}
                              onChange={(e) => e.preventDefault()}
                            />
                          </td>
                          <td onClick={() => handleCheckboxChange(joint, 'right')}>
                            <input
                              type='checkbox'
                              checked={selectedJoints[joint]?.right || false}
                              onChange={(e) => e.preventDefault()}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
          <button type='button' onClick={handleSubmit}>
            Ok
          </button>
        </form>
      </div>
    </div>
  );
}
