import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { useEffect, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useAuth } from './contexts/AuthContext';
import CustomErrorBoundary from './Components/customErrorBoundary/CustomErrorBoundary';
import CustomNavbar from './Components/navbar/Navbar';
import RouteChangeTracker from './Components/HOCs/RouteChangeTracker';
import { fetchUserData } from './store/actions/userActions';
import { useDispatch } from 'react-redux';
import { getSubdomain } from './utils/getSubdomain';
import LoadingIndicator from './Components/loadingIndicator/LoadingIndicator';
import Comparison from './Components/comparison/Comparison';
import Dashboard from './Components/dashboard/Dashboard';

const Login = React.lazy(() => import('./Components/login/Login'));
const PrivateRoute = React.lazy(() => import('./Components/HOCs/PrivateRoute'));
const Settings = React.lazy(() => import('./Components/settings/Settings'));
const ProblemReport = React.lazy(() => import('./Components/forms/ProblemReport'));
const FilloutForm = React.lazy(() => import('./Components/forms/FilloutForm'));
const Billing = React.lazy(() => import('./Components/billing/Billing'));
const Onboarding = React.lazy(() => import('./Components/onboarding/Onboarding'));
const OnboardingSuccess = React.lazy(() => import('./Components/onboardingSuccess/OnboardingSuccess'));
const ParadosEmployeePrivateRoute = React.lazy(() => import('./Components/HOCs/ParadosEmployeePrivateRoute'));
const PasswordReset = React.lazy(() => import('./Components/passwordReset/PasswordReset'));
const BiometricForm = React.lazy(() => import('./Components/biometricForm/BiometricForm'));
const MasterDashboard = React.lazy(() => import('./Components/masterDashboard/MasterDashboard'));
const AccountInactive = React.lazy(() => import('./Components/accountInactive/AccountInactive'));
const TestUploadForm = React.lazy(() => import('./Components/forms/TestUploadForm'));
const DashboardDemo = React.lazy(() => import('./Components/dashboardDemo/DashboardDemo'));
const ProviderSignIn = React.lazy(() => import('./Components/providerSignIn/ProviderSignIn'));

function App() {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  if (currentUser?.email) {
    localStorage.setItem('currentUserEmail', currentUser?.email);
  } else {
    localStorage.removeItem('currentUserEmail');
  }

  useEffect(() => {
    dispatch(fetchUserData());
  }, [currentUser, dispatch]);

  return (
    <>
      <CustomErrorBoundary>
        <CustomNavbar />
        <Container className='main-container d-flex justify-content-center'>
          <Suspense fallback={<LoadingIndicator />}>
            <Routes>
              {!getSubdomain()?.subdomain && (
                <>
                  <Route
                    path='/demo-dashboard/*'
                    element={
                      <ParadosEmployeePrivateRoute>
                        <DashboardDemo />
                      </ParadosEmployeePrivateRoute>
                    }
                  />
                  <Route
                    path='/form'
                    element={
                      <PrivateRoute>
                        <FilloutForm />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/biometricForm'
                    element={
                      <PrivateRoute>
                        <BiometricForm />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/billing'
                    element={
                      <PrivateRoute>
                        <Billing />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/test-upload-form'
                    element={
                      <ParadosEmployeePrivateRoute>
                        <TestUploadForm />
                      </ParadosEmployeePrivateRoute>
                    }
                  />
                  <Route
                    path='/settings'
                    element={
                      <PrivateRoute>
                        <Settings />
                      </PrivateRoute>
                    }
                  />
                </>
              )}
              <Route
                path='/'
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path='/welcome'
                element={
                  <PrivateRoute>
                    <Onboarding />
                  </PrivateRoute>
                }
              />
              <Route
                path='/onboarding-completed'
                element={
                  <PrivateRoute>
                    <OnboardingSuccess />
                  </PrivateRoute>
                }
              />
              <Route
                path='/compare'
                element={
                  <PrivateRoute>
                    <Comparison />
                  </PrivateRoute>
                }
              />
              <Route
                path='/problem-report'
                element={
                  <PrivateRoute>
                    <ProblemReport />
                  </PrivateRoute>
                }
              />
              <Route
                path='/account-inactive'
                element={
                  <PrivateRoute>
                    <AccountInactive />
                  </PrivateRoute>
                }
              />
              <Route
                path='/master-dashboard'
                element={
                  <ParadosEmployeePrivateRoute>
                    <MasterDashboard />
                  </ParadosEmployeePrivateRoute>
                }
              />
              <Route path='/signup' element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route path='/passwordReset' element={<PasswordReset />} />
              <Route
                path='/finish-sign-in'
                element={
                  <PrivateRoute>
                    <ProviderSignIn />
                  </PrivateRoute>
                }
              />
              <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
          </Suspense>
        </Container>
        <LoadingIndicator />
        <RouteChangeTracker />
      </CustomErrorBoundary>
    </>
  );
}

export default App;
