import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { EmailShareButton, FacebookMessengerShareButton, TwitterShareButton } from 'react-share';

import facebookImage from '../../assets/SocialsIcons/Facebook_Messanger_logo.png'; // Replace with the actual path
import twitterImage from '../../assets/SocialsIcons/Twitter-x-logo.webp'; // Replace with the actual path
import emailImage from '../../assets/SocialsIcons/Email_logo.png';

import styles from './ShareModal.module.css';
import { accountsTable } from '../../airtable-scripts';
import { useRef } from 'react';

export default function ShareModal({ isActive, videoName, currentResultEmail, onCancel }) {
  const [currentURL, setCurrentURL] = useState(window.location.href);
  const [linkedTrainer, setLinkedTrainer] = useState('');
  const [textCopied, setTextCopied] = useState(false);

  const urlInputRef = useRef();

  useEffect(() => {
    setCurrentURL(window.location.href);
  }, [isActive]);

  useEffect(() => {
    const fetchDataFromAirtable = async () => {
      try {
        const records = await accountsTable
          .select({
            filterByFormula: `{User} = "${currentResultEmail}"`,
          })
          .firstPage();

        if (records.length > 0) {
          const linkedTrainerEmail = records[0].get('Linked Trainer Email');
          setLinkedTrainer(linkedTrainerEmail[0]);
        }
      } catch (error) {
        console.error('Error fetching data from Airtable:', error);
      }
    };

    if (currentResultEmail) {
      fetchDataFromAirtable();
    } else {
      setLinkedTrainer('');
    }
  }, [currentResultEmail]);

  const handleCopyLink = () => {
    // urlInputRef.current.select();
    // document.execCommand('copy');

    try {
      navigator.clipboard.writeText(urlInputRef.current.value);
      setTextCopied(true);
      setTimeout(() => {
        setTextCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Unable to copy to clipboard.', err);
      alert('Copy to clipboard failed.');
    }
  };

  // if (!isActive) return null;

  return (
    <div className={`${styles.modal} ${isActive ? styles.active : ''}`}>
      <div onClick={onCancel} className={styles.overlay}></div>
      <div className={styles.block}>
        <FontAwesomeIcon onClick={onCancel} className={styles.cancelIcon} icon={faXmark} />
        <br />
        <div className={styles.container}>
          <h4>Sharing: {videoName}</h4>
          <p className={styles.labelText}>Account(s) with access to this video:</p>
          <p className={styles.accessList}>
            {currentResultEmail}
            <br />
            {linkedTrainer === currentResultEmail ? null : `${linkedTrainer}`}
          </p>
          <div className={styles.urlContainer}>
            <input ref={urlInputRef} type='text' value={currentURL} readOnly />
            <button onClick={handleCopyLink}>{textCopied ? 'Copied!' : 'Copy'}</button>
          </div>
          <div className={styles.shareIcons}>
            {/* <FacebookMessengerShareButton url={currentURL}>
              <img src={facebookImage} alt='Facebook Messenger Logo' />
            </FacebookMessengerShareButton> */}
            {/* <TwitterShareButton url={currentURL}>
              <img src={twitterImage} alt='Twitter X Logo' style={{ padding: '2px' }} />
            </TwitterShareButton> */}
            <EmailShareButton url={currentURL}>
              <img src={emailImage} alt='Email Logo' /> <p>Email</p>
            </EmailShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}
