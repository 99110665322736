import React, { forwardRef, useRef, useState } from 'react';
import styles from './VelocityGraph.module.css';

import { Line } from 'react-chartjs-2';

import GraphProgressLine from '../graphProgressLine/GraphProgressLine';
import GraphDeltaVelocity from '../graphDeltaVelocity/GraphDeltaVelocity';

const VelocityGraph = forwardRef(
  //forwardRef is for link scrolling
  (
    {
      linedata,
      dataPoint1Name,
      dataPoint2Name,
      dataPoint1Label,
      dataPoint2Label,
      videoRef,
      scrollToTime,
      jointDisplay,
      onLabelClick,
    },
    ref
  ) => {
    const jointName = dataPoint1Name.split('_').slice(1).join('_').toLowerCase();
    const graphRef = useRef(null);

    const [dataVisibility, setDataVisibility] = useState({
      [dataPoint1Label]: jointDisplay[dataPoint1Label.toLowerCase()],
      [dataPoint2Label]: jointDisplay[dataPoint2Label.toLowerCase()],
    });

    const options = {
      elements: {
        point: {
          radius: 0,
        },
      },
      normalized: false,
      animation: false,
      scales: {
        x: {
          display: false,
        },
        y: {
          min: undefined,
          max: undefined,
          ticks: {
            callback: function (value) {
              return value + 'm/s';
            },
          },
        },
      },
      legend: {
        display: false,
      },
      plugins: {
        legend: {
          onClick: function (e, legendItem, legend) {
            const side = (legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label).toLowerCase();
            onLabelClick('velocity', side);

            // Toggle visibility for the clicked dataset
            setDataVisibility((prevVisibility) => ({
              ...prevVisibility,
              [legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label]:
                !prevVisibility[legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label],
            }));
          },
        },
        tooltip: {
          callbacks: {
            title: function () {
              return '';
            },
            label: function (context) {
              const formattedValue = context.formattedValue;
              const roundedValue = Math.round(formattedValue * 100) / 100; // Round to 2 decimal places
              return `${context.dataset.label}: ${roundedValue}m/s`;
            },
          },
        },
      },
      maintainAspectRatio: false,
    };

    const lineChart = {
      labels: linedata.map((data, index) => index),
      datasets: [
        {
          label: `${dataPoint1Label}`,
          data: linedata.map((data) => Math.sqrt(data[`X:${dataPoint1Name}`] ** 2 + data[`Y:${dataPoint1Name}`] ** 2)),

          backgroundColor: ['#F5AB51'],
          borderColor: ['#F5AB51'],
          borderWidth: 3,
          hidden: !dataVisibility[dataPoint1Label],
        },
        {
          label: `${dataPoint2Label}`,
          data: linedata.map((data) => Math.sqrt(data[`X:${dataPoint2Name}`] ** 2 + data[`Y:${dataPoint2Name}`] ** 2)),
          backgroundColor: ['#0C89C3'],
          borderColor: ['#0C89C3'],
          borderWidth: 3,
          hidden: !dataVisibility[dataPoint2Label],
        },
      ],
    };

    const handleGraphClick = (event) => {
      const rect = graphRef.current.getBoundingClientRect();
      const x = event.clientX - rect.left - 38;
      const y = event.clientY - rect.top;
      const totalWidth = rect.width - 38;
      const totalHeight = rect.height - 6;

      if (x < 0 || x > totalWidth) {
        return;
      }

      if (y < 32 || y > totalHeight) {
        return;
      }

      const percentage = (x / totalWidth) * 100;
      scrollToTime(percentage);
    };

    const chartRef = useRef(null);

    return (
      <div
        ref={ref}
        className={styles.lineGraph}
        id={dataPoint1Label !== 'Shoulders' ? jointName + 'Graph' : 'shoulderHipGraph'}
        style={{ position: 'relative', scrollSnapPaddingTop: '100px' }}
        onClick={handleGraphClick}
      >
        <div ref={graphRef} className={styles.graphContainer}>
          <Line ref={chartRef} className={styles.line} data={lineChart} options={options} />
        </div>
        <GraphProgressLine videoRef={videoRef} graphRef={graphRef} />
        {dataVisibility[dataPoint1Label] || dataVisibility[dataPoint2Label] ? (
          <GraphDeltaVelocity
            videoRef={videoRef}
            linedata={linedata}
            dataPoint1Name={dataPoint1Name}
            dataPoint2Name={dataPoint2Name}
            dataPoint1Label={dataPoint1Label}
            dataPoint2Label={dataPoint2Label}
            dataVisibility={dataVisibility}
            label='m/s'
            graphRef={graphRef}
          />
        ) : null}
      </div>
    );
  }
);

export default React.memo(VelocityGraph);
