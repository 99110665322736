import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} location={location} params={params} navigate={navigate} />;
  }

  return ComponentWithRouterProp;
}

function useLocationEffect(callback) {
  const location = useLocation();

  useEffect(() => {
    callback(location);
  }, [location, callback]);
}

const RouteChangeTracker = ({ location }) => {
  useLocationEffect((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  //   history.listen((location, action) => {
  //     ReactGA.set({ page: location.pathname });
  //     ReactGA.pageview(location.pathname);
  //   });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);
