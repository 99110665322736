import { createSelector } from 'reselect';

// This selector gets the entire user state from the store.
const getUserState = (state) => state.user;

export const getUserData = createSelector(getUserState, (user) => user.userData || {});
export const getUserLoading = createSelector(getUserState, (user) => user.userLoading);
export const getUserError = createSelector(getUserState, (user) => user.userError);
export const getAthleteList = createSelector(getUserState, (user) => user.athleteList);
export const getCouponsData = createSelector(getUserState, (user) => user.coupons);

export const getAthletesLoading = createSelector(getUserState, (user) => user.athletesLoading);
export const getCouponsLoading = createSelector(getUserState, (user) => user.couponsLoading);

export const getAthletesError = createSelector(getUserState, (user) => user.athletesError);
export const getCouponsError = createSelector(getUserState, (user) => user.couponsError);
