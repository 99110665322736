import { accountsTable } from '../../airtable-scripts';

export const FETCH_ATHLETES_REQUEST = 'FETCH_ATHLETES_REQUEST';
export const FETCH_ATHLETES_SUCCESS = 'FETCH_ATHLETES_SUCCESS';
export const FETCH_ATHLETES_FAILURE = 'FETCH_ATHLETES_FAILURE';

export const fetchAthletesRequest = () => ({
  type: FETCH_ATHLETES_REQUEST,
});

export const fetchAthletesSuccess = (athletes) => ({
  type: FETCH_ATHLETES_SUCCESS,
  payload: athletes,
});

export const fetchAthletesFailure = (error) => ({
  type: FETCH_ATHLETES_FAILURE,
  payload: error,
});

export const fetchAthletesData = () => {
  return async (dispatch, getState) => {
    const currentUserEmail = localStorage.getItem('currentUserEmail');
    dispatch(fetchAthletesRequest());
    try {
      const response = await accountsTable
        .select({
          view: 'Grid view',
          filterByFormula: `AND({Linked Trainer} = '${currentUserEmail}', {User} != '${currentUserEmail}')`,
          sort: [{ field: 'Inactive', direction: 'asc' }],
        })
        .all();
      const athleteList = response.map((record) => record.fields);
      dispatch(fetchAthletesSuccess(athleteList));
    } catch (error) {
      dispatch(fetchAthletesFailure(error));
    }
  };
};
