import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import styles from './RangeSliderTitle.module.css';

export default function RangeSliderTitle({ title, minValue, maxValue, lineLimit, setLineLimit, isCompare = false }) {
  const [localLineLimit, setLocalLineLimit] = useState(lineLimit);

  const debouncedHandler = useCallback(
    debounce((value) => {
      setLineLimit(value);
    }, 500),
    [setLineLimit]
  );

  const handleSliderChange = (event) => {
    const value = event.target.value;
    debouncedHandler(value);

    setLocalLineLimit(value);
  };

  return (
    lineLimit !== null && (
      <div className={`${styles.nameAndRange} ${isCompare ? styles.compareOffset : null}`}>
        <p className={styles.label}>{title}</p>

        <div style={{ display: 'flex', visibility: 'hidden' }}>
          <p className={styles.sliderlabel}>limit:&nbsp;</p>
          <span className={styles.legend}>{minValue}&#176;</span>
          <div className={styles.sliderContainer}>
            <input
              type='range'
              defaultValue={localLineLimit}
              min={minValue}
              max={maxValue}
              className={styles.slider}
              onChange={handleSliderChange}
            />

            <span
              className={styles.sliderNumber}
              style={{ left: `calc(${(localLineLimit / maxValue) * 100}% - ${16 * (localLineLimit / maxValue)}px)` }}
            >
              {localLineLimit}&#176;
            </span>
          </div>
          <span className={styles.legend}>{maxValue}&#176;</span>
        </div>
      </div>
    )
  );
}
