import React from 'react';
import { Link } from 'react-router-dom';
import styles from './DashboardMobileOverlay.module.css';
import { useSelector } from 'react-redux';
import { getUserData } from '../../store/selectors/userSelectors';

function DashboardMobileOverlay({ setIsMobileOverlayDisabled }) {
  const userData = useSelector(getUserData);

  return (
    <div className={styles.dashboardMobileOverlay}>
      <div>
        <div className={styles.emojiiBlock}>
          <span>💻</span>
          {/* <span>
            <IconDeviceLaptop />
          </span> */}
          <h4> This page works best on large screens.</h4>
        </div>
        <div className={styles.emojiiBlock}>
          <span>📱</span>
          {/* <span>
            <IconDeviceMobile />
          </span> */}
          <h4> Mobile version coming soon.</h4>
        </div>
      </div>
      <br />
      {/* <h4>In the meantime you can:</h4> */}

      <Link to='/form'>Upload Video</Link>

      <Link to='/settings'>{userData.Role === 'Trainer' ? 'Manage Users' : 'Account Settings'}</Link>

      <p
        className={styles.hideMobileOverlay}
        onClick={() => {
          setIsMobileOverlayDisabled(true);
        }}
      >
        Proceed anyway
      </p>
    </div>
  );
}

export default React.memo(DashboardMobileOverlay);
