export function normalizeTiltAngles(angle) {
  // First, adjust the angle to a 0-360 range
  let normalized = (angle + 360) % 360;

  // Now map it to -180 to 180
  if (normalized > 180) {
    normalized -= 360;
  }

  // Finally, map anything over 90 to a value between -90 and 90
  if (normalized > 90) {
    normalized = -(180 - normalized);
  } else if (normalized < -90) {
    normalized = -(-180 - normalized);
  }

  return normalized;
}
