import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  IconArrowNarrowLeft,
  IconArrowNarrowRight,
  IconChevronLeft,
  IconChevronRight,
  IconNote,
} from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase';

import styles from './ComparisonResult.module.css';
// import AngleDisparityLine from '../angleDisparityLine/AngleDisparityLine';
import DatavizControl from '../datavizControl/DatavizControl';
// import ROM from '../ROM/ROM';
import RangeSliderTitle from '../rangeSliderTitle/RangeSliderTitle';
import CommentModal from '../modals/CommentModal';

import WithTooltip from '../HOCs/WithTooltip';
import tooltipData from '../../data/tooltipPropsData';
import LineGraphNew from '../lineGraph/LineGraphNew';
import GroundAngleGraphNew from '../groundAngleGraph/GroundAngleGraphNew';
import VelocityGraphNew from '../velocityGraph/VelocityGraphNew';
import TiltCircleNew from '../tiltCircle/TiltCircleNew';
import CenterOfBodyNew from '../centerOfBody/CenterOfBodyNew';

import jointsInitialDisplay from '../../data/jointsInitialDisplay';
import CustomVideoPlayer from '../customVideoPlayer/CustomVideoPlayer';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

function ComparisonResultNew({
  result,
  result2,
  currentPreset,
  currentPreset2,
  updateInfoJson,
  comment: initialComment,
  comment2: initialComment2,
  videoName,
  videoName2,
  fetchData,
}) {
  const [comment, setComment] = useState(initialComment);
  const [comment2, setComment2] = useState(initialComment2);

  const [resultFiles, setResultFiles] = useState({});
  const [resultFiles2, setResultFiles2] = useState({});

  const [linedata, setLinedata] = useState(null);
  const [linedata2, setLinedata2] = useState(null);

  const [jointsDisplay, setJointsDisplay] = useState(currentPreset || jointsInitialDisplay);
  const [jointsDisplay2, setJointsDisplay2] = useState(currentPreset2 || jointsInitialDisplay);

  const [tiltLimits, setTiltLimits] = useState({});
  const [isModalActive, setIsModalActive] = useState(false);

  const [showNavbar, setShowNavbar] = useState(true);
  const [bgColorClass, setBgColorClass] = useState(null);

  const videoRef = useRef();
  const videoRef2 = useRef();

  const videoListRef = ref(storage, `/${result?._location.path_}`);
  const videoListRef2 = ref(storage, `/${result2?._location.path_}`);

  const elementRefs = useRef([]);
  const titleRefs = useRef([]);

  const navigate = useNavigate();

  const toggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleDownload = () => {
    if (linedata) {
      const jsonBlob = new Blob([JSON.stringify(linedata)], { type: 'application/json' });
      saveAs(jsonBlob, videoName.split(' ').join('_') + '.json');
    }
  };

  const handleDownload2 = () => {
    if (linedata2) {
      const jsonBlob = new Blob([JSON.stringify(linedata2)], { type: 'application/json' });
      saveAs(jsonBlob, videoName2.split(' ').join('_') + '.json');
    }
  };

  const handleScrollLink = (e, id) => {
    const targetElement = elementRefs.current[id];

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  // Function to scroll the video to a particular time
  const scrollToTime = useCallback(
    (percentage) => {
      if (videoRef.current) {
        const video = videoRef.current;
        let time = percentage / 100;

        video.seekTo(time, 'fraction', false);
      }
    },
    [videoRef]
  );

  const scrollToTime2 = useCallback(
    (percentage) => {
      if (videoRef2.current) {
        const video = videoRef2.current;
        let time = percentage / 100;

        video.seekTo(time, 'fraction', false);
      }
    },
    [videoRef2]
  );

  // Rewritten for new AnglesNew.JSON
  function isEveryItemEmpty(data, propertyName) {
    const values = data[propertyName];
    if (values && Array.isArray(values)) {
      return values.every((item) => item === null || item === undefined);
    }
    return true; // Property doesn't exist or is not an array
  }

  const handleCheckboxClick = useCallback(
    (jointKey, keyToUpdate, side = null) => {
      setJointsDisplay((prevJointsDisplay) => {
        // Create a copy of the previous state
        const updatedJointsDisplay = { ...prevJointsDisplay };

        // Update the display value
        if (side) {
          updatedJointsDisplay[jointKey][keyToUpdate][side] = !updatedJointsDisplay[jointKey][keyToUpdate][side];
        } else {
          updatedJointsDisplay[jointKey][keyToUpdate].display = !updatedJointsDisplay[jointKey][keyToUpdate].display;
        }

        const newInfo = { preset: updatedJointsDisplay };
        const videoRefPath = result?._location.path_;

        // Update info.json in Firebase
        updateInfoJson(videoRefPath, newInfo);

        // Return the updated state
        return updatedJointsDisplay;
      });
    },
    [result, updateInfoJson]
  );

  const handleCheckboxClick2 = useCallback(
    (jointKey, keyToUpdate, side = null) => {
      setJointsDisplay2((prevJointsDisplay) => {
        // Create a copy of the previous state
        const updatedJointsDisplay = { ...prevJointsDisplay };

        // Update the display value
        if (side) {
          updatedJointsDisplay[jointKey][keyToUpdate][side] = !updatedJointsDisplay[jointKey][keyToUpdate][side];
        } else {
          updatedJointsDisplay[jointKey][keyToUpdate].display = !updatedJointsDisplay[jointKey][keyToUpdate].display;
        }

        const newInfo = { preset: updatedJointsDisplay };
        const videoRefPath = result2?._location.path_;

        // Update info.json in Firebase
        updateInfoJson(videoRefPath, newInfo);

        // Return the updated state
        return updatedJointsDisplay;
      });
    },
    [result2, updateInfoJson]
  );

  const openCommentModal = () => {
    setIsModalActive('comment1');
  };

  const openCommentModal2 = () => {
    setIsModalActive('comment2');
  };

  const closeCommentModal = () => {
    setIsModalActive(false);
  };

  function downloadVideo(videoUrl, isSecond = false) {
    if (!videoUrl) {
      console.error('No video URL available for download.');
      return;
    }

    // Fetch the video from the URL
    fetch(videoUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch the video: ${response.statusText}`);
        }
        return response.blob(); // Convert the response to a Blob if successful
      })
      .then((blob) => {
        // Create a URL for the Blob object
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element for the download
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = isSecond ? videoName2.replace(/\s+/g, '_') + '.mp4' : videoName.replace(/\s+/g, '_') + '.mp4'; // Specify filename for download

        // Append the link to the document body, click it to trigger download, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up by revoking the blob URL
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        console.error('Download failed:', error);
      });
  }

  const getFirebaseFiles = async (videoListRef, setResultFiles, setLineData) => {
    try {
      const response = await listAll(videoListRef);
      response.items.forEach(async (item) => {
        if (item._location.path.endsWith('/anglesNew.json')) {
          // Check if item ends with '/angles.json'
          getDownloadURL(item)
            .then((url) => fetch(url))
            .then((response) => response.json())
            .then((data) => {
              setLineData(data);
            })
            .catch((error) => console.error(error));
        } else if (item._location.path.endsWith('.mp4')) {
          getDownloadURL(item).then((url) => {
            setResultFiles((prev) => ({
              ...prev,
              Result: url,
            }));
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const checkDataAvailability = (dataType) => {
    if (!linedata || !linedata2) {
      return false;
    }

    // Assume jointsDisplay is an object with keys as jointNames
    for (const jointName in jointsDisplay) {
      switch (dataType) {
        case 'graph':
          if (jointsDisplay[jointName]?.graph?.display) {
            return true;
          }
          break;
        case 'velocity':
          if (jointsDisplay[jointName]?.velocity?.display) {
            return true;
          }
          break;
        case 'tilt':
          if (jointsDisplay[jointName]?.tilt?.display || jointsDisplay[jointName]?.vsHipsGraph?.display) {
            return true;
          }
          break;
        case 'cob':
          if (jointsDisplay[jointName]?.centerOfBody?.display) {
            return true;
          }
          break;

        // Add other data types as needed
        default:
          console.warn(`Unknown data type: ${dataType}`);
          return false;
      }
    }

    for (const jointName in jointsDisplay2) {
      switch (dataType) {
        case 'graph':
          if (jointsDisplay2[jointName]?.graph?.display) {
            return true;
          }
          break;
        case 'velocity':
          if (jointsDisplay2[jointName]?.velocity?.display) {
            return true;
          }
          break;
        case 'tilt':
          if (jointsDisplay2[jointName]?.tilt?.display || jointsDisplay2[jointName]?.vsHipsGraph?.display) {
            return true;
          }
          break;
        case 'cob':
          if (jointsDisplay2[jointName]?.centerOfBody?.display) {
            return true;
          }
          break;

        // Add other data types as needed
        default:
          console.warn(`Unknown data type: ${dataType}`);
          return false;
      }
    }

    return false;
  };

  useEffect(() => {
    setComment(initialComment);
    setLinedata(null);
    getFirebaseFiles(videoListRef, setResultFiles, setLinedata);
  }, [result]);

  useEffect(() => {
    setComment2(initialComment2);
    setLinedata2(null);
    getFirebaseFiles(videoListRef2, setResultFiles2, setLinedata2);
  }, [result2]);

  useEffect(() => {
    setJointsDisplay(currentPreset || jointsInitialDisplay);
  }, [currentPreset]);

  useEffect(() => {
    setJointsDisplay2(currentPreset2 || jointsInitialDisplay);
  }, [currentPreset2]);

  useEffect(() => {
    if (showNavbar) {
      setBgColorClass('left');
    } else {
      setBgColorClass('right');
    }

    // Reset the background color to gray after 1.5 seconds
    const timer = setTimeout(() => {
      setBgColorClass(null);
    }, 1500);

    return () => clearTimeout(timer);
  }, [showNavbar]);

  return (
    <>
      {/* <div className={styles.footerAdjuster}></div> */}

      <div className={styles.result}>
        {resultFiles.Result ? (
          <div className={styles.left}>
            <>
              <div className={styles.video}>
                <CustomVideoPlayer src={resultFiles.Result} ref={videoRef} downloadVideo={downloadVideo} />
              </div>
              <div className={styles.title}>
                <h3>{videoName}</h3>
              </div>
              <div onClick={openCommentModal} className={styles.notesBtn}>
                Notes <IconNote color='#272727' />
              </div>
            </>
          </div>
        ) : null}

        <div className={styles.center}>
          {linedata && linedata2 ? (
            <>
              <div className={styles.navbarControl}>
                <div className={`${styles.navbarIcon} ${showNavbar ? styles.selected : null}`} onClick={toggleNavbar}>
                  <IconChevronLeft />
                </div>
                <div className={styles.twoNavbars}>
                  <div className={`${styles.insideNavbar} ${showNavbar ? styles.visible : styles.hiddenLeft}`}>
                    <DatavizControl
                      handleScrollLink={handleScrollLink}
                      jointsDisplay={jointsDisplay}
                      handleCheckboxClick={handleCheckboxClick}
                      handleDownload={handleDownload}
                      isCompare={true}
                    />
                  </div>
                  <div className={`${styles.insideNavbar} ${showNavbar ? styles.hiddenRight : styles.visible}`}>
                    <DatavizControl
                      handleScrollLink={handleScrollLink}
                      jointsDisplay={jointsDisplay2}
                      handleCheckboxClick={handleCheckboxClick2}
                      handleDownload={handleDownload2}
                      isCompare={true}
                    />
                  </div>
                </div>

                <div className={`${styles.navbarIcon} ${!showNavbar ? styles.selected : null}`} onClick={toggleNavbar}>
                  <IconChevronRight />
                </div>
              </div>

              {/* <div id={styles.leftGreyBlock} className={bgColorClass === 'left' ? styles.blueBG : null} />
              <div id={styles.rightGreyBlock} className={bgColorClass === 'right' ? styles.blueBG : null} /> */}

              <div id={styles.leftOutline} />
              <div id={styles.rightOutline} />
              <button
                className={styles.backToSingleBtn}
                onClick={() => {
                  navigate(-1); // Navigate back to the previous page
                }}
              >
                <IconArrowNarrowLeft /> Back to Single
              </button>

              <div className={styles.dataviz}>
                <div className={styles.graphsSection}>
                  {checkDataAvailability('graph') &&
                    ['Shoulders', 'Hips', 'Knees', 'Ankles', 'Elbows', 'Wrists'].reduce((acc, jointName) => {
                      const displayValue =
                        jointsDisplay[jointName]?.graph?.display || jointsDisplay2[jointName]?.graph?.display;
                      if (displayValue) {
                        return acc + displayValue;
                      }
                      return acc;
                    }, '') && (
                      <h3 ref={(el) => (titleRefs.current['Joint Angles'] = el)}>
                        <WithTooltip {...tooltipData.jointAngles}>Joint Angles</WithTooltip>
                      </h3>
                    )}

                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (['Upper_Leg', 'Torso', 'Lower_Leg'].includes(jointName)) {
                      return null;
                    }
                    // Check if linedata.tilt is false and return null
                    if (!jointsDisplay[jointName]?.graph?.display && !jointsDisplay2[jointName]?.graph?.display) {
                      return null;
                    }

                    if (
                      !isEveryItemEmpty(linedata, 'LEFT_' + jointName.slice(0, -1).toUpperCase()) &&
                      !isEveryItemEmpty(linedata, 'RIGHT_' + jointName.slice(0, -1).toUpperCase()) &&
                      !isEveryItemEmpty(linedata2, 'LEFT_' + jointName.slice(0, -1).toUpperCase()) &&
                      !isEveryItemEmpty(linedata2, 'RIGHT_' + jointName.slice(0, -1).toUpperCase())
                    ) {
                      let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase();
                      let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase();

                      const dataPoint1Label = 'Left';
                      const dataPoint2Label = 'Right';
                      return (
                        <React.Fragment key={`${jointName}Graph`}>
                          <p ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)} className={styles.label}>
                            {jointName.split('_').join(' ')}:
                          </p>
                          {jointsDisplay[jointName]?.graph?.display ? (
                            <div className={styles.leftGraph}>
                              <LineGraphNew
                                videoRef={videoRef}
                                dataPoint1Data={linedata[dataPoint1Name]}
                                dataPoint2Data={linedata[dataPoint2Name]}
                                dataPoint1Name={dataPoint1Name}
                                dataPoint2Name={dataPoint2Name}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime}
                                jointDisplay={jointsDisplay[jointName].graph}
                                jointName={jointName}
                                handleCheckboxClick={handleCheckboxClick}
                              />
                            </div>
                          ) : null}
                          {jointsDisplay2[jointName]?.graph?.display ? (
                            <div style={{ backgroundColor: 'ff0000' }} className={styles.rightGraph}>
                              <LineGraphNew
                                videoRef={videoRef2}
                                dataPoint1Data={linedata2[dataPoint1Name]}
                                dataPoint2Data={linedata2[dataPoint2Name]}
                                dataPoint1Name={dataPoint1Name}
                                dataPoint2Name={dataPoint2Name}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime2}
                                jointDisplay={jointsDisplay2[jointName].graph}
                                jointName={jointName}
                                handleCheckboxClick={handleCheckboxClick2}
                              />
                            </div>
                          ) : null}
                          <br />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data is not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}
                  {checkDataAvailability('graph') &&
                    ['Upper_Leg', 'Torso', 'Lower_Leg'].reduce((acc, jointName) => {
                      const displayValue =
                        jointsDisplay[jointName]?.graph?.display || jointsDisplay2[jointName]?.graph?.display;

                      if (displayValue) {
                        return acc + displayValue;
                      }
                      return acc;
                    }, '') && (
                      <>
                        {checkDataAvailability('graph') && <hr />}
                        <h3 ref={(el) => (titleRefs.current['Segment Angles'] = el)}>
                          <WithTooltip {...tooltipData.segmentAngles}>Segment Angles</WithTooltip>
                        </h3>
                      </>
                    )}
                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (['Shoulders', 'Hips', 'Knees', 'Ankles', 'Elbows', 'Wrists'].includes(jointName)) {
                      return null;
                    }

                    if (!jointsDisplay[jointName]?.graph?.display && !jointsDisplay2[jointName]?.graph?.display) {
                      return null;
                    }

                    if (
                      !isEveryItemEmpty(linedata, jointName.toUpperCase() + '_ANGLE') &&
                      !isEveryItemEmpty(linedata2, jointName.toUpperCase() + '_ANGLE')
                    ) {
                      let dataPointName = jointName.toUpperCase() + '_ANGLE';

                      const dataPointLabel = jointName.split('_').join(' ');
                      return (
                        <React.Fragment key={`${jointName}Graph`}>
                          <p ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)} className={styles.label}>
                            {jointName.split('_').join(' ')}:
                          </p>
                          {jointsDisplay[jointName]?.graph?.display ? (
                            <div className={styles.leftGraph}>
                              <GroundAngleGraphNew
                                dataPointData={linedata[dataPointName]}
                                videoRef={videoRef}
                                dataPointName={dataPointName}
                                dataPointLabel={dataPointLabel}
                                scrollToTime={scrollToTime}
                                jointDisplay={jointsDisplay[jointName].graph}
                                jointName={jointName}
                                handleCheckboxClick={handleCheckboxClick}
                              />
                            </div>
                          ) : null}
                          {jointsDisplay2[jointName]?.graph?.display ? (
                            <div className={styles.rightGraph}>
                              <GroundAngleGraphNew
                                dataPointData={linedata2[dataPointName]}
                                videoRef={videoRef2}
                                dataPointName={dataPointName}
                                dataPointLabel={dataPointLabel}
                                scrollToTime={scrollToTime2}
                                jointDisplay={jointsDisplay2[jointName].graph}
                                jointName={jointName}
                                handleCheckboxClick={handleCheckboxClick2}
                              />
                            </div>
                          ) : null}
                          <br />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.split('_').join(' ')} data is not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}
                  {checkDataAvailability('velocity') && (
                    <>
                      {checkDataAvailability('graph') && <hr />}
                      <h3 ref={(el) => (titleRefs.current['Velocity'] = el)}>
                        <WithTooltip {...tooltipData.velocities}>Velocity</WithTooltip>
                      </h3>
                    </>
                  )}
                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (!jointsDisplay[jointName]?.velocity?.display && !jointsDisplay2[jointName]?.velocity?.display) {
                      return null;
                    }

                    if (
                      !isEveryItemEmpty(linedata, 'X:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'Y:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'X:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'Y:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'X:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'Y:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'X:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata2, 'Y:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY')
                    ) {
                      let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY';
                      let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY';

                      const dataPoint1Label = 'Left';
                      const dataPoint2Label = 'Right';

                      const dataPoint1Data = linedata['X:' + dataPoint1Name].map((xValue, index) =>
                        Math.sqrt(xValue ** 2 + linedata['Y:' + dataPoint1Name][index] ** 2)
                      );
                      const dataPoint2Data = linedata['X:' + dataPoint2Name].map((xValue, index) =>
                        Math.sqrt(xValue ** 2 + linedata['Y:' + dataPoint2Name][index] ** 2)
                      );

                      const dataPoint1Data2 = linedata2['X:' + dataPoint1Name].map((xValue, index) =>
                        Math.sqrt(xValue ** 2 + linedata2['Y:' + dataPoint1Name][index] ** 2)
                      );
                      const dataPoint2Data2 = linedata2['X:' + dataPoint2Name].map((xValue, index) =>
                        Math.sqrt(xValue ** 2 + linedata2['Y:' + dataPoint2Name][index] ** 2)
                      );
                      return (
                        <React.Fragment key={`${jointName}Velocity`}>
                          <p ref={(el) => (elementRefs.current[`${jointName}Velocity`] = el)} className={styles.label}>
                            {jointName.slice(0, -1)} Velocity:
                          </p>
                          {jointsDisplay[jointName]?.velocity?.display ? (
                            <div className={styles.leftGraph}>
                              <VelocityGraphNew
                                videoRef={videoRef}
                                dataPoint1Data={dataPoint1Data}
                                dataPoint2Data={dataPoint2Data}
                                dataPoint1Name={dataPoint1Name}
                                dataPoint2Name={dataPoint2Name}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime}
                                jointDisplay={jointsDisplay[jointName].velocity}
                                jointName={jointName}
                                handleCheckboxClick={handleCheckboxClick}
                              />
                            </div>
                          ) : null}

                          {jointsDisplay2[jointName]?.velocity?.display ? (
                            <div className={styles.rightGraph}>
                              <VelocityGraphNew
                                videoRef={videoRef2}
                                dataPoint1Data={dataPoint1Data2}
                                dataPoint2Data={dataPoint2Data2}
                                dataPoint1Name={dataPoint1Name}
                                dataPoint2Name={dataPoint2Name}
                                dataPoint1Label={dataPoint1Label}
                                dataPoint2Label={dataPoint2Label}
                                scrollToTime={scrollToTime2}
                                jointDisplay={jointsDisplay2[jointName].velocity}
                                jointName={jointName}
                                handleCheckboxClick={handleCheckboxClick2}
                              />
                            </div>
                          ) : null}
                          <br />
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} velocity data is not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}
                  {checkDataAvailability('tilt') && (
                    <>
                      {(checkDataAvailability('velocity') || checkDataAvailability('graph')) && <hr />}
                      <h3 ref={(el) => (titleRefs.current['Tilt'] = el)}>
                        <WithTooltip {...tooltipData.tilts}>Tilt</WithTooltip>
                      </h3>
                    </>
                  )}
                  <div className={styles.tiltSection}>
                    <div className={styles.divider} />

                    {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                      if (!jointsDisplay[jointName]?.tilt?.display && !jointsDisplay2[jointName]?.tilt?.display) {
                        return null;
                      }

                      if (!isEveryItemEmpty(linedata, 'TILT_SHOULDER')) {
                        let dataPointName = 'TILT_' + jointName.toUpperCase().slice(0, -1);

                        return (
                          <div key={`${jointName}Tilt`}>
                            <RangeSliderTitle
                              title={`${jointName}`}
                              minValue='0'
                              maxValue='45'
                              lineLimit={tiltLimits[jointName] || 5}
                              setLineLimit={(value) => {
                                setTiltLimits((prevLimits) => ({ ...prevLimits, [jointName]: value }));
                              }}
                              isCompare={true}
                            />
                            <div
                              className={styles.dividedDataSection}
                              ref={(el) => (elementRefs.current[`${jointName}Tilt`] = el)}
                            >
                              {jointsDisplay[jointName]?.tilt?.display ? (
                                <TiltCircleNew
                                  dataPointData={linedata[dataPointName]}
                                  videoRef={videoRef}
                                  jointName={jointName}
                                  lineLimit={tiltLimits[jointName] || 5}
                                  isCompare={true}
                                />
                              ) : (
                                <div></div>
                              )}
                              {jointsDisplay2[jointName]?.tilt?.display ? (
                                <TiltCircleNew
                                  dataPointData={linedata2[dataPointName]}
                                  videoRef={videoRef2}
                                  jointName={jointName}
                                  lineLimit={tiltLimits[jointName] || 5}
                                  isCompare={true}
                                />
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={`${jointName}Unavailable`}>
                            <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data is not available</h4>
                            <br />
                          </div>
                        );
                      }
                    })}
                  </div>

                  {!jointsDisplay.Shoulders?.vsHipsGraph?.display &&
                  !jointsDisplay2.Shoulders?.vsHipsGraph?.display ? null : !(
                      isEveryItemEmpty(linedata, 'TILT_SHOULDER') || isEveryItemEmpty(linedata, 'TILT_HIP')
                    ) ? (
                    <>
                      <p ref={(el) => (elementRefs.current['shoulderHipGraph'] = el)} className={styles.label}>
                        Shoulder vs Hip Tilt:
                      </p>

                      {jointsDisplay.Shoulders?.vsHipsGraph?.display ? (
                        <div className={styles.leftGraph}>
                          <LineGraphNew
                            videoRef={videoRef}
                            dataPoint1Data={linedata['TILT_SHOULDER']}
                            dataPoint2Data={linedata['TILT_HIP']}
                            dataPoint1Name={'TILT_SHOULDER'}
                            dataPoint2Name={'TILT_HIP'}
                            dataPoint1Label={'Shoulders'}
                            dataPoint2Label={'Hips'}
                            scrollToTime={scrollToTime}
                            jointDisplay={jointsDisplay.Shoulders.vsHipsGraph}
                            jointName={'Shoulders'}
                            handleCheckboxClick={handleCheckboxClick}
                          />
                        </div>
                      ) : null}

                      {jointsDisplay2.Shoulders?.vsHipsGraph?.display ? (
                        <div className={styles.rightGraph}>
                          <LineGraphNew
                            videoRef={videoRef2}
                            dataPoint1Data={linedata2['TILT_SHOULDER']}
                            dataPoint2Data={linedata2['TILT_HIP']}
                            dataPoint1Name={'TILT_SHOULDER'}
                            dataPoint2Name={'TILT_HIP'}
                            dataPoint1Label={'Shoulders'}
                            dataPoint2Label={'Hips'}
                            scrollToTime={scrollToTime2}
                            jointDisplay={jointsDisplay2.Shoulders.vsHipsGraph}
                            jointName={'Shoulders'}
                            handleCheckboxClick={handleCheckboxClick2}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div key={`vsHipsGraphUnavailable`}>
                      <h4 style={{ color: '#727272' }}>Shoulders vs Hips data is not available</h4>
                      <br />
                    </div>
                  )}
                  <br />

                  {checkDataAvailability('cob') && (
                    <>
                      {(checkDataAvailability('tilt') ||
                        checkDataAvailability('velocity') ||
                        checkDataAvailability('graph')) && <hr />}
                      <h3 ref={(el) => (titleRefs.current['CenterOfBody'] = el)}>
                        Center of Body
                        {/* <WithTooltip {...tooltipData.tilts}>Center of Body</WithTooltip> */}
                      </h3>
                    </>
                  )}
                  <div className={styles.cobSection}>
                    <div className={styles.divider} />

                    {!jointsDisplay.Shoulders?.centerOfBody?.display &&
                    !jointsDisplay2.Shoulders?.centerOfBody?.display ? null : (
                      <div
                        ref={(el) => (elementRefs.current[`centerOfBody`] = el)}
                        className={styles.dividedDataSection}
                      >
                        {!(isEveryItemEmpty(linedata, 'COB_X') || isEveryItemEmpty(linedata, 'COB_Y')) ? (
                          jointsDisplay.Shoulders?.centerOfBody?.display ? (
                            <CenterOfBodyNew
                              dataPoint1Data={linedata['COB_X']}
                              dataPoint2Data={linedata['COB_Y']}
                              videoRef={videoRef}
                              isCompare={true}
                            />
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div key={`centerOfBodyUnavailable`}>
                            <h4 style={{ color: '#727272' }}>Center of Body data is not available</h4>
                            <br />
                          </div>
                        )}
                        {!(isEveryItemEmpty(linedata2, 'COB_X') || isEveryItemEmpty(linedata2, 'COB_Y')) ? (
                          jointsDisplay2.Shoulders?.centerOfBody?.display ? (
                            <CenterOfBodyNew
                              dataPoint1Data={linedata2['COB_X']}
                              dataPoint2Data={linedata2['COB_Y']}
                              videoRef={videoRef2}
                              isCompare={true}
                            />
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div key={`centerOfBodyUnavailable`}>
                            <h4 style={{ color: '#727272' }}>Center of Body data is not available</h4>
                            <br />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : linedata && !linedata2 ? (
            <div className={clsx(styles.dropdownPrompt, styles.leftPrompt)}>
              <h4 style={{ textAlign: 'right' }}>
                Select second video <IconArrowNarrowRight />
              </h4>
            </div>
          ) : !linedata && linedata2 ? (
            <div className={clsx(styles.dropdownPrompt, styles.rightPrompt)}>
              <h4>
                <IconArrowNarrowLeft /> Select second video
              </h4>
            </div>
          ) : null}
        </div>

        {resultFiles2.Result ? (
          <div className={styles.right}>
            <>
              <div className={styles.video}>
                <CustomVideoPlayer
                  src={resultFiles2.Result}
                  ref={videoRef2}
                  downloadVideo={downloadVideo}
                  isSecond={true}
                />
              </div>
              <div className={styles.title}>
                <h3>{videoName2}</h3>
              </div>
              <div onClick={openCommentModal2} className={styles.notesBtn}>
                <IconNote color='#272727' /> Notes
              </div>
            </>
          </div>
        ) : null}
      </div>

      <CommentModal
        isActive={isModalActive === 'comment2' || isModalActive === 'comment1'} // Only open the modal if isModalActive is 'comment2'
        setIsModalActive={closeCommentModal}
        videoRef={isModalActive === 'comment1' ? result : result2}
        comment={isModalActive === 'comment1' ? comment : comment2}
        setComment={isModalActive === 'comment1' ? setComment : setComment2}
        videoName={isModalActive === 'comment1' ? videoName : videoName2}
        fetchData={fetchData}
      />
    </>
  );

  // <>
  //   {!linedata && !linedata2 ? (
  //     <img
  //       className={styles.loadingGif}
  //       src='https://vote.elections.virginia.gov/Content/images/loading_animated.gif'
  //       alt=''
  //       width='100px'
  //       height='100px'
  //     />
  //   ) : null}

  //   {linedata && !linedata2 ? (
  //     <div style={{ maxWidth: '70%' }}>
  //       <h3>Select a second video from the right dropdown...</h3>
  //     </div>
  //   ) : null}

  //   {!linedata && linedata2 ? (
  //     <div style={{ maxWidth: '70%' }}>
  //       <h3>Select a second video from the left dropdown...</h3>
  //     </div>
  //   ) : null}
  // </>
}

export default React.memo(ComparisonResultNew);
