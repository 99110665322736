import React, { useRef, useEffect } from 'react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

function GraphProgressLine({ videoRef, graphRef, leftOffset = 38 }) {
  const canvasRef = useRef(null);
  const oversamplingFactor = window.screen.height / window.innerHeight; // Oversampling factor based on screen height

  const isVisible = useIntersectionObserver(graphRef, {
    threshold: 0,
    rootMargin: '240px 240px 240px 240px',
  });

  // Handle canvas resize
  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      const parentDiv = graphRef?.current; // Use graphRef to reference the parent div

      if (canvas && parentDiv) {
        // Set the canvas width to a fraction of the parent div's width
        canvas.width = 6 * oversamplingFactor;
        canvas.height = parentDiv.offsetHeight * oversamplingFactor;
      }
    };

    handleResize(); // Initial setup

    window.addEventListener('resize', handleResize);

    // Use ResizeObserver to monitor changes in the parent div's size
    const resizeObserver = new ResizeObserver(handleResize);

    const parentDiv = graphRef?.current;

    if (parentDiv) {
      resizeObserver.observe(parentDiv);
    }

    return () => {
      window.removeEventListener('resize', handleResize);

      // Clean up the ResizeObserver
      if (parentDiv) {
        resizeObserver.unobserve(parentDiv);
      }
    };
  }, [graphRef, oversamplingFactor]); // Include graphRef and oversamplingFactor in the dependency array

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Set canvas size with oversampling
    canvas.width = 60 * oversamplingFactor;
    canvas.height = canvas.offsetHeight * oversamplingFactor;

    let lastRenderTime = 0;

    // Start animation loop
    let requestId;
    const animate = (timestamp) => {
      if (!isVisible) {
        cancelAnimationFrame(requestId);
        return; // Stop animation when not visible
      }

      if (timestamp - lastRenderTime < 16) {
        requestId = requestAnimationFrame(animate);
        return;
      }

      // Calculate video progress
      const videoDuration = videoRef.current?.getDuration();
      const videoCurrentTime = videoRef.current.getCurrentTime() % videoDuration;
      const newProgress = videoCurrentTime / videoDuration;

      // Update line position
      const newLineX = newProgress * canvasRef.current.parentNode.offsetWidth * oversamplingFactor;

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.strokeStyle = 'grey';

      // Draw line
      ctx.beginPath();
      ctx.moveTo(1, 0);
      ctx.lineTo(1, canvas.height);
      ctx.lineWidth = (1 * oversamplingFactor + 0.5).toFixed(0);
      ctx.stroke();

      canvas.style.transform = `translateX(${newLineX / oversamplingFactor}px)`;

      lastRenderTime = timestamp;
      // Request next frame
      requestId = requestAnimationFrame(animate);
    };

    // Start animation loop
    requestId = requestAnimationFrame(animate);

    // Cleanup
    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [videoRef, oversamplingFactor, isVisible]);

  return (
    <div
      style={{
        position: 'absolute',
        top: '32px',
        left: `${leftOffset}px`,
        width: `calc(100% - ${leftOffset}px)`,
        height: 'calc(100% - 58px)',
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          pointerEvents: 'none',
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
}

export default React.memo(GraphProgressLine);
