import React, { useRef, useState } from 'react';
import styles from './LineGraph.module.css';
import { Line } from 'react-chartjs-2';
import GraphProgressLine from '../graphProgressLine/GraphProgressLine';
import GraphDeltaValueNew from '../graphDeltaValue/GraphDeltaValueNew';

const LineGraphNew = ({
  dataPoint1Data,
  dataPoint2Data,
  dataPoint1Name,
  dataPoint2Name,
  dataPoint1Label,
  dataPoint2Label,
  videoRef,
  scrollToTime,
  jointDisplay,
  jointName,
  handleCheckboxClick,
}) => {
  const graphRef = useRef(null);
  const [dataVisibility, setDataVisibility] = useState({
    [dataPoint1Label]: jointDisplay[dataPoint1Label.toLowerCase()],
    [dataPoint2Label]: jointDisplay[dataPoint2Label.toLowerCase()],
  });

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    normalized: false,
    animation: false,
    scales: {
      x: {
        display: false,
        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },
        },
      },
      y: {
        min: (() => {
          const minY = Math.min(...dataPoint1Data, ...dataPoint2Data);
          return minY < 0
            ? ((minY - 10) / 10).toFixed(0) * 10
            : dataPoint1Label !== 'Shoulders'
            ? 0
            : ((minY - 10) / 10).toFixed(0) * 10;
        })(),
        max: (() => {
          const maxY = Math.max(...dataPoint1Data, ...dataPoint2Data);
          return maxY > 180
            ? ((maxY + 10) / 10).toFixed(0) * 10
            : dataPoint1Label !== 'Shoulders'
            ? 180
            : ((maxY + 10) / 10).toFixed(0) * 10;
        })(),

        ticks: {
          font: {
            family: 'Montserrat, Arial, sans-serif',
            size: 12,
          },

          callback: function (value) {
            return value + '°';
          },
        },
        grid:
          dataPoint1Label !== 'Shoulders'
            ? undefined
            : {
                drawBorder: false,
                color: (context) => (context.tick.value === 0 ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'),
                lineWidth: (context) => (context.tick.value === 0 ? 2 : 1),
              },
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        onClick: function (e, legendItem, legend) {
          e.native.preventDefault();

          const side = (legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label).toLowerCase();
          handleCheckboxClick(jointName, dataPoint1Label === 'Left' ? 'graph' : 'vsHipsGraph', side);

          setDataVisibility((prevVisibility) => ({
            ...prevVisibility,
            [legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label]:
              !prevVisibility[legendItem.datasetIndex === 0 ? dataPoint1Label : dataPoint2Label],
          }));
        },
      },
      tooltip: {
        callbacks: {
          title: function () {
            return '';
          },
          label: function (context) {
            const formattedValue = context.formattedValue;
            const roundedValue = Math.round(formattedValue * 100) / 100;
            return `${context.dataset.label}: ${roundedValue}°`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const lineChart = {
    labels: Array.from({ length: Math.max(dataPoint1Data.length, dataPoint2Data.length) }, (_, index) => index),
    datasets: [
      {
        label: dataPoint1Label,
        data: dataPoint1Data,
        backgroundColor: dataPoint1Label !== 'Shoulders' ? ['#F5AB51'] : ['#659157'],
        borderColor: dataPoint1Label !== 'Shoulders' ? ['#F5AB51'] : ['#659157'],
        borderWidth: 3,
        hidden: !dataVisibility[dataPoint1Label],
      },
      {
        label: dataPoint2Label,
        data: dataPoint2Data,
        backgroundColor: dataPoint1Label !== 'Shoulders' ? ['#0C89C3'] : ['#935FA7'],
        borderColor: dataPoint1Label !== 'Shoulders' ? ['#0C89C3'] : ['#935FA7'],
        borderWidth: 3,
        hidden: !dataVisibility[dataPoint2Label],
      },
    ],
  };

  const handleGraphClick = (event) => {
    const rect = graphRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left - 38;
    const y = event.clientY - rect.top;
    const totalWidth = rect.width - 38;
    const totalHeight = rect.height - 6;

    if (x < 0 || x > totalWidth) {
      return;
    }

    if (y < 32 || y > totalHeight) {
      return;
    }

    const percentage = (x / totalWidth) * 100;
    scrollToTime(percentage);
  };

  const chartRef = useRef(null);

  return (
    <div
      className={styles.lineGraph}
      id={dataPoint1Label !== 'Shoulders' ? `${dataPoint1Label.toLowerCase()}Graph` : 'shoulderHipGraph'}
      style={{ position: 'relative', scrollSnapPaddingTop: '100px' }}
      onClick={handleGraphClick}
    >
      <div ref={graphRef} className={styles.graphContainer}>
        <Line ref={chartRef} className={styles.line} data={lineChart} options={options} />
      </div>
      <GraphProgressLine videoRef={videoRef} graphRef={graphRef} />
      {dataVisibility[dataPoint1Label] || dataVisibility[dataPoint2Label] ? (
        <GraphDeltaValueNew
          videoRef={videoRef}
          dataPoint1Data={dataPoint1Data}
          dataPoint2Data={dataPoint2Data}
          dataPoint1Name={dataPoint1Name}
          dataPoint2Name={dataPoint2Name}
          dataPoint1Label={dataPoint1Label}
          dataPoint2Label={dataPoint2Label}
          dataVisibility={dataVisibility}
          graphRef={graphRef}
        />
      ) : null}
    </div>
  );
};
export default React.memo(LineGraphNew);
