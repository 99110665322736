import React from 'react';
import styles from './Loader.module.css';

const Loader = ({ isLoading }) => {
  return (
    <div className={isLoading ? `${styles.overlay} ${styles.show}` : styles.overlay}>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <img
            src='https://vote.elections.virginia.gov/Content/images/loading_animated.gif'
            alt='loader gif'
            width='100px'
            height='100px'
            className={styles.loader}
          />
        </div>
      )}
    </div>
  );
};

export default Loader;
