import React from 'react';
import styles from './NoAccessMessage.module.css'; // Import the CSS module
import { IconArrowLeft } from '@tabler/icons-react';

const NoAccessMessage = ({ selectedAthleteEmail }) => {
  return (
    <div className={styles.noAccessContainer}>
      <h2>Oops, this Video is Unavailable.</h2>
      <p className={styles.messageText}>Possible reasons:</p>
      <ol>
        <li>Incorrect Video Link</li>
        <li>
          No Access to Videos Associated with <b>{selectedAthleteEmail}</b>
        </li>
      </ol>

      <div className={styles.pointingToList}>
        <IconArrowLeft height={32} width={32} />
        <h4>Please select another video from the list.</h4>
      </div>
    </div>
  );
};

export default NoAccessMessage;
