import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap'; // Assuming you are using react-bootstrap for Form components

import styles from './ReportModal.module.css';

function ReportModal({ isActive, jointsDisplay, notes, exportPDF, onCancel, videoName }) {
  // Update checkboxState based on jointsDisplay whenever it changes

  const areNotesDisabled = notes.length === 0;
  const areGraphsDisabled = !Object.values(jointsDisplay).some((joint) => joint.graph?.display);
  const areVelocitiesDisabled = !Object.values(jointsDisplay).some((joint) => joint.velocity?.display);
  const areShoulderHipTiltsDisabled = !(jointsDisplay.Shoulders.tilt?.display && jointsDisplay.Hips.tilt?.display);
  const isShoulderVsHipsGraphDisabled = !jointsDisplay.Shoulders.vsHipsGraph?.display;
  const isCoBDisabled = !jointsDisplay.Shoulders.centerOfBody?.display;

  const [checkboxState, setCheckboxState] = useState({
    stillFrame: true,
    clientsData: false,
    includeNotes: !areNotesDisabled,
    angleGraphs: !areGraphsDisabled,
    velocities: !areVelocitiesDisabled,
    tilts: !areShoulderHipTiltsDisabled,
    vsHipsGraph: !isShoulderVsHipsGraphDisabled,
    centerOfBody: !isCoBDisabled,
  });

  useEffect(() => {
    setCheckboxState({
      stillFrame: checkboxState.stillFrame,
      clientsData: checkboxState.clientsData,
      includeNotes: checkboxState.includeNotes,
      angleGraphs: !areGraphsDisabled,
      velocities: !areVelocitiesDisabled,
      tilts: !areShoulderHipTiltsDisabled,
      vsHipsGraph: !isShoulderVsHipsGraphDisabled,
      centerOfBody: !isCoBDisabled,
    });
  }, [jointsDisplay]);

  if (!isActive) return null;

  const updateCheckboxState = (checkboxName, value) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [checkboxName]: value,
    }));
  };

  return (
    <div className={styles.modal}>
      <div onClick={onCancel} className={styles.overlay}></div>
      <div className={styles.card}>
        <FontAwesomeIcon onClick={onCancel} className={styles.cancelIcon} icon={faXmark} />
        <h4>Report for {videoName}</h4> {/* Title based on videoName prop */}
        <div className={styles.container}>
          <p>Include:</p>
          <Form className={styles.notifications}>
            <div className={styles.switchAndText}>
              <Form.Check
                custom='true'
                type='switch'
                id='stillFrame'
                className={styles.notificationCheckbox}
                checked={checkboxState.stillFrame}
                onChange={(e) => updateCheckboxState('stillFrame', e.target.checked)}
              />
              <p className={styles.checkboxLabel}>Video Screenshot</p>
            </div>

            <div className={styles.switchAndText}>
              <Form.Check
                custom='true'
                type='switch'
                id='clientsData'
                className={styles.notificationCheckbox}
                checked={checkboxState.clientsData}
                onChange={(e) => updateCheckboxState('clientsData', e.target.checked)}
              />
              <p className={styles.checkboxLabel}>Client's Data (Name, Email, Heigth)</p>
            </div>

            <div className={`${styles.switchAndText} ${areNotesDisabled && styles.disabled}`}>
              <Form.Check
                custom='true'
                type='switch'
                id='includeNotes'
                className={styles.notificationCheckbox}
                checked={checkboxState.includeNotes}
                onChange={(e) => updateCheckboxState('includeNotes', e.target.checked)}
                disabled={areNotesDisabled}
              />
              <p className={styles.checkboxLabel}>Notes</p>
            </div>

            <div className={`${styles.switchAndText} ${areGraphsDisabled && styles.disabled}`}>
              <Form.Check
                custom='true'
                type='switch'
                id='angleGraphs'
                className={styles.notificationCheckbox}
                checked={checkboxState.angleGraphs}
                onChange={(e) => updateCheckboxState('angleGraphs', e.target.checked)}
                disabled={areGraphsDisabled}
              />
              <p className={styles.checkboxLabel}>Joint Angles</p>
            </div>

            <div className={`${styles.switchAndText} ${areVelocitiesDisabled && styles.disabled}`}>
              <Form.Check
                custom='true'
                type='switch'
                id='velocities'
                className={styles.notificationCheckbox}
                checked={checkboxState.velocities}
                onChange={(e) => updateCheckboxState('velocities', e.target.checked)}
                disabled={areVelocitiesDisabled}
              />
              <p className={styles.checkboxLabel}>Velocities</p>
            </div>

            <div className={`${styles.switchAndText} ${areShoulderHipTiltsDisabled && styles.disabled}`}>
              <Form.Check
                custom='true'
                type='switch'
                id='tilts'
                className={styles.notificationCheckbox}
                checked={checkboxState.tilts}
                onChange={(e) => updateCheckboxState('tilts', e.target.checked)}
                disabled={areShoulderHipTiltsDisabled}
              />
              <p className={styles.checkboxLabel}>Shoulder and Hip Tilts</p>
            </div>

            <div className={`${styles.switchAndText} ${isShoulderVsHipsGraphDisabled && styles.disabled}`}>
              <Form.Check
                custom='true'
                type='switch'
                id='vsHipsGraph'
                className={styles.notificationCheckbox}
                checked={checkboxState.vsHipsGraph}
                onChange={(e) => updateCheckboxState('vsHipsGraph', e.target.checked)}
                disabled={isShoulderVsHipsGraphDisabled}
              />
              <p className={styles.checkboxLabel}>Shoulder vs Hip Tilt Graph</p>
            </div>

            <div className={`${styles.switchAndText} ${isCoBDisabled && styles.disabled}`}>
              <Form.Check
                custom='true'
                type='switch'
                id='centerOfBody'
                className={styles.notificationCheckbox}
                checked={checkboxState.centerOfBody}
                onChange={(e) => updateCheckboxState('centerOfBody', e.target.checked)}
                disabled={isCoBDisabled}
              />
              <p className={styles.checkboxLabel}>Center of Body</p>
            </div>
          </Form>
        </div>
        <button onClick={() => exportPDF(checkboxState)}>Download Report</button>
      </div>
    </div>
  );
}

export default React.memo(ReportModal);
