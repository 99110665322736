import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styles from './CustomVideoPlayer.module.css';
import stopwatchIcon from '../../assets/stopwatch-icon.svg';
import screenfull from 'screenfull'; // Import screenfull library
import {
  IconArrowBackUp,
  IconArrowForwardUp,
  IconDotsVertical,
  IconDownload,
  IconMaximize,
  IconMinimize,
  IconPlayerPause,
  IconPlayerPlay,
  IconPlayerSkipBack,
  IconPlayerSkipForward,
} from '@tabler/icons-react';
import clsx from 'clsx';

const CustomVideoPlayer = React.forwardRef(({ src, downloadVideo, isSecond = false }, ref) => {
  const [playing, setPlaying] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [played, setPlayed] = useState(0); // Track played fraction
  const [seeking, setSeeking] = useState(false); // Is user currently seeking
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePlayPause = () => {
    setPlaying(!playing);
  };

  const handlePlayBackRateChange = (rate) => {
    setPlaybackRate(rate);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    ref.current.seekTo(parseFloat(e.target.value));
  };

  const handleSeekTouchStart = (e) => {
    setSeeking(true);
  };

  const handleSeekTouchEnd = (e) => {
    setSeeking(false);
    ref.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    // Only update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleSkip = (amount) => {
    const currentTime = ref.current.getCurrentTime();
    ref.current.seekTo(currentTime + amount);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest(`.${styles.popup}`) && !event.target.closest(`.${styles.moreOptions}`)) {
        setIsPopupOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const toggleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(ref.current.wrapper.parentNode.parentNode);
    }
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      // Extend the valid keys for control of two different players
      const validKeys = [
        'f',
        'F',
        'Escape',
        ' ',
        's',
        'S',
        'd',
        'D',
        'ArrowRight',
        'a',
        'A',
        'ArrowLeft',
        'k',
        'K',
        'l',
        'L',
        'j',
        'J',
      ];

      if (validKeys.includes(e.key)) {
        switch (e.key) {
          case 'f':
          case 'F':
            if (!isSecond) {
              toggleFullScreen();
            }
            break;
          case 'Escape':
            if (screenfull.isFullscreen) {
              toggleFullScreen();
            }
            break;
          case ' ':
            setPlaying(!playing);
            break;
          case 's':
          case 'S':
            if (!isSecond) {
              // Only react on the first video player
              setPlaying(!playing);
            }
            break;
          case 'd':
          case 'D':
          case 'ArrowRight':
            if (!isSecond) {
              // Only react on the first video player
              handleSkip(1 / 25);
            }
            break;
          case 'a':
          case 'A':
          case 'ArrowLeft':
            if (!isSecond) {
              // Only react on the first video player
              handleSkip(-1 / 25);
            }
            break;
          case 'k':
          case 'K':
            if (isSecond) {
              // Only react on the second video player
              setPlaying(!playing);
            }
            break;
          case 'l':
          case 'L':
            if (isSecond) {
              // Only react on the second video player
              handleSkip(1 / 25);
            }
            break;
          case 'j':
          case 'J':
            if (isSecond) {
              // Only react on the second video player
              handleSkip(-1 / 25);
            }
            break;
          default:
            break;
        }

        e.preventDefault();
        e.stopPropagation();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  });

  return (
    <div className={clsx(styles.videoPlayer)}>
      <div className={styles.videoWrapper} onClick={togglePlayPause}>
        <ReactPlayer
          ref={ref}
          url={src}
          playing={playing}
          playsinline={true}
          progressInterval={250}
          loop={true}
          muted={true}
          width='100%'
          height='100%'
          playbackRate={playbackRate}
          onProgress={handleProgress}
        />
      </div>

      <div className={styles.controls}>
        <div className={styles.playbackButtons}>
          <IconPlayerSkipBack
            stroke={2.2}
            className={clsx(styles.playbackButton, styles.skipButton)}
            onMouseDown={() => handleSkip(-1 / 25)}
          />
          {!playing ? (
            <IconPlayerPlay className={styles.playbackButton} onClick={togglePlayPause} />
          ) : (
            <IconPlayerPause className={styles.playbackButton} onClick={togglePlayPause} />
          )}
          <IconPlayerSkipForward
            stroke={2.2}
            className={clsx(styles.playbackButton, styles.skipButton)}
            onClick={() => handleSkip(1 / 25)}
          />
        </div>

        <input
          type='range'
          min={0}
          max={1}
          step='any'
          value={played}
          onMouseDown={handleSeekMouseDown}
          onTouchStart={handleSeekTouchStart}
          onTouchEnd={handleSeekTouchEnd}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
          className={styles.progress}
        />

        <div className={styles.moreOptions}>
          <IconDotsVertical className={styles.playbackButton} onClick={togglePopup} />
          <div className={clsx(styles.popup, isPopupOpen && styles.inView)}>
            <div className={styles.popupItem}>
              <img src={stopwatchIcon} className={styles.popupIcon} alt='stopwatch' />
              <p className={styles.popupText}>Speed</p>
              <select
                className={styles.selectRate}
                onChange={(e) => handlePlayBackRateChange(parseFloat(e.target.value))}
                value={playbackRate}
              >
                <option value={0.25}>0.25x</option>
                <option value={0.5}>0.5x</option>
                <option value={1}>1x</option>
                <option value={1.5}>1.5x</option>
                <option value={2}>2x</option>
              </select>
            </div>
            <div className={styles.popupItem}>
              <IconDownload className={styles.popupIcon} />
              <p className={styles.popupText} onClick={() => downloadVideo(src, isSecond)}>
                Download
              </p>
            </div>
            <div className={styles.popupItem} onClick={toggleFullScreen}>
              {screenfull.isFullscreen ? (
                <IconMinimize className={styles.popupIcon} />
              ) : (
                <IconMaximize className={styles.popupIcon} />
              )}
              <p className={styles.popupText}>Fullscreen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CustomVideoPlayer;
