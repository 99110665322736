import React, { useEffect, useRef, useState } from 'react';

import bodyOutlineCoB from './../../assets/body-outline-cob.png';

import styles from './CenterOfBody.module.css';
import CobRulerXNew from '../cobRulerX/CobRulerXNew';
import CobRulerYNew from '../cobRulerY/CobRulerYNew';

const CenterOfBodyNew = ({ dataPoint1Data, dataPoint2Data, videoRef, isCompare = false }) => {
  const calculateWidth = () => {
    let width = isCompare
      ? window.innerWidth / (window.innerWidth < 1280 ? 2 : 4) - 32
      : window.innerWidth < 768
      ? window.innerWidth - 64
      : window.innerWidth / 2 - 32;

    return Math.min(width, isCompare ? 360 : 440);
  };

  const canvasRef = useRef(null);
  const [circleWidth, setCircleWidth] = useState(calculateWidth());

  // UseEffect for handling size of the canvas
  useEffect(() => {
    const handleResize = () => {
      setCircleWidth(calculateWidth());
    };

    setCircleWidth(calculateWidth());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isCompare]);

  const scaleFactorX = 2; // Adjust this as needed
  const scaleFactorY = 1; // Adjust this as needed

  // const circleMovementFactor = 1.2; // Adjust this variable
  // const hipsShoulderDistanceFactor = 1.5; // Adjust this variable

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = circleWidth * 2;
    canvas.height = circleWidth * 1.5 * 2;
    canvas.style.width = `${circleWidth}px`;
    canvas.style.height = `${circleWidth * 1.5}px`;

    let lastRenderTime = 0;
    let requestId;

    const animate = (timestamp) => {
      if (timestamp - lastRenderTime < 16) {
        requestId = requestAnimationFrame(animate);
        return;
      }

      const videoDuration = videoRef.current?.getDuration();
      const videoCurrentTime = videoRef.current.getCurrentTime() % videoDuration;
      const newProgress = videoDuration ? videoCurrentTime / videoDuration : 0;
      const newIndex = ((dataPoint1Data.length - 1) * newProgress).toFixed(0);

      const currentCOBX = dataPoint1Data ? dataPoint1Data[newIndex] : 0;
      const currentCOBY = dataPoint2Data ? dataPoint2Data[newIndex] : 0;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Calculate the position of the circle based on COB_X and COB_Y
      const circleX = canvas.width / 2 + currentCOBX * scaleFactorX; // Adjust scaleFactorX as needed
      const circleY = canvas.height / 2 + currentCOBY * scaleFactorY; // Reverse the direction by using a plus sign

      // // Calculate the position of the vertical line representing hips-shoulder distance
      // const lineX = canvas.width / 2;
      // const lineY1 = canvas.height / 2 - (circleWidth / 2) * hipsShoulderDistanceFactor;
      // const lineY2 = canvas.height / 2 + (circleWidth / 2) * hipsShoulderDistanceFactor;

      // // Draw the black line representing shoulder width
      // const shoulderWidth = circleWidth * circleMovementFactor; // Adjust the factor
      // ctx.beginPath();
      // ctx.moveTo(canvas.width / 2 - shoulderWidth / 2, canvas.height / 2);
      // ctx.lineTo(canvas.width / 2 + shoulderWidth / 2, canvas.height / 2);
      // ctx.strokeStyle = 'red';
      // ctx.lineWidth = 5; // Adjust line width as needed
      // ctx.stroke();

      // // Draw the vertical line representing hips-shoulder distance
      // ctx.beginPath();
      // ctx.moveTo(lineX, lineY1);
      // ctx.lineTo(lineX, lineY2);
      // ctx.strokeStyle = 'red';
      // ctx.lineWidth = 5; // Adjust line width as needed
      // ctx.stroke();

      // Draw the red circle
      ctx.beginPath();
      ctx.arc(circleX, circleY, 16, 0, 2 * Math.PI);
      ctx.fillStyle = '#0C89C3';
      ctx.fill();

      lastRenderTime = timestamp;

      // Request the next animation frame
      requestId = requestAnimationFrame(animate);
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [dataPoint1Data, dataPoint2Data, videoRef, circleWidth]);

  return (
    <div
      id={'CoB'}
      className={styles.CoBSection}
      style={{
        width: `calc(${circleWidth}px + 16px + 8px)`,
        height: `calc(${circleWidth * 1.5}px + 16px + 8px)`,
      }}
    >
      <div className={styles.COBImage}>
        <div
          className={styles.cobBackground}
          style={{
            width: circleWidth,
            height: circleWidth * 1.5,
            backgroundImage: `url(${bodyOutlineCoB})`,
            filter: 'contrast(0%)',
          }}
          alt='COB-background'
        ></div>
        <canvas
          className={styles.CoBCanvas}
          style={{ width: circleWidth, height: circleWidth * 1.5 }}
          ref={canvasRef}
        />
      </div>
      <div className={styles.cobRulerX}>
        <CobRulerXNew
          scaleFactorX={scaleFactorX}
          videoRef={videoRef}
          dataPoint1Data={dataPoint1Data}
          width={circleWidth}
        />
      </div>
      <div className={styles.cobRulerY}>
        <CobRulerYNew
          scaleFactorY={scaleFactorY}
          videoRef={videoRef}
          dataPoint2Data={dataPoint2Data}
          height={circleWidth * 1.5}
        />
      </div>
    </div>
  );
};

export default React.memo(CenterOfBodyNew);
