import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { deleteObject, getDownloadURL, getStorage, list, listAll, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAthleteList, getUserData } from '../../store/selectors/userSelectors';

// import { customLogo } from '../../custom-labeling';
import manBackground from '../../assets/RunningManWhite.png';

import styles from './Comparison.module.css';

import ResultList from '../resultList/ResultList';
import ComparisonResult from '../comparisonResult/ComparisonResult';
import VideoRenamingModal from '../modals/VideoRenamingModal';
import DashboardMobileOverlay from '../dashboardMobileOverlay/DashboardMobileOverlay';
import ComparisonResultNew from '../comparisonResult/ComparisonResultNew';

function Comparison() {
  const location = useLocation();

  const linkingVideoInfo = location.state ? JSON.parse(location.state) : null;

  const [resultList, setResultList] = useState([]);
  const [currentResult, setCurrentResult] = useState(
    location.state ? ref(storage, linkingVideoInfo?.result._location.path_) : null
  );

  const [currentResult2, setCurrentResult2] = useState(null);
  // const format = location.state
  //   ? checkFileVersion(linkingVideoInfo?.result._location.path_).then((format) => format)
  //   : null;
  const [anglesFormat, setAnglesFormat] = useState(null);
  const [anglesFormat2, setAnglesFormat2] = useState(null);
  useEffect(() => {
    if (location.state) {
      checkFileVersion(linkingVideoInfo?.result._location.path_)
        .then((format) => {
          setAnglesFormat(format);
        })
        .catch((error) => {
          console.error('Error checking file version:', error);
          // Handle the error as needed
        });
    }
  }, [location.state]);

  const [currentVideoName, setCurrentVideoName] = useState(location.state ? linkingVideoInfo?.videoName : null);
  const [currentVideoName2, setCurrentVideoName2] = useState(null);
  const [currentVideoComment, setCurrentVideoComment] = useState(location.state ? linkingVideoInfo?.comment : null);
  const [currentVideoComment2, setCurrentVideoComment2] = useState(null);

  const [currentPreset, setCurrentPreset] = useState(location.state ? linkingVideoInfo?.jointsDisplay : null);
  const [currentPreset2, setCurrentPreset2] = useState(null);

  const [isModalActive, setIsModalActive] = useState(false);
  const [renamingModalRef, setRenamingModalRef] = useState(null);
  const [renamingModalvideoName, setRenamingModalvideoName] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isMobileOverlayDisabled, setIsMobileOverlayDisabled] = useState(false);

  const { currentUser } = useAuth();

  const fetchDataCallId = useRef(0); // Add this ref at the component level

  const userData = useSelector(getUserData);
  const athleteList = useSelector(getAthleteList);

  const videoListRef = useMemo(() => {
    return ref(storage, `videos/${currentUser.email}/Results/`);
  }, [currentUser.email]);

  const athleteListRef = useMemo(() => {
    if (athleteList.length === 0) {
      return [];
    }

    return athleteList.map((athlete) => {
      return ref(storage, `videos/${athlete.User}/Results/`);
    });
  }, [athleteList]);

  async function checkFileVersion(path) {
    const storage = getStorage();
    const folderRef = ref(storage, path);

    try {
      const listResult = await list(folderRef);

      // Check if 'anglesNew.json' exists
      const hasNewFile = listResult.items.some((item) => item.name === 'anglesNew.json');
      if (hasNewFile) {
        return 'new';
      }

      // Check if 'angles.json' exists
      const hasOldFile = listResult.items.some((item) => item.name === 'angles.json');

      if (hasOldFile) {
        return 'old';
      }

      // If neither file is found, you might want to return a default value or throw an error
      return null;
    } catch (error) {
      console.error('Error checking file version:', error);
      throw error; // Re-throw the error or handle it as needed
    }
  }

  const handleEditVideo = useCallback((videoRef, videoName) => {
    setRenamingModalRef(videoRef);
    setRenamingModalvideoName(videoName);
    setIsModalActive(true);
  }, []);

  const handleSelectVideo = async (result) => {
    const { folder, athleteEmail } = result;

    const path = `videos/${athleteEmail}/Results/${folder}`;

    const format = await checkFileVersion(path).then((format) => format);
    setAnglesFormat(format);
    setCurrentResult(ref(storage, path));
    setCurrentPreset(result.preset);
    setCurrentVideoName(result.videoName);
    setCurrentVideoComment(result.comment);
  };

  const handleSelectVideo2 = async (result) => {
    const { folder, athleteEmail } = result;

    const path = `videos/${athleteEmail}/Results/${folder}`;

    const format = await checkFileVersion(path).then((format) => format);
    setAnglesFormat2(format);
    setCurrentResult2(ref(storage, path));
    setCurrentPreset2(result.preset);
    setCurrentVideoName2(result.videoName);
    setCurrentVideoComment2(result.comment);
  };

  const handleDeleteVideo = async (videoPath) => {
    const storageRef = ref(storage, videoPath);

    try {
      // List all items (files) in the folder
      const listResult = await listAll(storageRef);

      // Delete each file in the list
      await Promise.all(
        listResult.items.map(async (item) => {
          await deleteObject(item);
        })
      );

      setCurrentResult(null);
      setCurrentResult2(null);

      fetchData(); // Fetch data again to update the result list
      setIsModalActive(false); // Close the modal
    } catch (error) {
      console.error('Failed to delete video and folder:', error);
    }
  };

  // Function to check if info.json file exists
  const checkInfoJsonExists = async (path) => {
    const folderRef = ref(storage, path);
    const folderContents = await list(folderRef);
    const infoJsonExists = folderContents.items.some((item) => item.name === 'info.json');
    return infoJsonExists;
  };

  // Function to parse the info.json file
  const parseInfoJson = async (path) => {
    const infoExists = await checkInfoJsonExists(path);
    if (!infoExists) {
      return null;
    }

    const infoRef = ref(storage, `${path}/info.json`);
    try {
      const infoSnapshot = await getDownloadURL(infoRef);
      const response = await fetch(infoSnapshot);
      const jsonData = await response.json();

      return {
        videoName: jsonData.name,
        date: jsonData.date,
        comment: jsonData.comment || '',
        preset: jsonData.preset || '',
        timestamp: jsonData.timestamp || '',
        view: jsonData.view || '',
        exercise: jsonData.exercise || '',
      };
    } catch (error) {
      console.error('Failed to fetch info.json:', error);
      return null;
    }
  };

  const fetchData = useCallback(async () => {
    const currentCallId = ++fetchDataCallId.current; // Increment the counter for each call

    setIsDataLoading(true);
    try {
      if (currentCallId !== fetchDataCallId.current) {
        // This means a new call has been initiated, abort this execution
        return;
      }

      const usersResponses = await Promise.all([videoListRef, ...athleteListRef].map((ref) => listAll(ref)));

      const usersPathsPromises = usersResponses.map(async (response, index) => {
        const athleteEmail = [userData, ...athleteList][index]?.User;
        const athleteName = [userData, ...athleteList][index]?.Name;

        const pathsPromises = response.prefixes.map(async (folder) => {
          const path = `videos/${athleteEmail}/Results/${folder.name}`;
          const info = await parseInfoJson(path); // Parse info.json

          if (!info) {
            return null; // Skip the path if info.json is not found
          }

          return {
            path: ref(storage, path),
            videoName: info.videoName,
            date: info.date,
            comment: info.comment,
            preset: info.preset,
            timestamp: info.timestamp,
            view: info.view,
            exercise: info.exercise,
            athleteEmail,
            folder: folder.name,
          };
        });

        const paths = await Promise.all(pathsPromises);
        const resolvedPaths = paths.filter(Boolean);
        return {
          athleteName: athleteEmail === currentUser.email ? 'My Videos' : athleteName || athleteEmail,
          paths: resolvedPaths,
        };
      });

      const usersPaths = await Promise.all(usersPathsPromises); // Wait for all user paths promises to resolve

      const combinedResult = [...usersPaths];
      const sortedResult = combinedResult.slice(1).sort((a, b) => {
        const nameA = a.athleteName.trim().toLowerCase();
        const nameB = b.athleteName.trim().toLowerCase();

        return nameA.localeCompare(nameB);
      });

      setResultList([combinedResult[0], ...sortedResult]);
    } finally {
      if (currentCallId === fetchDataCallId.current) {
        setIsDataLoading(false);
      }
    }
  }, [videoListRef, athleteListRef]);

  // CAREFUL HERE:
  const updateInfoJson = useCallback(
    async (videoRefPath, newInfo) => {
      try {
        // Reference to the info.json file
        const infoRef = ref(storage, `${videoRefPath}/info.json`);

        // Download the existing info.json data
        const infoSnapshot = await getDownloadURL(infoRef);
        const response = await fetch(infoSnapshot);
        const jsonData = await response.json();

        // Modify the info.json data
        const updatedJsonData = { ...jsonData, ...newInfo };

        // Upload the updated info.json back to Firebase Storage
        const updatedInfoData = JSON.stringify(updatedJsonData);
        const updatedInfoBlob = new Blob([updatedInfoData], { type: 'application/json' });
        await uploadBytes(ref(storage, `${videoRefPath}/info.json`), updatedInfoBlob);
        fetchData();
      } catch (error) {
        console.error('Failed to update info.json:', error);
      }
    },
    [fetchData]
  );

  useEffect(() => {
    fetchData();
  }, [athleteListRef, userData]);

  return (
    <>
      {!isMobileOverlayDisabled && <DashboardMobileOverlay setIsMobileOverlayDisabled={setIsMobileOverlayDisabled} />}

      <ResultList
        resultList={resultList}
        currentResult={currentResult}
        handleSelectVideo={handleSelectVideo}
        onEditVideo={handleEditVideo}
        isDashboardLoading={isDataLoading}
      />

      <ResultList
        resultList={resultList}
        currentResult={currentResult2}
        handleSelectVideo={handleSelectVideo2}
        isDashboardLoading={isDataLoading}
        isSecond={true}
        onEditVideo={handleEditVideo}
      />
      <>
        {currentResult || currentResult2 ? (
          <>
            {anglesFormat !== 'old' && anglesFormat2 !== 'old' ? (
              <ComparisonResultNew
                result={currentResult}
                result2={currentResult2}
                currentPreset={currentPreset}
                currentPreset2={currentPreset2}
                setCurrentPreset={setCurrentPreset}
                setCurrentPreset2={setCurrentPreset2}
                updateInfoJson={updateInfoJson}
                comment={currentVideoComment}
                comment2={currentVideoComment2}
                videoName={currentVideoName}
                videoName2={currentVideoName2}
                fetchData={fetchData}
              />
            ) : (
              <ComparisonResult
                result={currentResult}
                result2={currentResult2}
                currentPreset={currentPreset}
                currentPreset2={currentPreset2}
                setCurrentPreset={setCurrentPreset}
                setCurrentPreset2={setCurrentPreset2}
                updateInfoJson={updateInfoJson}
                comment={currentVideoComment}
                comment2={currentVideoComment2}
                videoName={currentVideoName}
                videoName2={currentVideoName2}
                fetchData={fetchData}
              />
            )}
          </>
        ) : (
          <div className={styles.emptyResult}>
            <img className={styles.manBackground} src={manBackground} alt='Running Man Background' />
          </div>
        )}
      </>
      <VideoRenamingModal
        isActive={isModalActive}
        setIsModalActive={setIsModalActive}
        videoRef={renamingModalRef}
        videoName={renamingModalvideoName}
        fetchData={fetchData}
        setCurrentVideoName={setCurrentVideoName}
        onDeleteVideo={handleDeleteVideo}
      />
    </>
  );
}

export default React.memo(Comparison);
