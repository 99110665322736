import React, { useEffect, useRef, useState, forwardRef } from 'react';
import tiltArrowRight from './../../assets/TiltArrowRight.svg';
import tiltArrowLeft from './../../assets/TiltArrowLeft.svg';

import bodyOutline from './../../assets/body-outline.svg';
import styles from './TiltCircle.module.css';

const TiltCircle = forwardRef(({ videoRef, linedata, jointName, lineLimit, isCompare = false }, ref) => {
  const calculateCircleWidth = () => {
    let width = window.innerWidth > 600 ? 320 : window.innerWidth - 64;
    return isCompare ? window.innerWidth / 4 - 48 : width;
  };

  const canvasRef = useRef(null);
  const [circleWidth, setCircleWidth] = useState(calculateCircleWidth());

  // UseEffect for handling size of the canvas
  useEffect(() => {
    const handleResize = () => {
      setCircleWidth(calculateCircleWidth());
    };

    setCircleWidth(calculateCircleWidth());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isCompare]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = circleWidth * 2;
    canvas.height = (circleWidth / 1.55) * 2;
    canvas.style.width = `${circleWidth}px`;
    canvas.style.height = `${circleWidth / 1.55}px`;

    let lastRenderTime = 0;
    let requestId;

    const arrowRight = new Image();
    const arrowLeft = new Image();
    arrowRight.src = tiltArrowRight;
    arrowLeft.src = tiltArrowLeft;

    const animate = (timestamp) => {
      if (timestamp - lastRenderTime < 16) {
        requestId = requestAnimationFrame(animate);
        return;
      }

      const videoDuration = videoRef.current?.getDuration();
      const videoCurrentTime = videoRef.current.getCurrentTime() % videoDuration;
      const newProgress = videoDuration ? videoCurrentTime / videoDuration : 0;
      const newTiltIndex = ((linedata.length - 1) * newProgress).toFixed(0);
      let currentTilt = linedata ? linedata[newTiltIndex][`TILT_${jointName.slice(0, -1).toUpperCase()}`] : 0;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const oversamplingFactor = 2;
      const arrowSize = 20;
      let tiltDirection = currentTilt?.toFixed(0) > 0 ? -1 : 1;
      if (currentTilt.toFixed(0) === 0) tiltDirection = 0;

      const arrowOffset = 10;

      // Right Arrow

      ctx.save();
      ctx.translate(0, canvas.height);
      ctx.scale(-tiltDirection, tiltDirection); // Flip vertically

      ctx.translate(arrowOffset, canvas.height / 2);
      const aspectRatioRight = arrowRight.width / arrowRight.height;
      const arrowWidthRight = arrowSize;
      const arrowHeightRight = arrowSize / aspectRatioRight;
      ctx.drawImage(arrowRight, 0, -arrowHeightRight / 2, arrowWidthRight, arrowHeightRight);
      ctx.restore();

      // Left Arrow

      ctx.save();
      ctx.translate(0, canvas.height);
      ctx.scale(tiltDirection, -tiltDirection); // Flip vertically

      ctx.translate(canvas.width - arrowWidthRight - arrowOffset, canvas.height / 2);
      const aspectRatioLeft = arrowLeft.width / arrowLeft.height;
      const arrowWidthLeft = arrowSize;
      const arrowHeightLeft = arrowSize / aspectRatioLeft;
      ctx.drawImage(arrowLeft, 0, -arrowHeightLeft / 2, arrowWidthLeft, arrowHeightLeft);
      ctx.restore();

      let colorArray = [0, 255, 0];
      const normalizedTilt = Math.abs(currentTilt % 360);
      const angleFactor = Math.sin((180 * (normalizedTilt / lineLimit) * Math.PI) / 180);
      const redValue = Math.round(255 * angleFactor);
      const greenValue = Math.round(255 * (1 - angleFactor));
      if (normalizedTilt <= lineLimit / 2) colorArray = [redValue, 255, 0];
      else if (normalizedTilt <= lineLimit) colorArray = [255, 255 - greenValue, 0];
      else colorArray = [255, 0, 0];

      const indent = 64;
      const shapeIndent = 4;
      const shapeSize = 28;
      const shapeOutlineWidth = 2;
      const lineWidth = 12;

      ctx.beginPath();
      const lineX2 = canvas.width / 2 - (canvas.width / 2 - indent) * Math.cos((currentTilt * Math.PI) / 180);
      const lineY2 = canvas.height / 2 + (canvas.height / 2 - indent) * Math.sin((currentTilt * Math.PI) / 180);
      ctx.moveTo(canvas.width - lineX2, canvas.height - lineY2);
      ctx.lineTo(lineX2, lineY2);
      ctx.lineWidth = lineWidth + 4;
      ctx.stroke();
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = `rgb(${colorArray[0]}, ${colorArray[1]}, ${colorArray[2]})`;
      ctx.stroke();

      ctx.lineWidth = shapeOutlineWidth;
      const rotationAngle = (currentTilt * Math.PI) / 180;

      if (jointName === 'Hips') {
        const shapeX1 = lineX2 - shapeIndent * Math.cos(rotationAngle);
        const shapeY1 = lineY2 + shapeIndent * Math.sin(rotationAngle);
        const shapeX2 = canvas.width - lineX2 + shapeIndent * Math.cos(rotationAngle);
        const shapeY2 = canvas.height - lineY2 - shapeIndent * Math.sin(rotationAngle);
        ctx.fillStyle = '#ED7D31';
        ctx.strokeStyle = 'black';
        ctx.save();
        ctx.translate(shapeX1, shapeY1);
        ctx.rotate(-rotationAngle);
        ctx.beginPath();
        ctx.moveTo(0, -shapeSize / 2);
        ctx.lineTo(shapeSize / 2, shapeSize / 2);
        ctx.lineTo(-shapeSize / 2, shapeSize / 2);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();

        ctx.save();
        ctx.translate(shapeX2, shapeY2);
        ctx.rotate(-rotationAngle);
        ctx.beginPath();
        ctx.moveTo(0, -shapeSize / 2);
        ctx.lineTo(shapeSize / 2, shapeSize / 2);
        ctx.lineTo(-shapeSize / 2, shapeSize / 2);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
      } else if (jointName === 'Shoulders') {
        const shapeX = lineX2;
        const shapeY = lineY2;
        ctx.fillStyle = '#FFC000';
        ctx.strokeStyle = 'black';

        ctx.beginPath();
        ctx.arc(shapeX, shapeY, shapeSize / 2, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.beginPath();
        ctx.arc(canvas.width - shapeX, canvas.height - shapeY, shapeSize / 2, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
      }

      ctx.strokeStyle = 'black';
      ctx.fillStyle = 'rgba(255, 255, 255)';
      ctx.font = '36px Lato';
      const padding = 6;
      const text = Math.abs(currentTilt?.toFixed(0)) + '°';
      const rectX = 80 - 36;
      const rectY = 64 - 28;
      ctx.fillRect(rectX - padding, rectY - padding, 36 * 2 + padding, 36 + padding);
      ctx.fillStyle = '#0E0E0E';
      ctx.fillText(text, 80, 64);

      lastRenderTime = timestamp;
      requestId = requestAnimationFrame(animate);
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [videoRef, linedata, jointName, lineLimit, circleWidth]);

  return (
    <div ref={ref} id={jointName + 'Tilt'} className={styles.TiltSection}>
      <>
        <div className={styles.TiltImage}>
          <div
            className={jointName === 'Shoulders' ? styles.shoulders : styles.hips}
            style={{
              width: circleWidth,
              height: circleWidth / 1.55,
              backgroundImage: `url(${bodyOutline})`,
              backgroundPosition: `center top ${
                jointName === 'Shoulders' ? `-${circleWidth / 2.8}px` : `-${circleWidth / 0.8}px`
              }`,
            }}
            alt='Tilt-Circle'
          ></div>
          <canvas
            className={styles.tiltLineCanvas}
            style={{ width: circleWidth, height: circleWidth / 1.55 }}
            ref={canvasRef}
          />
        </div>
      </>
    </div>
  );
});

export default React.memo(TiltCircle);
