import React, { useRef, useEffect } from 'react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

function GraphDeltaValueNew({
  videoRef,
  dataPoint1Data,
  dataPoint2Data,
  dataPoint1Label,
  dataPoint2Label,
  dataVisibility,
  label = '°',
  graphRef,
}) {
  const canvasRef = useRef(null);

  const oversamplingFactor = window.screen.height / window.innerHeight;
  const isVelocity = label === 'm/s';

  const isVisible = useIntersectionObserver(graphRef, {
    threshold: 0,
    rootMargin: '240px 240px 240px 240px',
  });

  const toFixedCustom = (angle) => {
    if (!isVelocity) {
      return angle.toFixed(0);
    } else if (isVelocity) {
      return Math.round((angle + Number.EPSILON) * 10) / 10;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      const parentDiv = canvasRef.current.parentNode;
      if (canvas && parentDiv) {
        // Set the canvas width to the minimum required width
        canvas.width = 120 * oversamplingFactor;
        canvas.height = 16 * oversamplingFactor;
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    const resizeObserver = new ResizeObserver(handleResize);
    const parentDiv = graphRef?.current;

    if (parentDiv) {
      resizeObserver.observe(parentDiv);
    }

    return () => {
      window.removeEventListener('resize', handleResize);

      if (parentDiv) {
        resizeObserver.unobserve(parentDiv);
      }
    };
  }, [graphRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const fontSize = 16 * oversamplingFactor + 'px lato';

    let lastRenderTime = 0;
    let requestId;

    const animate = (timestamp) => {
      if (!isVisible) {
        cancelAnimationFrame(requestId);
        return; // Stop animation when not visible
      }

      if (timestamp - lastRenderTime < 16) {
        requestId = requestAnimationFrame(animate);
        return;
      }

      const videoDuration = videoRef.current?.getDuration();
      const videoCurrentTime = videoRef.current.getCurrentTime() % videoDuration;
      const newProgress = videoDuration ? videoCurrentTime / videoDuration : 0;

      const newDataIndex = Math.floor(dataPoint1Data.length * newProgress);
      const angle1 = dataPoint1Data ? dataPoint1Data[newDataIndex] : 0;
      const angle2 = dataPoint2Data ? dataPoint2Data[newDataIndex] : 0;

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const newLineX =
        newProgress *
        (canvasRef.current?.parentNode.offsetWidth - (isVelocity ? 24 : 16) * oversamplingFactor * newProgress) *
        oversamplingFactor;
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);

      ctx.save(); // Save the current canvas state

      // Apply the transform to only draw a small part of the canvas
      // ctx.translate(-newLineX, 0);

      if (
        dataVisibility[dataPoint1Label] &&
        dataVisibility[dataPoint2Label] &&
        angle1 !== null &&
        angle1 !== undefined &&
        angle2 !== null &&
        angle2 !== undefined
      ) {
        ctx.font = fontSize;

        ctx.fillStyle = '#0E0E0E';
        ctx.fillText('Δ', 0, canvasHeight);

        ctx.font = fontSize;
        ctx.fillStyle = '#0E0E0E';
        const angleDifference = Math.abs(angle1 - angle2);

        ctx.fillText(`${toFixedCustom(angleDifference)}${label}`, 13 * oversamplingFactor, canvasHeight);
      } else if (dataVisibility[dataPoint1Label] && angle1 !== null && angle1 !== undefined) {
        ctx.font = fontSize;
        ctx.fillStyle = '#0E0E0E';
        ctx.fillText(`${toFixedCustom(angle1)}${label}`, 0, canvasHeight - 1);
      } else if (dataVisibility[dataPoint2Label] && angle2 !== null && angle2 !== undefined) {
        ctx.font = fontSize;
        ctx.fillStyle = '#0E0E0E';
        ctx.fillText(`${toFixedCustom(angle2)}${label}`, 0, canvasHeight);
      }

      ctx.restore(); // Restore the canvas state to reset the transform

      canvas.style.transform = `translateX(${newLineX / oversamplingFactor}px)`;

      lastRenderTime = timestamp;
      requestId = requestAnimationFrame(animate);
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [videoRef, dataVisibility, dataPoint1Data, dataPoint2Data, dataPoint1Label, dataPoint2Label, label, isVisible]);

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0px',
        left: '20px',
        minWidth: 'calc(320px - 32px)',
        width: 'calc(100% - 20px)',
        height: '16px',
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          height: '16px',
          pointerEvents: 'none',
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
}

export default React.memo(GraphDeltaValueNew);
