import { accountsTable } from '../../airtable-scripts';
import { fetchAthletesData } from './athletesActions';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions();
const checkSubscription = httpsCallable(functions, 'checkSubscription');

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const SET_LOADING = 'SET_LOADING';

export const fetchUserRequest = () => ({
  type: FETCH_USER_REQUEST,
});

export const fetchUserSuccess = (userData) => ({
  type: FETCH_USER_SUCCESS,
  payload: userData,
});

export const fetchUserFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});

export const fetchUserData = () => {
  return async (dispatch) => {
    const currentUserEmail = localStorage.getItem('currentUserEmail');

    dispatch(fetchUserRequest());
    if (currentUserEmail === null) {
      dispatch(fetchUserFailure('No user email found'));
      return;
    }
    try {
      const response = await accountsTable
        .select({
          view: 'Grid view',
          filterByFormula: `{User} = '${currentUserEmail}'`,
        })
        .firstPage();

      if (response.length > 0) {
        let userData = response[0].fields;

        // Assume stripeID is stored in userData
        const stripeID = userData['Stripe ID'];
        if (stripeID) {
          const subscriptionResult = await checkSubscription(stripeID);
          if (subscriptionResult.data && subscriptionResult.data.status !== 'inactive') {
            userData.activeSubscription = subscriptionResult.data; // Append subscription data to userData
          } else {
            userData.activeSubscription = { status: 'inactive' }; // Handle inactive or no data case
          }
        }

        dispatch(fetchUserSuccess(userData));
        dispatch(fetchAthletesData());
      } else {
        throw new Error('User not found');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      dispatch(fetchUserFailure(error));
    }
  };
};

export const updateSubscriptionTier = (subscriptionRecordID) => {
  return async (dispatch, getState) => {
    try {
      const currentUserEmail = localStorage.getItem('currentUserEmail');

      const response = await accountsTable
        .select({
          view: 'Grid view',
          filterByFormula: `{User} = '${currentUserEmail}'`,
        })
        .firstPage();

      if (response && response.length > 0) {
        const userId = response[0].id;

        await accountsTable.update(userId, {
          'Subscription Tier': [subscriptionRecordID], // Link to the subscription tier record
        });

        dispatch(fetchUserData());
      } else {
        console.error('User record not found.');
      }
    } catch (error) {
      console.error('Error updating subscription tier:', error);
    }
  };
};
