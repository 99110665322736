import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { getDownloadURL, list, listAll, ref, deleteObject, getStorage, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';
import { useNavigate, useSearchParams } from 'react-router-dom';
import manBackground from '../../assets/RunningManWhite.png';
import ResultList from '../resultList/ResultList';
import Result from '../result/Result';
import styles from './Dashboard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAthleteList, getAthletesLoading, getUserData, getUserLoading } from '../../store/selectors/userSelectors';
import VideoRenamingModal from '../modals/VideoRenamingModal';
import ShareModal from '../modals/ShareModal';
import { useCallback } from 'react';
import ResultNew from '../result/ResultNew';
import DashboardMobileOverlay from '../dashboardMobileOverlay/DashboardMobileOverlay';
import NoAccessMessage from '../noAccessMessage/NoAccessMessage';

function Dashboard() {
  const navigate = useNavigate();

  const [resultList, setResultList] = useState([]);
  const [currentResult, setCurrentResult] = useState(null);
  const [anglesFormat, setAnglesFormat] = useState(null);
  const [currentPreset, setCurrentPreset] = useState(null);
  const [currentVideoName, setCurrentVideoName] = useState(null);
  const [currentVideoComment, setCurrentVideoComment] = useState(null);
  const [currentResultEmail, setCurrentResultEmail] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isShareModalActive, setIsShareModalActive] = useState(false);
  const [renamingModalRef, setRenamingModalRef] = useState(null);
  const [renamingModalvideoName, setRenamingModalvideoName] = useState(null);
  const [initialURLParsingCompleted, setInitialURLParsingCompleted] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [resultAccessError, setResultAccessError] = useState(false);
  // const [isMobileOverlayDisabled, setIsMobileOverlayDisabled] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedAthleteEmail = searchParams.get('selectedAthleteEmail');
  const selectedFolder = searchParams.get('selectedFolder');

  const fetchDataCallId = useRef(0); // Counter to keep track of the latest fetchData call
  const prevAthleteListRef = useRef(null);

  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  const userData = useSelector(getUserData);
  const userLoading = useSelector(getUserLoading);

  const athleteList = useSelector(getAthleteList);
  const athletesLoading = useSelector(getAthletesLoading);

  const videoListRef = useMemo(() => {
    return ref(storage, `videos/${currentUser.email}/Results/`);
  }, [currentUser.email]);

  const athleteListRef = useMemo(() => {
    if (athleteList.length === 0) {
      return [];
    }

    return athleteList.map((athlete) => {
      return ref(storage, `videos/${athlete.User}/Results/`);
    });
  }, [athleteList]);

  async function checkFileVersion(path) {
    const storage = getStorage();
    const folderRef = ref(storage, path);

    try {
      const listResult = await list(folderRef);

      // Check if 'anglesNew.json' exists
      const hasNewFile = listResult.items.some((item) => item.name === 'anglesNew.json');
      if (hasNewFile) {
        return 'new';
      }

      // Check if 'angles.json' exists
      const hasOldFile = listResult.items.some((item) => item.name === 'angles.json');

      if (hasOldFile) {
        return 'old';
      }

      // If neither file is found, you might want to return a default value or throw an error
      return null;
    } catch (error) {
      console.error('Error checking file version:', error);
      throw error; // Re-throw the error or handle it as needed
    }
  }

  const handleEditVideo = useCallback((videoRef, videoName) => {
    setRenamingModalRef(videoRef);
    setRenamingModalvideoName(videoName);
    setIsModalActive(true);
  }, []);

  const handleSelectVideo = async (result) => {
    setResultAccessError(false);
    // Extract athleteEmail and folder from the result
    const { folder, athleteEmail } = result;

    // Update the URL parameters with selectedAthleteEmail and selectedFolder
    setSearchParams({
      selectedAthleteEmail: athleteEmail,
      selectedFolder: folder,
    });

    const path = `videos/${athleteEmail}/Results/${folder}`;
    const format = await checkFileVersion(path).then((format) => format);
    setAnglesFormat(format);
    setCurrentResult(ref(storage, path));

    setCurrentPreset(result.preset);
    setCurrentVideoName(result.videoName);
    setCurrentVideoComment(result.comment);
    setCurrentResultEmail(result.athleteEmail);
  };

  const handleDeleteVideo = async (videoPath) => {
    const storageRef = ref(storage, videoPath);

    try {
      // List all items (files) in the folder
      const listResult = await listAll(storageRef);

      // Delete each file in the list
      await Promise.all(
        listResult.items.map(async (item) => {
          await deleteObject(item);
        })
      );

      setCurrentResult(null);
      fetchData(); // Fetch data again to update the result list
      setIsModalActive(false); // Close the modal
    } catch (error) {
      console.error('Failed to delete video and folder:', error);
    }
  };

  // Function to check if info.json file exists
  const checkInfoJsonExists = async (path) => {
    const folderRef = ref(storage, path);
    const folderContents = await list(folderRef);
    const infoJsonExists = folderContents.items.some((item) => item.name === 'info.json');
    return infoJsonExists;
  };

  // Function to parse the info.json file
  const parseInfoJson = async (path) => {
    const infoExists = await checkInfoJsonExists(path);
    if (!infoExists) {
      return null;
    }

    const infoRef = ref(storage, `${path}/info.json`);
    try {
      const infoSnapshot = await getDownloadURL(infoRef);
      const response = await fetch(infoSnapshot);
      const jsonData = await response.json();

      return {
        videoName: jsonData.name,
        date: jsonData.date,
        comment: jsonData.comment || '',
        preset: jsonData.preset || '',
        timestamp: jsonData.timestamp || '',
        view: jsonData.view || '',
        exercise: jsonData.exercise || '',
      };
    } catch (error) {
      console.error('Failed to fetch info.json:', error);
      return null;
    }
  };

  const fetchData = useCallback(async () => {
    const currentCallId = ++fetchDataCallId.current; // Increment the counter for each call

    setIsDataLoading(true);
    try {
      if (currentCallId !== fetchDataCallId.current) {
        // This means a new call has been initiated, abort this execution
        return;
      }
      const usersResponses = await Promise.all([videoListRef, ...athleteListRef].map((ref) => listAll(ref)));

      const usersPathsPromises = usersResponses.map(async (response, index) => {
        const athleteEmail = [userData, ...athleteList][index]?.User;
        const athleteName = [userData, ...athleteList][index]?.Name;

        const pathsPromises = response.prefixes.map(async (folder) => {
          const path = `videos/${athleteEmail}/Results/${folder.name}`;
          const info = await parseInfoJson(path); // Parse info.json

          if (!info) {
            return null; // Skip the path if info.json is not found
          }

          return {
            path: ref(storage, path),
            videoName: info.videoName,
            date: info.date,
            comment: info.comment,
            preset: info.preset,
            timestamp: info.timestamp,
            view: info.view,
            exercise: info.exercise,
            athleteEmail,
            folder: folder.name,
          };
        });

        const paths = await Promise.all(pathsPromises);
        const resolvedPaths = paths.filter(Boolean);
        return {
          athleteName: athleteEmail === currentUser.email ? 'My Videos' : athleteName || athleteEmail,
          paths: resolvedPaths,
        };
      });

      const usersPaths = await Promise.all(usersPathsPromises);

      const combinedResult = [...usersPaths];
      const sortedResult = combinedResult.slice(1).sort((a, b) => {
        const nameA = a.athleteName.trim().toLowerCase();
        const nameB = b.athleteName.trim().toLowerCase();

        return nameA.localeCompare(nameB);
      });

      setResultList([combinedResult[0], ...sortedResult]);
    } finally {
      if (currentCallId === fetchDataCallId.current) {
        setIsDataLoading(false);
      }
    }
  }, [videoListRef, athleteListRef]);

  // CAREFUL HERE:
  const updateInfoJson = useCallback(
    async (videoRefPath, newInfo) => {
      try {
        // Reference to the info.json file
        const infoRef = ref(storage, `${videoRefPath}/info.json`);

        // Download the existing info.json data
        const infoSnapshot = await getDownloadURL(infoRef);
        const response = await fetch(infoSnapshot);
        const jsonData = await response.json();

        // Modify the info.json data
        const updatedJsonData = { ...jsonData, ...newInfo };

        // Upload the updated info.json back to Firebase Storage
        const updatedInfoData = JSON.stringify(updatedJsonData);
        const updatedInfoBlob = new Blob([updatedInfoData], { type: 'application/json' });
        await uploadBytes(ref(storage, `${videoRefPath}/info.json`), updatedInfoBlob);

        fetchData();
      } catch (error) {
        console.error('Failed to update info.json:', error);
      }
    },
    [fetchData]
  );

  useEffect(() => {
    if (userLoading || athletesLoading) {
      return;
    }

    if (!initialURLParsingCompleted && userData.User && athleteList) {
      fetchData()
        .then(() => {
          if (!(selectedAthleteEmail && selectedFolder)) {
            setCurrentResult(null);
            return;
          }

          if (![...athleteList.map((athlete) => athlete.User), userData.User].includes(selectedAthleteEmail)) {
            setResultAccessError(true);
            return;
          }

          const path = `videos/${selectedAthleteEmail}/Results/${selectedFolder}`;
          parseInfoJson(path).then(async (info) => {
            if (info) {
              setCurrentResult(ref(storage, path));
              const format = await checkFileVersion(path).then((format) => format);
              setAnglesFormat(format);
              setCurrentResultEmail(selectedAthleteEmail);

              setCurrentPreset(info.preset);
              setCurrentVideoName(info.videoName);
              setCurrentVideoComment(info.comment);
            } else {
              setCurrentResult(null);
            }
          });
        })
        .then(() => {
          setInitialURLParsingCompleted(true);
        });
    }
  }, [userData.User, athleteList, selectedAthleteEmail, selectedFolder]);

  useEffect(() => {
    if (initialURLParsingCompleted && JSON.stringify(athleteListRef) !== JSON.stringify(prevAthleteListRef.current)) {
      fetchData();
    }

    prevAthleteListRef.current = athleteListRef;
  }, [athleteListRef, userLoading || athletesLoading]);

  useEffect(() => {
    const lastShownTime = localStorage.getItem('lastShownTime');
    const currentTime = new Date().toString();
    const twentyFourHours = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const lastShownTimestamp = new Date(lastShownTime).getTime();
    const currentTimestamp = new Date().getTime();

    const timeDifference = currentTimestamp - lastShownTimestamp;

    if (timeDifference >= twentyFourHours || lastShownTime === undefined) {
      if (Object.keys(userData).length !== 0) {
        if (userData['Height (cm)'] === undefined) {
          localStorage.setItem('lastShownTime', currentTime);
          navigate('/biometricForm', {
            state: { filloutUrl: userData['Fillout Code'], forwardTo: !userData['Onboarding'] ? '/welcome' : '/' },
            replace: true,
          });
        }
      }
    } else if (Object.keys(userData).length !== 0) {
      !userData['Onboarding'] && navigate('/welcome');
    }
  }, [dispatch, userData]);

  return (
    <>
      {/* {!isMobileOverlayDisabled && <DashboardMobileOverlay setIsMobileOverlayDisabled={setIsMobileOverlayDisabled} />} */}
      <ResultList
        resultList={resultList}
        currentResult={currentResult}
        handleSelectVideo={handleSelectVideo}
        onEditVideo={handleEditVideo}
        isDashboardLoading={isDataLoading}
      />
      <>
        {currentResult ? (
          <>
            {anglesFormat === 'new' ? (
              <ResultNew
                result={currentResult}
                currentPreset={currentPreset}
                updateInfoJson={updateInfoJson}
                comment={currentVideoComment}
                videoName={currentVideoName}
                fetchData={fetchData}
                setIsShareModalActive={setIsShareModalActive}
              />
            ) : anglesFormat === 'old' ? (
              <Result
                result={currentResult}
                currentPreset={currentPreset}
                updateInfoJson={updateInfoJson}
                comment={currentVideoComment}
                videoName={currentVideoName}
                fetchData={fetchData}
                setIsShareModalActive={setIsShareModalActive}
              />
            ) : null}
          </>
        ) : resultAccessError ? (
          <NoAccessMessage selectedAthleteEmail={selectedAthleteEmail} />
        ) : (
          <div className={styles.emptyResult}>
            <img className={styles.manBackground} src={manBackground} alt='Running Man Background' />
          </div>
        )}
      </>
      <VideoRenamingModal
        isActive={isModalActive}
        setIsModalActive={setIsModalActive}
        videoRef={renamingModalRef}
        videoName={renamingModalvideoName}
        fetchData={fetchData}
        setCurrentVideoName={setCurrentVideoName}
        onDeleteVideo={handleDeleteVideo} // Pass the function to the modal
      />
      <ShareModal
        isActive={isShareModalActive}
        videoName={currentVideoName}
        currentResultEmail={currentResultEmail}
        onCancel={() => setIsShareModalActive(false)}
      />
    </>
  );
}

export default React.memo(Dashboard);
