// FeedbackModal.js
import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './Modal.module.css';
import ReactDOM from 'react-dom';

import { FilloutStandardEmbed } from '@fillout/react';
import '@fillout/react/style.css';

export default function FeedbackModal({ isActive, closeModal }) {
  const { currentUser } = useAuth();

  if (!isActive) return null;

  return ReactDOM.createPortal(
    <div className={styles.modal}>
      <div onClick={closeModal} className={styles.overlay}></div>
      <div className={styles.card} style={{ padding: '20px 0px' }}>
        <FontAwesomeIcon onClick={closeModal} className={styles.cancelIcon} icon={faTimes} />

        <div className={styles.form} style={{ maxHeight: '80vh', height: '100%', userSelect: 'none' }}>
          <FilloutStandardEmbed dynamicResize filloutId='oN32QrZXZ6us' parameters={{ email: currentUser.email }} />
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
}
