const Airtable = require('airtable');

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_KEY }).base(process.env.REACT_APP_AIRTABLE_BASE_ID);

export const table = base('Video Upload API Testing');
export const accountsTable = base('Account Settings');
export const videoUploadTable = base('Video Upload Data');
export const stripeCoupons = base('Stripe Coupons');
export const exerciseTable = base('Exercise Database');
export const subscriptionTiersTable = base('Subscription Tiers');
