import React, { useRef, useEffect } from 'react';

function GraphDeltaValue({
  videoRef,
  linedata,
  dataPoint1Name,
  dataPoint2Name,
  dataPoint1Label,
  dataPoint2Label,
  dataVisibility,
  label = '°',
  graphRef,
}) {
  const canvasRef = useRef(null);
  const oversamplingFactor = 2; // Your oversampling factor

  // Handle canvas resize
  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;
      const parentDiv = graphRef?.current; // Use graphRef to reference the parent div

      if (canvas && parentDiv) {
        canvas.width = canvas.offsetWidth * oversamplingFactor;
        canvas.height = 16 * oversamplingFactor;
      }
    };

    handleResize(); // Initial setup

    window.addEventListener('resize', handleResize);

    // Use ResizeObserver to monitor changes in the parent div's size
    const resizeObserver = new ResizeObserver(handleResize);
    const parentDiv = graphRef?.current;

    if (parentDiv) {
      resizeObserver.observe(parentDiv);
    }

    return () => {
      window.removeEventListener('resize', handleResize);

      // Clean up the ResizeObserver
      if (parentDiv) {
        resizeObserver.unobserve(parentDiv);
      }
    };
  }, [graphRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = canvas.offsetWidth * oversamplingFactor;
    canvas.height = 16 * oversamplingFactor;
    // ctx.scale(oversamplingFactor, oversamplingFactor);

    let lastRenderTime = 0;
    let requestId;

    const animate = (timestamp) => {
      if (timestamp - lastRenderTime < 33) {
        requestId = requestAnimationFrame(animate);
        return;
      }

      const videoDuration = videoRef.current?.getDuration();
      const videoCurrentTime = videoRef.current.getCurrentTime() % videoDuration;
      const newProgress = videoDuration ? videoCurrentTime / videoDuration : 0;

      const newDataIndex1 = Math.floor((linedata.length - 1) * newProgress);
      const newDataIndex2 = newDataIndex1 + 1;

      const angle1 = linedata ? linedata[newDataIndex1][dataPoint1Name] : 0;
      const angle2 = linedata ? linedata[newDataIndex2][dataPoint2Name] : 0;

      const newLineX = newProgress * (canvas.width - 32);

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Check dataVisibility to determine what to display
      if (
        dataVisibility[dataPoint1Label] &&
        dataVisibility[dataPoint2Label] &&
        angle1 !== null &&
        angle1 !== undefined &&
        angle2 !== null &&
        angle2 !== undefined
      ) {
        ctx.font = '32px Lato';
        ctx.fillStyle = '#0E0E0E';
        ctx.fillText('Δ', newLineX, canvas.height - 4);

        ctx.font = '32px Lato';
        ctx.fillStyle = '#0E0E0E';
        const angleDifference = Math.abs(angle1 - angle2);

        ctx.fillText(`${angleDifference.toFixed(0)}${label}`, newLineX + 24, canvas.height - 4);
      } else if (dataVisibility[dataPoint1Label] && angle1 !== null && angle1 !== undefined) {
        ctx.font = '32px Lato';
        ctx.fillStyle = '#0E0E0E';
        ctx.fillText(`${angle1.toFixed(0)}${label}`, newLineX, canvas.height - 4);
      } else if (dataVisibility[dataPoint2Label] && angle2 !== null && angle2 !== undefined) {
        ctx.font = '32px Lato';
        ctx.fillStyle = '#0E0E0E';
        ctx.fillText(`${angle2.toFixed(0)}${label}`, newLineX, canvas.height - 4);
      }

      lastRenderTime = timestamp;
      requestId = requestAnimationFrame(animate);
    };

    requestId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, [videoRef, dataVisibility]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        bottom: '0px',
        left: '20px',
        minWidth: 'calc(320px - 32px)',
        width: 'calc(100% - 20px)',
        height: 'calc(16px)',
        pointerEvents: 'none',
        backgroundColor: 'transparent',
      }}
    />
  );
}

export default GraphDeltaValue;
