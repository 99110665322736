import { useEffect, useRef, useState } from 'react';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase';
import styles from './Result.module.css';
// import AngleDisparityLine from '../angleDisparityLine/AngleDisparityLine';
import DatavizControl from '../datavizControl/DatavizControl';
import React from 'react';
import { Link } from 'react-router-dom';
// import ROM from '../ROM/ROM';

import { IconAngle, IconBoxAlignLeft, IconGauge, IconLoader2, IconNote } from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import RangeSliderTitle from '../rangeSliderTitle/RangeSliderTitle';
import CommentModal from '../modals/CommentModal';
import WithTooltip from '../HOCs/WithTooltip';

// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas'; // Import HTML2Canvas library
// import * as htmlToImage from 'html-to-image';
import ReportModal from '../modals/ReportModal';
import tooltipData from '../../data/tooltipPropsData';

import LineGraphNew from '../lineGraph/LineGraphNew';
import { useCallback } from 'react';
import GroundAngleGraphNew from '../groundAngleGraph/GroundAngleGraphNew';
import VelocityGraphNew from '../velocityGraph/VelocityGraphNew';
import TiltCircleNew from '../tiltCircle/TiltCircleNew';
import CenterOfBodyNew from '../centerOfBody/CenterOfBodyNew';

import jointsInitialDisplay from '../../data/jointsInitialDisplay';
import MultiGraph from '../multiGraph/MultiGraph';
import MultiGraphModal from '../modals/MultiGraphModal';
import MultiVelocityModal from '../modals/MultiVelocityModal';
import MultiVelocityGraph from '../multiGraph/MultiVelocityGraph';
import Loader from '../loader/Loader';
import { normalizeTiltAngles } from '../../utils/normalizeTiltAngles';
import CustomVideoPlayer from '../customVideoPlayer/CustomVideoPlayer';
import WithPayWall from '../HOCs/WithPayWall';

function ResultNew({
  result,
  currentPreset,
  updateInfoJson,
  videoName,
  comment: initialComment,
  fetchData,
  setIsShareModalActive,
}) {
  const [comment, setComment] = useState(initialComment);
  const [resultFiles, setResultFiles] = useState({});
  const [linedata, setLinedata] = useState(null);

  const [downloadableCSVURL, setDownloadableCSVURL] = useState(null);
  const [downloadableJSONURL, setDownloadableJSONURL] = useState(null);
  const [downloadablePDFURL, setDownloadablePDFURL] = useState(null);

  const [jointsDisplay, setJointsDisplay] = useState(currentPreset || jointsInitialDisplay);
  const [multiJointsToDisplay, setMultiJointsToDisplay] = useState([]);
  const [multiVelocityToDisplay, setMultiVelocityToDisplay] = useState([]);
  const [tiltLimits, setTiltLimits] = useState({});
  const [isModalActive, setIsModalActive] = useState(false);
  const [isReportModalActive, setIsReportModalActive] = useState(false);
  const [isMultiGraphModalActive, setIsMultiGraphModalActive] = useState(false);
  const [isMultiVelocityModalActive, setIsMultiVelocityModalActive] = useState(false);
  const [isPDFbeingExported, setIsPDFbeingExported] = useState(false);
  const [currentSectionInView, setCurrentSectionInView] = useState('');

  const stringifiedResult = JSON.stringify({ result, videoName, comment, jointsDisplay });

  const videoRef = useRef();
  const datavizRef = useRef(null);
  const videoListRef = ref(storage, `/${result?._location.path_}`);

  const elementRefs = useRef([]);
  const titleRefs = useRef([]);

  // Temporary
  const exportPDF = function (params) {
    return;
  };

  const downloadFile = (fileURL, fileName) => {
    if (!fileURL) {
      console.error('No file URL available for download.');
      return;
    }

    const link = document.createElement('a');
    link.href = fileURL;
    // link.download = fileName;
    link.attributes.download = true;
    // link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = (filetype = 'json') => {
    switch (filetype) {
      case 'json':
        if (downloadableJSONURL) {
          downloadFile(downloadableJSONURL, videoName.split(' ').join('_') + '.json');
        }
        break;
      case 'csv':
        if (downloadableCSVURL) {
          downloadFile(downloadableCSVURL, videoName.split(' ').join('_') + '.csv');
        }
        break;
      case 'pdf':
        if (downloadablePDFURL) {
          downloadFile(downloadablePDFURL, 'Snapshot_' + videoName.split(' ').join('_') + '.pdf');
        }
        break;
      case 'video':
        if (resultFiles.Result) {
          downloadFile(resultFiles.Result, videoName.split(' ').join('_') + '.mp4');
        }
        break;
      default:
        console.error('Unknown filetype');
    }
  };

  const handleScrollLink = (e, id) => {
    const targetElement = elementRefs.current[id];

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const handleCheckboxClick = useCallback(
    (jointKey, keyToUpdate, side = null) => {
      setJointsDisplay((prevJointsDisplay) => {
        // Create a copy of the previous state
        const updatedJointsDisplay = { ...prevJointsDisplay };

        // Update the display value
        if (side) {
          updatedJointsDisplay[jointKey][keyToUpdate][side] = !updatedJointsDisplay[jointKey][keyToUpdate][side];
        } else {
          updatedJointsDisplay[jointKey][keyToUpdate].display = !updatedJointsDisplay[jointKey][keyToUpdate].display;
        }

        const newInfo = { preset: updatedJointsDisplay };
        const videoRefPath = result?._location.path_;

        // Update info.json in Firebase
        updateInfoJson(videoRefPath, newInfo);

        // Return the updated state
        return updatedJointsDisplay;
      });
    },
    [result, updateInfoJson]
  );

  const openCommentModal = () => {
    setIsModalActive(true);
  };

  const closeCommentModal = () => {
    setIsModalActive(false);
  };

  // Function to scroll the video to a particular time
  const scrollToTime = useCallback(
    (percentage) => {
      if (videoRef.current) {
        const video = videoRef.current;
        let time = percentage / 100;

        video.seekTo(time, 'fraction', false);
      }
    },
    [videoRef]
  );

  // Rewritten for new AnglesNew.JSON
  function isEveryItemEmpty(data, propertyName) {
    const values = data[propertyName];
    if (values && Array.isArray(values)) {
      return values.every((item) => item === null || item === undefined);
    }
    return true; // Property doesn't exist or is not an array
  }

  const checkDataAvailability = (dataType) => {
    if (!linedata) {
      return false;
    }

    // Assume jointsDisplay is an object with keys as jointNames
    for (const jointName in jointsDisplay) {
      switch (dataType) {
        case 'graph':
          if (jointsDisplay[jointName]?.graph?.display) {
            return true;
          }
          break;
        case 'velocity':
          if (jointsDisplay[jointName]?.velocity?.display) {
            return true;
          }
          break;
        case 'tilt':
          if (jointsDisplay[jointName]?.tilt?.display) {
            return true;
          }
          break;
        case 'cob':
          if (jointsDisplay[jointName]?.centerOfBody?.display) {
            return true;
          }
          break;

        // Add other data types as needed
        default:
          console.warn(`Unknown data type: ${dataType}`);
          return false;
      }
    }

    return false;
  };

  const toggleReportModal = () => {
    setIsReportModalActive(true);
    const currentTime = videoRef.current.getCurrentTime();
    const videoDuration = videoRef.current?.getDuration();
    const fraction = currentTime / videoDuration;

    videoRef.seekTo(fraction, 'fraction', false);
  };

  // Function to handle confirming selected joints
  const selectMultiGraphJoints = (selectedJoints) => {
    setMultiJointsToDisplay(selectedJoints);
    setIsMultiGraphModalActive(false);
  };

  // Function to handle confirming selected joints
  const selectMultiVelocityJoints = (selectedJoints) => {
    setMultiVelocityToDisplay(selectedJoints);
    setIsMultiVelocityModalActive(false);
  };

  const isGraphOrVelocityActive = (jointsDisplay) => {
    return Object.values(jointsDisplay).some((joint) => {
      return (joint.graph && joint.graph.display) || (joint.velocity && joint.velocity.display);
    });
  };

  function downloadVideo(videoUrl) {
    if (!videoUrl) {
      console.error('No video URL available for download.');
      return;
    }

    handleDownload('video');
  }

  useEffect(() => {
    // setResultFiles([]);
    setComment(initialComment);
    setLinedata(null);
    setDownloadableCSVURL(null);
    setDownloadablePDFURL(null);
    setDownloadableJSONURL(null);
    listAll(videoListRef).then((response) => {
      response.items.forEach((item) => {
        if (item._location.path.endsWith('/anglesNew.json')) {
          // New format for Angles JSON
          getDownloadURL(item)
            .then((url) => {
              setDownloadableJSONURL(url);
              return fetch(url);
            })
            .then((response) => response.json())
            .then((data) => {
              setLinedata(data);
            })
            .catch((error) => console.error(error));
        } else if (item._location.path.endsWith('.mp4')) {
          getDownloadURL(item).then((url) => {
            setResultFiles((prev) => ({
              ...prev,
              Result: url,
            }));
          });
        } else if (item._location.path.endsWith('/angle.csv')) {
          getDownloadURL(item)
            .then((url) => setDownloadableCSVURL(url))
            .catch((error) => console.error('Failed to load CSV:', error));
        } else if (item._location.path.endsWith('/snapshot.pdf')) {
          getDownloadURL(item)
            .then((url) => setDownloadablePDFURL(url))
            .catch((error) => console.error('Failed to load PDF:', error));
        }
      });
    });
  }, [result]);

  useEffect(() => {
    setJointsDisplay(currentPreset || jointsInitialDisplay);
  }, [currentPreset]);

  const calculateOffsetsForJoints = () => {
    if (!datavizRef.current) return;

    const scrollY = datavizRef.current.scrollTop;
    const datavizHeight = datavizRef.current.clientHeight;
    let sectionInView = '';

    // Function to check if section is at the bottom of the screen
    const isSectionAtBottom = (sectionRef) => {
      if (sectionRef) {
        const sectionTop = sectionRef.offsetTop;
        if (scrollY + datavizHeight >= sectionTop && sectionTop >= scrollY) {
          return true;
        }
      }
      return false;
    };

    // Loop through each joint
    for (const jointName in jointsInitialDisplay) {
      const joint = jointsInitialDisplay[jointName];

      switch (true) {
        case jointName === 'Shoulders' &&
          joint.centerOfBody &&
          joint.centerOfBody.display &&
          isSectionAtBottom(elementRefs.current[`centerOfBody`]):
          sectionInView = 'cob-dropdown';
          break;

        case joint.tilt &&
          joint.tilt.display &&
          !isSectionAtBottom(elementRefs.current[`centerOfBody`]) &&
          (isSectionAtBottom(elementRefs.current[`${jointName}Tilt`]) ||
            isSectionAtBottom(elementRefs.current[`shoulderHipGraph`])):
          sectionInView = 'tilt-dropdown';
          break;

        case joint.velocity && joint.velocity.display && isSectionAtBottom(elementRefs.current[`${jointName}Velocity`]):
          sectionInView = 'velocity-dropdown';
          break;

        case joint.graph && joint.graph.display && isSectionAtBottom(elementRefs.current[`${jointName}Graph`]):
          sectionInView = 'graph-dropdown';
          break;

        default:
          sectionInView = '';
          break;
      }

      if (sectionInView) break;
    }

    if (sectionInView !== currentSectionInView) {
      setCurrentSectionInView(sectionInView);
    }
  };

  useEffect(() => {}, [videoName]);

  useEffect(() => {
    calculateOffsetsForJoints();
  }, [linedata]);

  return (
    <>
      {resultFiles.Result && linedata ? (
        <>
          {/* <div className={styles.footerAdjuster}></div> */}
          <div className={styles.result}>
            <div className={styles.left}>
              <div className={styles.video}>
                <CustomVideoPlayer src={resultFiles.Result} ref={videoRef} downloadVideo={downloadVideo} />
              </div>
              <div className={styles.title}>
                <h3>{videoName}</h3>
              </div>

              <div onClick={openCommentModal} className={styles.notesBtn}>
                Notes <IconNote color='#272727' />
              </div>
            </div>
            <div className={styles.right}>
              <DatavizControl
                handleScrollLink={handleScrollLink}
                jointsDisplay={jointsDisplay}
                handleDownload={handleDownload}
                isCSVAvailable={!!downloadableCSVURL}
                isPDFAvailable={!!downloadablePDFURL}
                downloadableJSONURL={downloadableJSONURL}
                downloadableCSVURL={downloadableCSVURL}
                downloadablePDFURL={downloadablePDFURL}
                handleCheckboxClick={handleCheckboxClick}
                toggleReportModal={toggleReportModal}
                setIsShareModalActive={setIsShareModalActive}
                currentSectionInView={currentSectionInView}
              />
              <div className={styles.compareLink}>
                <WithPayWall acceptedPlanIDs={[1, 2, 3, 4, 5, 6, 7, 8, 9]}>
                  <Link to='/compare' state={stringifiedResult}>
                    <button className={styles.compareBtn}>
                      <span className={styles.compareText}>Compare</span>
                      <IconBoxAlignLeft
                        className={styles.compareIcon}
                        color='#ffffff'
                        // style={{ marginBottom: '4px', marginLeft: '0px' }}
                      />
                    </button>
                  </Link>
                </WithPayWall>
              </div>
              {isPDFbeingExported ? (
                <div className={styles.PDFoverlay}>
                  <div className={styles.container}>
                    <h2>
                      <IconLoader2 className={styles.icon} />
                      Preparing the report...
                    </h2>
                    <p>Your report will automatically begin downloading once it's generated.</p>
                  </div>
                </div>
              ) : null}
              {videoRef.current && (
                <div
                  ref={datavizRef}
                  onScroll={calculateOffsetsForJoints}
                  className={`${styles.dataviz} ${isPDFbeingExported && styles.forReport}`}
                >
                  <div>
                    {checkDataAvailability('graph') &&
                      ['Shoulders', 'Hips', 'Knees', 'Ankles', 'Elbows', 'Wrists'].reduce((acc, jointName) => {
                        const displayValue = jointsDisplay[jointName]?.graph?.display;
                        if (displayValue) {
                          return acc + displayValue;
                        }
                        return acc;
                      }, '') && (
                        <h3 ref={(el) => (titleRefs.current['Joint Angles'] = el)}>
                          <WithTooltip {...tooltipData.jointAngles}>Joint Angles</WithTooltip>
                        </h3>
                      )}

                    {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                      if (['Upper_Leg', 'Torso', 'Lower_Leg'].includes(jointName)) {
                        return null;
                      }
                      // Check if linedata.tilt is false and return null
                      if (!jointsDisplay[jointName]?.graph?.display) {
                        return null;
                      }

                      if (
                        !isEveryItemEmpty(linedata, 'LEFT_' + jointName.slice(0, -1).toUpperCase()) &&
                        !isEveryItemEmpty(linedata, 'RIGHT_' + jointName.slice(0, -1).toUpperCase())
                      ) {
                        let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase();
                        let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase();

                        const dataPoint1Label = 'Left';
                        const dataPoint2Label = 'Right';
                        return (
                          <div
                            key={`${jointName}Graph`}
                            ref={(lineGraphEl) => (elementRefs.current[`${jointName}Graph`] = lineGraphEl)}
                          >
                            <p className={styles.label}>{jointName.split('_').join(' ')}:</p>
                            <LineGraphNew
                              videoRef={videoRef}
                              dataPoint1Data={linedata[dataPoint1Name]}
                              dataPoint2Data={linedata[dataPoint2Name]}
                              dataPoint1Name={dataPoint1Name}
                              dataPoint2Name={dataPoint2Name}
                              dataPoint1Label={dataPoint1Label}
                              dataPoint2Label={dataPoint2Label}
                              scrollToTime={scrollToTime}
                              jointDisplay={jointsDisplay[jointName].graph}
                              jointName={jointName}
                              handleCheckboxClick={handleCheckboxClick}
                            />
                            <br />
                            {/* <React.Fragment>
                    <RangeSliderTitle
                      title={`${jointName.slice(0, -1)} Asymmetry:`}
                      minValue='0'
                      maxValue='180'
                      lineLimit={lineLimits[jointName] || 15}
                      setLineLimit={(value) => {
                        setLineLimits((prevLimits) => ({ ...prevLimits, [jointName]: value }));
                      }}
                    />
                    <AngleDisparityLine
                      ref={(el) => (elementRefs.current[`${jointName}Difference`] = el)}
                      linedata={linedata}
                      videoRef={videoRef}
                      jointName={jointName}
                      lineLimit={lineLimits[jointName] || 15}
                    />
                  </React.Fragment> */}
                          </div>
                        );
                      } else {
                        return (
                          <div key={`${jointName}Unavailable`}>
                            <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data not available</h4>
                            <br />
                          </div>
                        );
                      }
                    })}
                  </div>

                  {/* <h3>Relative to Ground Graphs</h3> */}

                  {checkDataAvailability('graph') &&
                    ['Upper_Leg', 'Torso', 'Lower_Leg'].reduce((acc, jointName) => {
                      const displayValue = jointsDisplay[jointName]?.graph?.display;
                      if (displayValue) {
                        return acc + displayValue;
                      }
                      return acc;
                    }, '') && (
                      <>
                        {checkDataAvailability('graph') && <hr />}
                        <h3 ref={(el) => (titleRefs.current['Segment Angles'] = el)}>
                          <WithTooltip {...tooltipData.segmentAngles}>Segment Angles</WithTooltip>
                        </h3>
                      </>
                    )}

                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (['Shoulders', 'Hips', 'Knees', 'Ankles', 'Elbows', 'Wrists'].includes(jointName)) {
                      return null;
                    }

                    if (!jointsDisplay[jointName]?.graph?.display) {
                      return null;
                    }

                    if (!isEveryItemEmpty(linedata, jointName.toUpperCase() + '_ANGLE')) {
                      let dataPointName = jointName.toUpperCase() + '_ANGLE';

                      const dataPointLabel = jointName.split('_').join(' ');
                      return (
                        <div key={`${jointName}Graph`} ref={(el) => (elementRefs.current[`${jointName}Graph`] = el)}>
                          <p className={styles.label}>{jointName.split('_').join(' ')}:</p>

                          <GroundAngleGraphNew
                            dataPointData={linedata[dataPointName]}
                            videoRef={videoRef}
                            dataPointName={dataPointName}
                            dataPointLabel={dataPointLabel}
                            scrollToTime={scrollToTime}
                            jointDisplay={jointsDisplay[jointName].graph}
                            jointName={jointName}
                            handleCheckboxClick={handleCheckboxClick}
                          />
                          <br />
                        </div>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.split('_').join(' ')} data not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}

                  {checkDataAvailability('velocity') && (
                    <>
                      {checkDataAvailability('graph') && <hr />}
                      <h3 ref={(el) => (titleRefs.current['Velocity'] = el)}>
                        <WithTooltip {...tooltipData.velocities}>Velocity</WithTooltip>
                      </h3>
                    </>
                  )}

                  {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                    if (!jointsDisplay[jointName]?.velocity?.display) {
                      return null;
                    }

                    if (
                      !isEveryItemEmpty(linedata, 'X:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'Y:LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'X:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY') &&
                      !isEveryItemEmpty(linedata, 'Y:RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY')
                    ) {
                      let dataPoint1Name = 'LEFT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY';
                      let dataPoint2Name = 'RIGHT_' + jointName.slice(0, -1).toUpperCase() + '_VELOCITY';

                      const dataPoint1Label = 'Left';
                      const dataPoint2Label = 'Right';

                      const dataPoint1Data = linedata['X:' + dataPoint1Name].map((xValue, index) =>
                        Math.sqrt(xValue ** 2 + linedata['Y:' + dataPoint1Name][index] ** 2)
                      );
                      const dataPoint2Data = linedata['X:' + dataPoint2Name].map((xValue, index) =>
                        Math.sqrt(xValue ** 2 + linedata['Y:' + dataPoint2Name][index] ** 2)
                      );
                      return (
                        <div
                          key={`${jointName}Velocity`}
                          ref={(el) => (elementRefs.current[`${jointName}Velocity`] = el)}
                        >
                          <p className={styles.label}>{jointName.slice(0, -1)} Velocity:</p>

                          <VelocityGraphNew
                            videoRef={videoRef}
                            dataPoint1Data={dataPoint1Data}
                            dataPoint2Data={dataPoint2Data}
                            dataPoint1Name={dataPoint1Name}
                            dataPoint2Name={dataPoint2Name}
                            dataPoint1Label={dataPoint1Label}
                            dataPoint2Label={dataPoint2Label}
                            scrollToTime={scrollToTime}
                            jointDisplay={jointsDisplay[jointName].velocity}
                            jointName={jointName}
                            handleCheckboxClick={handleCheckboxClick}
                          />
                          <br />
                        </div>
                      );
                    } else {
                      return (
                        <div key={`${jointName}Unavailable`}>
                          <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} velocity data not available</h4>
                          <br />
                        </div>
                      );
                    }
                  })}

                  {(checkDataAvailability('velocity') || checkDataAvailability('graph')) && <hr />}

                  <div className={styles.TiltAndCOB}>
                    {checkDataAvailability('tilt') && (
                      <div className={styles.TiltSection}>
                        <>
                          <h3 ref={(el) => (titleRefs.current['Tilt'] = el)}>
                            <WithTooltip {...tooltipData.tilts}>Tilt</WithTooltip>
                          </h3>
                        </>

                        {Object.entries(jointsDisplay).map(([jointName, jointData]) => {
                          if (!jointsDisplay[jointName]?.tilt?.display) {
                            return null;
                          }

                          if (!isEveryItemEmpty(linedata, 'TILT_SHOULDER')) {
                            let dataPointName = 'TILT_' + jointName.toUpperCase().slice(0, -1);

                            return (
                              <div
                                key={`${jointName}Tilt`}
                                ref={(el) => (elementRefs.current[`${jointName}Tilt`] = el)}
                              >
                                {/* <RangeSliderTitle
                            title={`${jointName}`}
                            minValue='0'
                            maxValue='45'
                            lineLimit={tiltLimits[jointName] || 5}
                            setLineLimit={(value) => {
                              setTiltLimits((prevLimits) => ({ ...prevLimits, [jointName]: value }));
                            }}
                          /> */}

                                <p className={styles.label}>{jointName}</p>

                                <TiltCircleNew
                                  dataPointData={linedata[dataPointName]}
                                  videoRef={videoRef}
                                  jointName={jointName}
                                  lineLimit={tiltLimits[jointName] || 5}
                                />
                              </div>
                            );
                          } else {
                            return (
                              <div key={`${jointName}Unavailable`}>
                                <h4 style={{ color: '#727272' }}>{jointName.slice(0, -1)} data not available</h4>
                                <br />
                              </div>
                            );
                          }
                        })}
                      </div>
                    )}

                    {checkDataAvailability('cob') && (
                      <div className={styles.COBSection}>
                        <h3 ref={(el) => (titleRefs.current['CenterOfBody'] = el)} style={{ marginBottom: '24px' }}>
                          Center of Body
                          {/* <WithTooltip {...tooltipData.tilts}>Center of Body</WithTooltip> */}
                        </h3>

                        {jointsDisplay.Shoulders?.centerOfBody?.display ? (
                          !(isEveryItemEmpty(linedata, 'COB_X') || isEveryItemEmpty(linedata, 'COB_Y')) ? (
                            <div ref={(el) => (elementRefs.current[`centerOfBody`] = el)}>
                              <CenterOfBodyNew
                                dataPoint1Data={linedata['COB_X']}
                                dataPoint2Data={linedata['COB_Y']}
                                videoRef={videoRef}
                              />
                            </div>
                          ) : (
                            <div key={`centerOfBodyUnavailable`}>
                              <h4 style={{ color: '#727272' }}>Center of Body data not available</h4>
                              <br />
                            </div>
                          )
                        ) : null}
                      </div>
                    )}
                  </div>

                  {jointsDisplay.Shoulders?.vsHipsGraph?.display ? (
                    !(isEveryItemEmpty(linedata, 'TILT_SHOULDER') || isEveryItemEmpty(linedata, 'TILT_HIP')) ? (
                      <div ref={(el) => (elementRefs.current['shoulderHipGraph'] = el)}>
                        <p className={styles.label}>Shoulder vs Hip Tilt:</p>

                        <LineGraphNew
                          videoRef={videoRef}
                          dataPoint1Data={linedata['TILT_SHOULDER'].map((angle) => normalizeTiltAngles(angle))}
                          dataPoint2Data={linedata['TILT_HIP'].map((angle) => normalizeTiltAngles(angle))}
                          dataPoint1Name={'TILT_SHOULDER'}
                          dataPoint2Name={'TILT_HIP'}
                          dataPoint1Label={'Shoulders'}
                          dataPoint2Label={'Hips'}
                          scrollToTime={scrollToTime}
                          jointDisplay={jointsDisplay.Shoulders.vsHipsGraph}
                          jointName={'Shoulders'}
                          handleCheckboxClick={handleCheckboxClick}
                        />
                        <br />
                      </div>
                    ) : (
                      <div key={`vsHipsGraphUnavailable`}>
                        <h4 style={{ color: '#727272' }}>Shoulders vs Hips data not available</h4>
                        <br />
                      </div>
                    )
                  ) : null}

                  {isGraphOrVelocityActive(jointsDisplay) && (
                    <div className={styles.combinedGraph}>
                      <WithTooltip {...tooltipData.combinedGraphs}>
                        <p className={styles.label}>
                          Combine Angle/Velocity Graphs&nbsp;
                          <span className={styles.betaHightlight}>Beta</span>{' '}
                        </p>
                      </WithTooltip>
                      <div className={styles.buttonContainer}>
                        <button className={styles.jointSelectButton} onClick={() => setIsMultiGraphModalActive(true)}>
                          <IconAngle color='white' /> Select Joint Angles
                        </button>
                        <button
                          className={styles.jointSelectButton}
                          onClick={() => setIsMultiVelocityModalActive(true)}
                        >
                          <IconGauge color='white' /> Select Joint Velocities
                        </button>
                      </div>
                      {multiJointsToDisplay.length > 0 && (
                        <>
                          <MultiGraph
                            linedata={linedata}
                            videoRef={videoRef}
                            scrollToTime={scrollToTime}
                            multiJointsToDisplay={multiJointsToDisplay}
                          />
                          <br />
                        </>
                      )}
                      {multiVelocityToDisplay.length > 0 && (
                        <>
                          <MultiVelocityGraph
                            linedata={linedata}
                            videoRef={videoRef}
                            scrollToTime={scrollToTime}
                            multiVelocityToDisplay={multiVelocityToDisplay}
                          />
                          <br />
                        </>
                      )}
                    </div>
                  )}
                  <hr />
                  <br />
                </div>
              )}
            </div>
          </div>
          <CommentModal
            isActive={isModalActive}
            setIsModalActive={closeCommentModal}
            videoRef={result}
            comment={comment}
            setComment={setComment}
            videoName={videoName}
            fetchData={fetchData}
          />
          <ReportModal
            isActive={isReportModalActive}
            jointsDisplay={jointsDisplay}
            notes={comment}
            exportPDF={exportPDF}
            onCancel={() => {
              setIsReportModalActive(false);
            }}
            videoName={videoName}
          />
          <MultiGraphModal
            isActive={isMultiGraphModalActive}
            jointsDisplay={jointsDisplay}
            onConfirm={selectMultiGraphJoints}
            onCancel={() => {
              setIsMultiGraphModalActive(false);
            }}
          />
          <MultiVelocityModal
            isActive={isMultiVelocityModalActive}
            jointsDisplay={jointsDisplay}
            onConfirm={selectMultiVelocityJoints}
            onCancel={() => {
              setIsMultiVelocityModalActive(false);
            }}
          />
        </>
      ) : null}
      <Loader isLoading={!(resultFiles.Result && linedata)} />
    </>
  );
}

export default React.memo(ResultNew);
