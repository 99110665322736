import JHSportsLogo from '../../src/assets/JH_LOGO.png';
import logo from '../../src/assets/Logotype-cropped.png';
import sparkmotionLogo from '../../src/assets/Spark-Parados-logo.png';
import { getSubdomain } from './getSubdomain';
const subdomain = getSubdomain()?.subdomain;

let customName, customLogo, customSlogan, customPadding;

if (subdomain === 'jhsports') {
  customName = 'JH Sports';
  customLogo = JHSportsLogo;
  customSlogan = <>Test, Don’t Guess</>;
  customPadding = '0px';
} else if (subdomain === 'sparkmotion') {
  customName = 'Spark Motion';
  customLogo = sparkmotionLogo;
  customSlogan = (
    <>
      Dig Deeper
      <br />
      Soar Higher
    </>
  );
  customPadding = '0px';
} else {
  customName = 'Parados';
  customLogo = logo;
  customSlogan = (
    <>
      Dig Deeper
      <br />
      Soar Higher
    </>
  );
  customPadding = '5px 0px';
}

export { customName, customLogo, customSlogan, customPadding };
