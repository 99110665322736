import { stripeCoupons } from '../../airtable-scripts';

export const FETCH_COUPONS_REQUEST = 'FETCH_COUPONS_REQUEST';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const FETCH_COUPONS_FAILURE = 'FETCH_COUPONS_FAILURE';

export const fetchCouponsRequest = () => ({
  type: FETCH_COUPONS_REQUEST,
});

export const fetchCouponsSuccess = (coupons) => ({
  type: FETCH_COUPONS_SUCCESS,
  payload: coupons,
});

export const fetchCouponsFailure = (error) => ({
  type: FETCH_COUPONS_FAILURE,
  payload: error,
});

export const fetchCouponsData = (email) => {
  return async (dispatch) => {
    dispatch(fetchCouponsRequest());

    try {
      const coupons = await stripeCoupons
        .select({
          filterByFormula: `AND({Email} = "${email}", NOT({Expired}))`,
        })
        .all();

      const promotionArray = coupons.map((coupon) => ({
        email: coupon.fields.Email,
        description: coupon.fields.Description,
        discount: coupon.fields['Discount (in $)'],
        Expired: coupon.fields.Expired,
        expiryDate: coupon.fields['Expiry Date'],
      }));
      dispatch(fetchCouponsSuccess(promotionArray)); // Pass promotionArray as payload
    } catch (error) {
      console.error('Error fetching stripeCoupons:', error);
      dispatch(fetchCouponsFailure(error));
    }
  };
};
