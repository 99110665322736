import { Form, FormControl, InputGroup, InputGroupAddon, InputGroupText } from 'react-bootstrap';
import React, { useEffect } from 'react';
import styles from './SearchBar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default function SearchBar({ searchInputRef, onSearch }) {
  // Accept the ref as a prop
  useEffect(() => {
    if (searchInputRef && searchInputRef.current) {
      // Focus the search input when the component mounts
      searchInputRef.current.focus();
    }
  }, [searchInputRef]);

  function handleInputChange(event) {
    const inputValue = event.target.value;
    onSearch(inputValue);
  }

  return (
    <Form>
      <InputGroup className={styles.searchBarInput}>
        <FormControl
          ref={searchInputRef}
          className={styles.textInput}
          type='text'
          placeholder='Search:'
          onChange={handleInputChange}
          onKeyDown={(e) => {
            e.stopPropagation();
          }} // Call handleInputChange on input change
        />
        <InputGroup.Text className={styles.iconContainer} style={{ display: 'block' }}>
          <FontAwesomeIcon icon={faSearch} fill='#FF0000' />
        </InputGroup.Text>
      </InputGroup>
    </Form>
  );
}
