import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { ErrorBoundary } from 'react-error-boundary';
import styles from './CustomErrorBoundary.module.css';
import { IconHome, IconLogout, IconRefresh } from '@tabler/icons-react';
import FeedbackModal from '../modals/FeedbackModal';

function ErrorFallback({ error, resetErrorBoundary }) {
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const onLinkClick = (e) => {
    e.preventDefault();
    currentUser ? setIsFeedbackModalOpen(true) : window.open('https://www.parados.app/contact.html', '_blank');
  };

  return (
    <div role='alert' className={styles.ErrorBoundary}>
      <h3>Oops!</h3>
      <h4>Something went wrong...</h4>
      <p>
        Try refreshing the page or{' '}
        <span onClick={onLinkClick} className={styles.contactSupport}>
          let us know about the issue
        </span>
        .
      </p>

      <br />
      <div className={styles.btnGroup}>
        <button
          onClick={() => {
            resetErrorBoundary();
            window.location.reload();
          }}
        >
          <IconRefresh color='white' stroke={1.5} /> Refresh Page
        </button>
        <button onClick={() => navigate('/')}>
          <IconHome color='white' stroke={1.5} />
          Home
        </button>
      </div>
      <FeedbackModal isActive={isFeedbackModalOpen} closeModal={() => setIsFeedbackModalOpen(false)} />
    </div>
  );
}

function CustomErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // This will reset the state of your application to the initial state
        // This function will be called after the resetErrorBoundary function is called from the ErrorFallback component
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default CustomErrorBoundary;
